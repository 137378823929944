import React from 'react';
import MiniDrawerContainer from '../container/MiniDrawerContainer';
import { Routing } from './Routing';
import { useStyles } from '../static/MiniDrawerStyles';
import './style.css'
import {useSelector} from 'react-redux'

// import AppBarComponent from './AppBarComponent';

function MainPage(props) {
    const classes = useStyles;
    const { openSideBar, toggleHandler } = props;
    const {isPrint}=useSelector(state=>state.isPrintReducer)
    return (
        <div className={classes.root}>
            {/* <AppBarComponent /> */}
            <MiniDrawerContainer  openSideBar={openSideBar} sideBarClick={toggleHandler} className="hiddenDrawer"/>
            <Routing openSideBar={openSideBar} sideBarClick={toggleHandler} />
        </div>
    );
}

export default MainPage;