import { addMenuItem } from "../actions"

export const menuDataReducer = (state= {menuData:[]},action) => {
    switch(action.type) {
        case "SET_MENU_DATA":
            return {...state,menuData:action.payload}

        // case "ADD_MENU_DATA":
        //     let data=action.payload

        //     let addMenu=state.orderData.push(data)
        //     console.log('==================')
        //     console.log('add menu redux case working=============',addMenu)
        //     return {...state,orderData:addMenu}

        default:
            return {...state}
    }
}


