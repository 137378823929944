import React, { useEffect, useState } from 'react';
import PureBreadcrumbs from '../../../component/Breadcrumb'
import { Label, Input, Form, FormGroup } from 'reactstrap'
import { Switch, Route } from 'react-router-dom'
import './reception.css';
import { table } from './data'
import { dineData } from '../../../localDB/dinningData';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Para from '../../../component/partials/Para'
import Btn from '../../../component/partials/Button'
import { BorderColor, NewReleasesRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom'
import { getMenuItem } from '../../../services/getMenuItem'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { getDining } from '../../../services/getDiningData'
import { getUsers } from '../../../services/getUsers'
import { postDining } from '../../../services/postDiningStatus'
import { addDine } from '../../../services/addDine'
import { MyCard } from '../../../component/partials/Card'
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { setDineTable, setTableStatus, setTableOrder, setTableMenu,setMenuData,setWaitersInfo,setMenuId,setOrderId,categoryItem } from '../../../redux/actions';
// import { setDineTable } from '../../../redux/actions';
import { MdModeEdit } from 'react-icons/md'
import { Row, Col } from 'react-bootstrap'
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Backdrop, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { SignIn } from '../sign-In/Sign-In';
import { CircularProgress } from '@material-ui/core';
import { Spinner } from '../../../component/partials/Spinner'
import { FaBeer } from 'react-icons/fa'
import MenuItem from '@material-ui/core/MenuItem';
import { MdAdd } from 'react-icons/md'
import { Delete } from '@material-ui/icons';
import { Avatar, IconButton } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ApartmentIcon from '@material-ui/icons/Apartment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { getCustomerOrder } from '../../../services/getCustomerOrder'
import { delTable } from '../../../services/deleteDine'
import { updateTable } from '../../../services/updateDine'
import {useHistory } from 'react-router-dom';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';
import PrintIcon from '@material-ui/icons/Print';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Fourseats from '../../../component/partials/Fourseat';

// import {IoAccessibilitySharp} from 'react-icons/io'


const useStyles = makeStyles({
    box: {

        minWidth: '500px',

    },
    screen: {
        padding: '0px',
        textAlign: 'center'
    }
    ,
    popUp: {
        minWidth: '600px',
        padding: '0px'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: '90%',

    },
    selectEmpty: {
        // marginTop: theme.spacing(2),
    }
    ,
    formControl: {

        minWidth: 220,
    }, 
    button:{
        marginLeft: '30px',
    },
});



export function RecepDinning() {
  /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }
    const { user } = useSelector(state => state.userReducer)
    const { tableOrder } = useSelector(state => state.tableOrderReducer)
    const {waitersInfo} =useSelector(state => state.waitersInfoReducer)
    // console.debug()
    console.debug('user in dinning', user)
    const { dineTable } = useSelector(state => state.dineTableReducer)
    const [snackOpen, setSnackOpen] = useState(false)
    const [adminDialogOpen, setAdminDialogOpen] = useState(false)
    const [addSeating, setAddSeating] = useState()
    const [addFloor, setAddFloor] = useState()
    const [addTable, setAddTable] = useState()
    const [editDineTable, setEditDineTable] = useState(false)
    const [allWaiters, setAllWaiters] = useState([])
    const {menuData}=useSelector(state=>state.menuDataReducer)
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const [okBtnDisabled, setOkBtnDisabled] = React.useState(false);
    const [selectedWaiter, setSelectedWaiter] = useState(
        {
        "id":0,
        "name":"",
        "role":""
        }       
    )

    const history = useHistory()
    const moveToMenu = (id, orderId) => {
        dispatch(setMenuId(id))
        dispatch(setOrderId(orderId))
        history.push('/waiter/menu')//TODO: to change to /admin/customer-order
    }

    const dispatch = useDispatch()
    const [response, setResponse] = useState({
        "user_id": 0,
        "floor_table_id": 'A',
        "status": 'free',
        "servedBy": ''
    })
    console.debug('real response', response)
    let dineBeforeInitialize;
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const adminAddRecep = () => {
        setAdminDialogOpen(true)
    }
    const snackHandleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackOpen(false);
    }
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [deletetableId, setDeleteTableId] = useState()
    const deleteDineDialog = (id,tableNumber,floorName) => {
        setDeleteTableId(id)
        setTableName(tableNumber)
        setFloorNo(floorName)
        setDeleteDialog(true)
    }
    let [tabId, setTablId] = useState()

    const deleteDine = (id) => {
        delTable(`/restaurant/deleteDining/${id}`).then(
            (result) => {
                console.debug('here your result', result)
                getDiningData();
            }
        )

        let updtable = dineTable
        let newtable = updtable.map((data) => {
            data.table.filter((item) => item.tableid !== id)
        })
        console.debug('here your delete table', newtable)
        setDeleteDialog(false)
        console.debug('here your id')
    }
    const addDining = (getSeat, getTable, getFloor, waiter) => {
        console.debug('1/here your add dine data ',getSeat, getTable, getFloor, waiter,waitersInfo, selectedWaiter)
        let waiterId = waitersInfo.filter((item) => item.name == waiter)
        console.debug('2/here your add dine data ',getSeat, getTable, getFloor, waiter,waitersInfo)
        let rend = {
            "table_number": getTable,
            "seats": getSeat,
            "floor": getFloor,
            "status":'created',
            "adminId": user.id,
            "userId": waiterId[0].id
        }
        if (getSeat, getTable, getFloor, waiter) {
            addDine('/restaurant/addTable', rend).then(
                (result) => {
                    console.debug('add dining status')
                    getDiningData(); 
                }
            )
            console.debug('here your dine value', getSeat, getTable, getFloor)
        }

        handleClose()
    }
    const getDiningData = () => {
        getDining('/restaurant/dinning').then(
            (result) => {
                let response = result;
                dineBeforeInitialize = response
                console.debug('checking API data', response)
                dispatch(setDineTable(response))
            })  
    }
    const [values, setValues] = React.useState({
        id: '',
        name: '',
        role: '',
      });

      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        console.debug(event.target.value)
      };
      const handleWaiterChange = (prop) => {
        console.debug("the waiter change ", prop);
       // setSelectedWaiter(prop)
        let waiterId = waitersInfo.filter((item) => item.name == prop)
        console.debug("the waiter Id ", waiterId[0].id);
        setSelectedWaiter(waiterId[0])
        console.debug("2-the waiter ", selectedWaiter);
      };

    let [tableName, setTableName] = useState()
    let [seating, setSeating] = useState()
    let [floorNo, setFloorNo] = useState()
    function editDine(tab, sat, flor, tabId,ser) {
        setTableName(tab)
        setSeating(sat)
        setFloorNo(flor)
        setTablId(tabId)
       // setSelectedWaiter(ser)
        setEditDineTable(true)
    }
    
    let floorInfo = []
    console.debug('floor push info', floorInfo)
    useEffect(() => {
        getDiningData();            
        getCustomerOrder(`/restaurant/customer-orders?hour=16`).then(
            (result) => {
                let response = result;
                console.debug('checking ORDER DATA', response)
                dispatch(setTableOrder(response))
                if(menuData.length===0)
                {
                    getMenuItem('/restaurant/getmenu').then(
                        (result) => {
                            let response = result;
                            dispatch(setMenuData(response))
                            dispatch(categoryItem(response['Mutton']))
                        }
                    )
                }else
                {
                    console.debug('menu data available!');
                }
            });
            

        getUsers('/restaurant/users?role=waiter').then(
            (result) => {
                console.debug("1-The waiters: ",waitersInfo);
                let resp = result;
                dispatch(setWaitersInfo(resp));
                console.debug("2-The waiters: ",waitersInfo);
                console.debug("The resp: ",resp);
                let arr = []
                resp.map((item) => {
                    arr.push(item.name)
                })
                setAllWaiters(arr);
                console.debug("3-The waiters: ",waitersInfo);
            }
        )
        const interval = setInterval(() => {
            apiCall()
        }, 12000);

        return () => clearInterval(interval)
    }, [])

    function apiCall() {
        getDining('/restaurant/dinning').then(
            (result) => {

                let response = result;
                dispatch(setDineTable(response))
                let dineTableData = JSON.stringify(dineBeforeInitialize)
                if (dineTableData === JSON.stringify(response)) {
                    console.debug('dine table match')
                    // alert('some thing added in dining')
                }
                else {
                    console.debug('dine table does not match')
                    // alert('hey lookie')
                    setSnackOpen(true)
                }
                dineBeforeInitialize = response

            })

        getCustomerOrder(`/restaurant/customer-orders?hour=16`).then(
            (result) => {
                let response = result;
                console.debug('checking ORDER DATA', response)
                dispatch(setTableOrder(response))
            })
    }

    const classes = useStyles();
    // dispatch(setUser(JSON.parse(localStorage.getItem('user'))))    
    const [onn, setOnn] = useState(false)
    const [check, setCheck] = useState({ seats: 2, status: 'free', userId: 1, tableid: 1, servedBy: 'abc' })
    const [checkFloor, setCheckFloor] = useState({ floorName: 'A' })
    const [tableIndex, setTableIndex] = useState(1)
    let [pay, setPay] = useState({ status: ' ' });
    // let check,checkFloor, tableIndex;
    const updateDine = (seat, tab, flor, id,wait) => {        
        let waiterId = waitersInfo.filter((item) => item.name == wait)
        console.debug('here our waiter',waiterId)
        console.debug('here our waiter name',selectedWaiter)
        // setSelectedWaiter(served)
        let payload = {
            "tableNumber": tableName,
            "seats": seating,
           // "floor": flor,
            "adminId": user.id,
            "userId": waiterId[0].id
        }
        /**billid: null
orderid: 872
seats: 4
servedBy: "waiter4"
status: "free"
tableNumber: "061-SF"
tableid: 141
 */
            updateTable(`/restaurant/editTable/${id}`, payload).then(
                (result) => {
                    console.debug('here your edit table id', result)
                    getDiningData(); 
                }
            )
        handleClose()    

        // setEditDineTable(false)

    }
    const [adminChangeWaiter,setAdminChangeWaiter]=useState(false)
    const adminWaiterAssign=(fl)=>{
        setFloorNo(fl)
        setAdminChangeWaiter(true)
    }
    const setFloorWaiter=(fl,wt)=>{
        let waiterId = allWaiters.filter((item) => item.name == wt)

let payload={
    "floor":fl,
    "userId":waiterId[0].id,
    adminId:user.id

}

postDining('/restaurant/assignFloor',payload).then(
    (result)=>{
        console.debug('here your result',result)
    }
)
    }


    const handlePopUp = (index, tableIndex, IsOrder,userId) => {
        setCheck(dineTable[index].table[tableIndex])
        setCheckFloor(dineTable[index])
        setTableIndex(tableIndex)
        let waiterId = waitersInfo.filter((item) => item.id == userId)
        setSelectedWaiter(waiterId[0])
       // setSelectedWaiter(allWaiters[0])
        if (IsOrder) {
            console.debug('not opening Dialog')
        }
        else {
            setOnn(true)
        }

        console.debug('check pop up')
    }
    const handleOk = () => {
        handleClose()
        window.print()
    }
    const closePopUp = () => {
        setBackdropOpen(true);
        setPay({ status: ' ' })
        setOnn(false);
        setBackdropOpen(false);
    }
    const [open, setOpen] = useState(false);
    const handleClickOpen = (data) => {
        setTimeout(() => {
            setOpen(true);
        }, 700);

    };
    let date = new Date().toString()

    const handleClose = () => {
        setOpen(false);
        setAdminDialogOpen(false)
        setEditDineTable(false)
        setDeleteDialog(false)
        setAdminChangeWaiter(false)
    };
    let [selectedTable, setSelectedTable] = useState();

    function postDiningStatus(id, floor, status, tableNumber, pay, orderID, billId, serve) {
        // export {floor,servedBy};
        console.debug('serving', serve);    
        setOkBtnDisabled(true);    
        closePopUp();
        setSelectedTable(tableNumber)
        let payload = {
            "user_id": id,
            "floor_table_id": floor,
            "status": pay.status,
            "orderid": orderID,
            "billid": billId
        }
        let resPay = {
            "user_id": id,
            "floor_table_id": floor,
            "status": pay.status,
            "orderid": orderID,
            "billid": billId,
            "servedBy": serve
        }
        setResponse(resPay)
        postDining('/restaurant/assignDinning', payload).then(
            (result) => {
                result.servedBy = serve
                setResponse(result)
                // console.debug(response)
                setOkBtnDisabled(false); 
            }
        )
        setPay({ status: ' ' })
        console.debug('console of pay', pay)
        dispatch(setTableStatus(pay))
        if (pay.status == "occupied") {
            handleClickOpen()
        }
    }


    console.debug('dinetable edites', dineTable)
    return (
        <div onClick={snackHandleClose}>
            {
                user ?
                    <div >
                        {dineTable && tableOrder ? <div>
                            <div className='onPrintDine' style={{ marginBottom: '20px' }}>
                                <PureBreadcrumbs />
                            </div>
                            
                            {/* <AccountTreeIcon color="primary"/> */}
                            <div>
                                {/* className='row' style={{ boxShadow: '0px 8px 23px #E899993B' }} */}
                                <div className='onPrintDine'>
                                    {dineTable.map((floorData, floorIndex) => {
                                        // console.debug('checking data', data)
                                       // console.debug('checking floornama u', floorData.floorName)
                                        var occ = floorData.table.filter((data) => data.status.includes("occupied"))
                                        var free = floorData.table.filter((data) => data.status.includes("free"))
                                        floorInfo.push(floorData.floorName)
                                        return (<div style={{ marginBottom:'10px'}}>
                                            <Accordion >
                                                <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                        <div className="col-lg-4 " >
                                                            <p style={{ textAlign: 'center' }}><u className="dinning-p-line "> <ApartmentIcon style={{ color: 'black' }} />{floorData.floorName.substring(2)}</u></p>
                                                        </div>
                                                        <div className="col-lg-4 ">
                                                            <p style={{ textAlign: 'center' }}><Fourseats fill="red" theWidth={50} theHight={40}/> <u className='dinning-p-line center'>occupied : {occ.length} </u></p>
                                                            
                                                        </div>
                                                        <div className="col-lg-4 ">
                                                            <p style={{ textAlign: 'center' }}><Fourseats fill="green" theWidth={50} theHight={40}/> <u className='dinning-p-line center'>Free : {free.length} </u></p>
                                                          {user.role == 'admin'? <div style={{position:'absolute',right:'10px',top:'0px'}}><IconButton  onClick={()=>{adminWaiterAssign(floorData.floorName)}}><MdModeEdit color="secondary" /></IconButton></div> :''}
                                                        </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                <Grid container spacing={0} alignItems="stretch" style={{ justifyContent: 'center' }}>
                                                        {/* <div> */}
                                                        {
                                                            floorData.table.map((data, tableIndex) => {
                                                                let IsOrder = false
                                                                let freeStatus = false
                                                                if (data.status == 'free') {
                                                                    freeStatus = true
                                                                }
                                                                if (data.status == 'occupied' && tableOrder.length >= 1) {
                                                                    let reqInfo = tableOrder.filter(item => item.id == data.orderid)
                                                                    if (reqInfo.length >= 1) {
                                                                        if (reqInfo[0].orderMenuItem.length >= 1) {
                                                                            IsOrder = true
                                                                        }
                                                                    }
                                                                }
                                                                return (
                                                                    // <Row>
                                                                    <div style={{ position: 'relative' }} className='showView'>
                                                                        <Grid item xs onClick={() => { handlePopUp(floorIndex, tableIndex, IsOrder,data.userId) }}  >
                                                                            <MyCard id={data.id} number={data.tableNumber} status={data.status} seats={data.seats} />
                                                                            {IsOrder ? <div style={{ position: 'absolute', bottom: '10px', left: '10px' }}>
                                                                                <IconButton><NewReleasesRounded style={{ color: '#212529' }} /></IconButton>
                                                                            </div> : ''}
                                                                        </Grid>
                                                                        {user.role == 'admin' ? <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                                                            <IconButton><Delete style={{ color: '#212529' }} onClick={() => { deleteDineDialog(data.tableid,data.tableNumber,floorData.floorName) }} /></IconButton>
                                                                        </div> : ''}
                                                                        {user.role == 'admin' ?
                                                                         <div style={{ position: 'absolute', bottom: '10px', left: '10px' }}>
                                                                            <IconButton onClick={() => { editDine(data.tableNumber, data.seats, floorData.floorName, data.tableid,data.servedBy) }}><MdModeEdit style={{ color: '#212529' }} /></IconButton>
                                                                            {data.status == 'occupied' ? <IconButton onClick={() => { moveToMenu(data.tableid, data.orderid) }}><MenuBookIcon style={{ color: 'red' }}  /></IconButton> : ''}
                                                                         </div> 
                                                                        : ''}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <hr />
                                                        <hr />
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                           
                                            {/* Admin Add table popup */}
                                            <Dialog open={adminDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                                                <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>Add New Table</DialogTitle>
                                                <DialogContent>
                                                    <FormControl style={{ minWidth: 360 }} >
                                                        <TextField
                                                            autoFocus
                                                            margin="dense"
                                                            id="tableNumber"
                                                            label="Table Number"
                                                            type="text"
                                                            fullWidth
                                                            onChange={(e) => { setAddTable(e.target.value) }}
                                                        />                                            
                                                        <Form>
                                                            <FormGroup>
                                                                <Label for="exampleSelect">Select Floor</Label>
                                                                <Input type="select" name="Floor" id="exampleSelect" onChange={(e) => { setAddFloor(e.target.value) }} value={addFloor}>
                                                                    <option></option>
                                                                    {(floorInfo || []).map((item, i) => {
                                                                        return (
                                                                            <option key={i} >
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </Input>
                                                                <Label for="exampleSelect">Select Waiter</Label>
                                                                <Input type="select" name="Waiter" id="exampleSelect1" onChange={(e) => { handleWaiterChange(e.target.value); }} value={selectedWaiter.name}>
                                                                  {(allWaiters || []).map((item, i) => {
                                                                        return (
                                                                            <option key={i} >
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </Input>
                                                            </FormGroup>
                                                        </Form>                                            
                                                        <TextField
                                                            autoFocus
                                                            margin="dense"
                                                            id="seating"
                                                            label="Seating"
                                                            type="number"
                                                            fullWidth
                                                            onChange={(e) => { setAddSeating(e.target.value) }}
                                                        />
                                                    </FormControl>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose} color="primary">
                                                        Cancel
                                                    </Button>
                                                    <Button onClick={() => addDining(addSeating, addTable, addFloor, selectedWaiter.name)} color="primary">
                                                        Create
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                            {/* End Admin Add table popup  */}
                                            {/* Delete Confirm Dialog */}
                                            <Dialog
                                                open={deleteDialog}

                                                onClose={handleClose}

                                            >
                                                <DialogTitle id="alert-dialog-slide-title">Delete table: {tableName} </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-slide-description">
                                                        Are you sure you want to delete "{tableName}" from floor {floorNo}?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose} color="primary">
                                                        Cancel
                                                    </Button>
                                                    <Button onClick={() => { deleteDine(deletetableId) }} color="secondary">
                                                        Delete
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                            {/* End Delete Confirm Dialog */}
                                            {/* Edit Floor Waiter dialog */}
                                            <Dialog open={adminChangeWaiter} onClose={handleClose} aria-labelledby="form-dialog-title">
                                                <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>Change Waiter</DialogTitle>
                                                <DialogContent>
                                                    <FormControl style={{ minWidth: 360 }} >
                                                        
                                            
                                                    <h1>Floor </h1>
                                                    <label>{floorNo}</label>

                                                        <Form>
                                                            <FormGroup>
                                                                <Label for="exampleSelect">Select Floor</Label>
                                                                <Input type="select" name="Waiter" id="exampleSelect1" onChange={(e) => { handleWaiterChange(e.target.value) }} value={selectedWaiter.name}>
                                                                {(allWaiters || []).map((item, i) => {
                                                                    return (
                                                                        <option key={i} >
                                                                            {item}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Input>


                                                        
                                                            </FormGroup>
                                                        </Form>

                                            
                                                        
                                                    </FormControl>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose} color="primary">
                                                        Cancel
                                                    </Button>
                                                    <Button onClick={() => setFloorWaiter(floorNo,  selectedWaiter.name)} color="primary">
                                                        Assign
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                            {/*end admin Floor Waiter dialog */}
                                            {/* Admin edit dinetable */}
                                            <Dialog open={editDineTable} onClose={handleClose} aria-labelledby="form-dialog-title">
                                                <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>Edit Dine</DialogTitle>
                                                <DialogContent>

                                                    <h1>Floor </h1>
                                                    <label>{floorNo}</label>
                                                    <TextField
                                                        autoFocus
                                                        margin="dense"
                                                        id="floor"
                                                        label="Dine Table"
                                                        type="text"
                                                        fullWidth
                                                        value={tableName}
                                                        onChange={(e) => { setTableName(e.target.value) }}
                                                    />
                                                    <TextField
                                                        autoFocus
                                                        margin="dense"
                                                        id="Price"
                                                        label="Seaiting"
                                                        type="number"
                                                        fullWidth
                                                        value={seating}
                                                        onChange={(e) => { setSeating(e.target.value) }}
                                                    />
                                                    <Form>
                                                        <FormGroup>
                                                            <Label for="exampleSelect">Select Waiter</Label>
                                                            <Input type="select" name="Waiter" id="exampleSelect1" onChange={(e) => { handleWaiterChange(e.target.value) }} value={selectedWaiter.name}>
                                                                <option></option>
                                                                {(allWaiters || []).map((item, i) => {
                                                                    return (
                                                                        <option key={i} >
                                                                            {item}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Input>

                                                        </FormGroup>
                                                    </Form>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose} color="primary">
                                                        Cancel
                                                    </Button>
                                                    <Button color="primary" onClick={() => { updateDine(addSeating, addTable, addFloor, tabId, selectedWaiter.name) }}>
                                                        Save
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                            {/* End Admin Edit dine table */}
                                            {/* onClose={closePopUp} */}
                                            <Dialog open={onn}   >
                                                <div className={classes.screen}>
                                                    <DialogTitle id="form-dialog-title" className={classes.screen} >Customer Detail</DialogTitle>
                                                    <Divider />
                                                    <DialogContent className={classes.popUp}>
                                                        <div className='popup-effect'>
                                                            <div className='customer-popup'>
                                                                {/* <Para title='Customer List' /> */}
                                                                <hr />
                                                                <br />
                                                                <div className='customer-popup-item'>
                                                                    <Para title='Floor Name' />
                                                                    <Para title={checkFloor.floorName} />
                                                                </div>
                                                                <div className='customer-popup-item'>
                                                                    <Para title='Table No.' />
                                                                    <Para title={check.tableNumber} />
                                                                </div>
                                                                <div className='customer-popup-item'>
                                                                    <Para title='Time In' />
                                                                    <Para title={date.slice(15, 24)} />
                                                                </div>
                                                                <div className='customer-popup-item'>
                                                                    <Para title='Floor Served By' />
                                                                    <Para title={check.servedBy} />
                                                                </div>
                                                                <div className='customer-popup-item'>
                                                                    <Para title='Current status' />
                                                                    <Para title={check.status} />
                                                                </div>

                                                                <div className='customer-popup-item'>
                                                                    <Label for="exampleSelect">Select</Label>


                                                                    <FormControl variant="filled" className={classes.formControl}>
                                                                        <InputLabel id="demo-simple-select-filled-label">Select One ...</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-filled-label"
                                                                            id="demo-simple-select-filled"

                                                                            onChange={(e) => {
                                                                                setPay({
                                                                                    floorName: checkFloor.floorName,
                                                                                    tableIndex,
                                                                                    status: e.target.value
                                                                                })
                                                                                // dispatch(setTableStatus(pay))
                                                                            }}
                                                                            value={pay.status}
                                                                        >
                                                                            <MenuItem value='cancel'>cancel</MenuItem>
                                                                            <MenuItem value='occupied'>occupied</MenuItem>
                                                                            <MenuItem value='reserved'>reserved</MenuItem>
                                                                            {/* <MenuItem value='cancel'    disabled={check.status=='free'?true:false}>cancel</MenuItem>
                                                                            <MenuItem value='occupied'  disabled={check.status!='free'?true:false}>occupied</MenuItem>
                                                                            <MenuItem value='reserved'  disabled={check.status!='free'?true:false}>reserved</MenuItem> */}
                                                                       </Select>
                                                                    </FormControl>
                                                                </div>
                                                                <br />
                                                                <div className='customer-popUp-btn'>
                                                                <Button
                                                                        disabled={okBtnDisabled}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={classes.button}
                                                                        startIcon={<SendIcon />}
                                                                        onClick={() => postDiningStatus(check.userId, check.tableid, check.status, check.tableNumber, pay, check.orderid, check.billid, check.servedBy)}
                                                                    >
                                                                        OK
                                                                    </Button>
                                                                    <Button
                                                                    
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        className={classes.button}
                                                                        startIcon={<CancelIcon />}
                                                                        onClick={closePopUp}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                    {/*check.status !== 'free' ? <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={classes.button}
                                                                        startIcon={<PrintIcon />}
                                                                        onClick={handleOk}>
                                                                        Print
                                                                        </Button> : ''*/}                                                                
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </DialogContent>

                                                </div>
                                            </Dialog>
                                            {/* Customer PopUp */}
                                            <Dialog open={open} onClose={handleClose} className='showView'>
                                                <div className={classes.screen}>
                                                    <img src="https://ab-res.web.app/static/media/header-01.81675d4a.png" style={{ height: '60px' }} />
                                                    {/*<h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Ab Restaurant</h1>*/}
                                                    <DialogTitle className={classes.screen} >Customer Detail</DialogTitle>
                                                    <Divider />
                                                    <DialogContent className={classes.screen, classes.box}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Para title="Customer Id" />
                                                            <Para title={response.customer_id} />
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Para title="Floor Name" />
                                                            <Para title={checkFloor.floorName} />
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Para title="Table No." />
                                                            <Para title={selectedTable} />
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Para title="Status" />
                                                            <Para title={response.status} />
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Para title="Current Time" />
                                                            <Para title={date.slice(0, 24)} />
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Para title="Floor Served By" />
                                                            <Para title={response.servedBy} />
                                                        </div>
                                                       {/* <div className='btnOnPrint'><Btn title='Print' width='60px' height='20px' onClick={handleOk} /></div>
                                                        <div className='btnOnPrint'><Btn title='Close' width='60px' height='20px' onClick={handleOk} /></div>*/}
                                                        <div >
                                                            <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={classes.button}
                                                                        startIcon={<PrintIcon />}
                                                                        onClick={handleOk}>
                                                                        Print
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={classes.button}
                                                                        startIcon={<CancelIcon />}
                                                                        onClick={handleClose}>
                                                                        Close
                                                                    </Button>                                                                
                                                                </div>
                                                    </DialogContent>
                                                </div>
                                            </Dialog>
                                        </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            {/* <h1 style={{ textAlign: 'center' }}>Dining !</h1> */}
                            {user.role == 'admin' ? <div style={{ display: 'flex', justifyContent: 'center' }} className='onPrintDine'>
                                <IconButton onClick={adminAddRecep}><MdAdd style={{ fontSize: "42px" }} /></IconButton>
                            </div> : ''}
                            {/* Receipt start */}
                            <div className='hideView'>
                                <img src="https://ab-res.web.app/static/media/header-01.81675d4a.png" className='rec-pop-image' />
                                {/*<h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Ab Restaurant</h1>*/}
                                <h1 style={{ fontWeight: '700' }}>Customer Table Reservation</h1>
                                <Divider style={{ borderTop: '10px solid black' }} />
                                <Row>
                                    <Col>
                                        <h2 style={{ fontWeight: 'bold' }}>Customer Id</h2>
                                    </Col>
                                    <Col>
                                        <h2 style={{ fontWeight: 'bold' }}>{response.customer_id}</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h2 style={{ fontWeight: 'bold' }}>Table No.</h2>
                                    </Col>
                                    <Col>
                                        <h2 style={{ fontWeight: 'bold' }}>{selectedTable}</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h2 style={{ fontWeight: 'bold' }}>Status</h2>
                                    </Col>
                                    <Col>
                                        <h2 style={{ fontWeight: 'bold' }}>{response.status} </h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h2 style={{ fontWeight: 'bold' }}>Current Time</h2>
                                    </Col>
                                    <Col>
                                        <h2 style={{ fontWeight: 'bold' }}>{date.slice(0, 24)} </h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h2 style={{ fontWeight: 'bold' }}>Floor Served By</h2>
                                    </Col>
                                    <Col>
                                        <h2 style={{ fontWeight: 'bold' }}>{response.servedBy}</h2>
                                    </Col>
                                </Row>
                            </div>
                            <Snackbar open={snackOpen} autoHideDuration={6000} onClose={snackHandleClose}>
                                <Alert onClose={snackHandleClose} severity="success">
                                    Dine Updated!
                                </Alert>
                            </Snackbar>
                            {/* Receipt End */}
                        </div> :
                            <Spinner />
                        }
                    </div>
                    : <div><Route path='/' component={SignIn} /></div>
            }
            <Backdrop className={classes.backdrop} open={backdropOpen}>
                <CircularProgress color="inherit" />
              </Backdrop>
        </div >
    )
};
