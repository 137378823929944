export const kitchenOrderReducer=(state={order:null},action)=>{
    switch(action.type){
        case 'SET_KITCHEN_ORDER':
            return {...state,order:action.payload}

        default:
            return {...state}
    }
}

export const postOrderReducer=(state={postOrder:[]},action)=>{
    switch(action.type){
        case 'SET_POST_ORDER':
            return{...state,postOrder:action.payload}
            default:
                return {...state}
        }
    
}

export const isPrintReducer=(state={isPrint:false},action)=>{
    switch(action.type){
        case 'SET_IS_PRINT':
            return{...state,isPrint:action.payload}
            default:
                return {...state}
        }
    }
