import {BaseURL} from './baseURL'

export function postBill(address,payload){

return new Promise((resolve,reject)=>{
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify(payload);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
};

fetch(BaseURL+address, requestOptions)
  .then(response => response.json())
  .then((result) => {
    resolve(result)
    console.log(result)})
  .catch((error) => {
  reject(error)
    console.log('error', error)
  }
    );

 
})
}