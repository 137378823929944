import React from 'react'
import { CircularProgress } from '@material-ui/core';
import { Backdrop} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Para from './Para';



const useStyles = makeStyles({
    backdrop:{
        position:'relative',
        // marginTop:'48%'
        // minHeight:'90vh',
        height:'auto',
        // marginLeft:'45%',
        zIndex:1000,
        backgroundColor:'#fff',
    
    }
})
export const Spinner=({theWidth,theHight,theTilte})=>{
    var paraTilte=theTilte?theTilte:"Loading";

const classes=useStyles()

    return(
        <div style={{width:theWidth,height:theHight}  }>
            <Backdrop className={classes.backdrop} open>
<CircularProgress color='primary' className={classes.CircularProgress}/>

</Backdrop>

<Para title={paraTilte} color="#0275d8" textAlign='center'/>



        </div>

    )
}



export default Spinner;