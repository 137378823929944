import React from 'react';
import { BrowserRouter as Router,Route,Switch,Redirect  } from 'react-router-dom';
import MainPage from './component/MainPage';
import MainPageContainer from './container/MainPageContainer';
import {SignIn} from './pages/Layout/sign-In/Sign-In'
import {useDispatch,useSelector} from 'react-redux'
import {setUser} from './redux/actions'
import CustomerList from './pages/Layout/reception/CustomerList'
// import {}
import {Reset} from './pages/Layout/sign-In/Reset'
// import {user}=useSelector(state => state.userReducer)
// import 'bootstrap/dist/css/bootstrap.min.css'
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { MailOutline } from '@material-ui/icons';
import { Reports } from './pages/Layout/storeKeeper/reports';

function App() {
  return (
    <div>
<Router>
{}
<Switch>
<Route path='/cashier'>
  <MainPageContainer />
</Route>

<Route path='/cashier'>
  <MainPageContainer />
</Route>

<Route path='/admin'>
  <MainPageContainer />
</Route>
<Route path='/accountant'>
  <MainPageContainer />
</Route>

<Route path='/kitchen'>
  <MainPageContainer/>
</Route>
<Route path='/storekeeper'>
  <MainPageContainer/>
</Route>
<Route  path='/storekeeper/reports' component={Reports} />

<Route path='/cashier/menu'>
  <MainPageContainer/>
</Route>
<Route path='/waiter'>
  <MainPageContainer/>
</Route>
<Route path='/takeaway'>
<MainPageContainer />
</Route>
<Route path='/customerList'>
<MainPageContainer/>
</Route>
<Route path="/addCustomer">
<MainPageContainer/>
</Route>
<Route path='/admin/menu'>
  <MainPageContainer />
</Route>
  <Route path="/reception">
<MainPageContainer/>
  </Route>
  <Route path="*">
            <NoMatch />
  </Route>
</Switch>

<Switch>
  <Route path="/reset" >
<Reset/>
  </Route>
  <Route exact path="/" >
<SignIn/>
  </Route>

</Switch>

</Router>


    </div>
  );
}
function NoMatch() {
  return (
    <div>
        <Redirect to="/" />
      </div>
  );
}

export default App;