export const tableMenuReducer = (state= {menu:null},action) => {
    switch(action.type) {
        case "SET_TABLE_MENU_DATA":
            return {...state,menu:action.payload}

        // case "ADD_MENU_DATA":
        //     let data=action.payload

        //     let addMenu=state.orderData.push(data)
        //     console.log('==================')
        //     console.log('add menu redux case working=============',addMenu)
        //     return {...state,orderData:addMenu}

        default:
            return {...state}
    }
}
// {
//     "id":null,
//     "userId":null,
//     "floorTableId":null,
//     "customerId":null,
//     "status":null,

//     "orderMenuItem":[{"orderItemId":null,
//     "orderId":null,
//     "menuItemId":null,
//     "name":"","categoryName":"","price":null}]


// }

export const menuIdReducer=(state={menuId:null},action)=>{
    switch(action.type){
        case "SET_MENU_ID":
            return{...state,menuId:action.payload}
    
    default:
        return{...state}
        }
    }