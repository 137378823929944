import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { Row, Col } from 'react-bootstrap'
//import './reception.css'
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { postData } from '../../../services/getData';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DataGrid } from '@material-ui/data-grid';
//import {Delete} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
//import { MdAdd } from 'react-icons/md'
import { Avatar, IconButton } from '@material-ui/core'
import Btn from '../../../component/partials/Button';
import { setCustomer } from '../../../redux/actions';
import { getCustomers } from '../../../services/getCustomerList';
import PureBreadcrumbs from '../../../component/Breadcrumb';
import { Spinner } from '../../../component/partials/Spinner';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getUsers } from '../../../services/getUsers';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import InputAdornment from '@material-ui/core/InputAdornment';
import { putData } from '../../../services/getData';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import EditIcon from '@material-ui/icons/Edit';
import { MdAdd } from 'react-icons/md';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {GridToolbar } from "@material-ui/data-grid";

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& .tr-table-order-new': {
      backgroundColor: 'rgba(255, 179, 0, 0.98)',
    },
    '& .tr-table-order-completed': {
      backgroundColor: '#fe0101',
    },
    '& .tr-table-order-in-progress': {
      backgroundColor: '#86d689',
    },
  },
  box: {

    minWidth: '500px',
    '@media print': {
      minWidth: '600px'
    }

  },
  screen: {
    padding: '0px',
    textAlign: 'center',
    '@media print': {
      textAlign: 'left'
    }
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function BillTableToolBar() {
    /*---------disable the console logs when production environment--------*/
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
  return (
    <div>
      
      <GridToolbar />
    {/*<GridToolbarFilterButton />
    <GridToolbarDensitySelector />
    <GridToolbarExport />*/}
  </div>
  );
}

export const Transactions = () => {
  const { customer } = useSelector(state => state.customerReducer)
  const { user } = useSelector(state => state.userReducer)
  const [open, setOpen] = React.useState(false);
  const [openAddMenu, setOpenAddMenu] = React.useState(false);
  const [existingQty, setExistingQty] = React.useState(0);
  const [allWaiters, setAllWaiters] = React.useState([])
  const [allSuppliers, setAllSuppliers] = React.useState([])
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('loading....');
  const [receivedData, setReceivedData] = React.useState(true);
  //const [showSuppliers, setShowSuppliers] = React.useState(false);

  const useStyles = makeStyles((theme) => ({

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));
  const [item, setItem] = React.useState('');
  const [openn, setOpenn] = React.useState(false);
  const [productOutOpen, setProductOutOpen] = React.useState(false);
  const [stockError, setStockError] = React.useState(false);
  const [isSubmitDisabled, setSubmitDisabled] = React.useState(true);
  

  const handleChangee = (event) => {
    setItem(event.target.value);
  };
  const handleClosee = () => {
    setOpenn(false);
  };
  const handleOpenn = () => {
    setOpenn(true);
  };

 //popu//
 const addMenuClickk = () => {
  //setEditCateg(get_icon_Key(menuData,data))   
  console.debug('from add popup open')
  //setBackdropOpen(true);
  setBackdropOpen(true);
  //setOpen(true);
  setOpenAddMenu(true);

}



const addProduct = () => {
  let rend = {
     // "id": values.id,
     "name":values.name,
      "quantity_type": values.quantity_type,
      "quantity": 0
  }
  if (values.name, values.quantity_type) {
    postData('/restaurant/add-product', rend).then(
          (result) => {
              if(result.id)
              {
                  setSeverity('success');
                  setAlertMessage('Product is added, system created id is: '+result.id)
                  getTransactions();
                  setOpenAddMenu(false);
                  setOpen(true);
              }
              else{
                  setSeverity('error');
                  setAlertMessage(result.message);
                  setOpenAddMenu(false);
                  setOpen(true);
              }
              console.log('add Product status')
              
          }            
      )
      console.debug("form: "+rend)
  }    
 // handleClose()
}

// end Popu//  
  const dispatch = useDispatch()
  const classes = useStyles();
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });


  function getTransactions() {
    getCustomers('/restaurant/inventory-transactions').then(
      (result) => {
        let response = result
        dispatch(setCustomer(response));
        setReceivedData(false);
      }
    )
  }
  useEffect(() => {
    getTransactions();
  }, [])
  const [values, setValues] = React.useState({
    id: '',
    quantity: '',
    quantity_type: '',
    name: '',
    price: '',
    supplier_id: '',
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === 'quantity' ) {
      if(event.target.value < existingQty)
      { 
        console.debug(prop + " : <--Yes it is");
        setSubmitDisabled(false);
      }
      else{
        setStockError(true);
        setSubmitDisabled(true);
      }     
    }    
    console.debug(prop + " : " + event.target.value);
  };
  const [dialogType, setDialogType] = React.useState("add");

  const handleClickOpen = (rowdata, clickType) => {
    setDialogType(clickType)
    if(clickType==='add')
    {
     setValues( {
       id: '',
        quantity: '',
        quantity_type: '',
        name: '',
        price: '',
       supplier_id: '',
      }); 
    }
    if(rowdata)
    {
    setValues(rowdata)
    setExistingQty(rowdata.quantity)
    console.debug("quantity:" + rowdata.quantity)
    }

    if(clickType==='decrease')
    {
      setProductOutOpen(true)
    }else
    {
      setOpenAddMenu(true);
    }

   // setOpen(true);
   

    /**  //setEditCateg(get_icon_Key(menuData,data))   
  console.debug('from add popup open')
  //setBackdropOpen(true);
  setBackdropOpen(true);
  //setOpen(true);
  setOpenAddMenu(true); */

  };

  const handleClose = () => {
    setOpen(false);
    setProductOutOpen(false);
    setStockError(false);
  };

  useEffect(() => {
    getUsers('/restaurant/users').then(
      (result) => {
        let resp = result
        setAllWaiters(resp)
      }
    )
  }, [])
  useEffect(() => {
    getUsers('/restaurant/suppliers').then(
      (result) => {
        let resp = result
        setAllSuppliers(resp)
      }
    )
  }, [])

  const postnDeleteMenu = () => {
    var url = null;
    var newQty = parseInt(existingQty);
    if (dialogType === "add") {
      addProduct();
    }
   /* else  if (dialogType === "edit") {
      url = `/restaurant/editProduct/${values.id}?name=${values.name}`
      putData(url, null).then(
        (result) => {
  
          console.log(`here deleted one menu item id is ${newQty}`);
          setAlertMessage('Product updated: '+result.id)
          setOpenAddMenu(false);
                  setOpen(true);
                  apiCall();
        }
      )
    }
    else if (dialogType === "increase") {
      newQty = parseInt(existingQty) + parseInt(values.quantity);
      url = `/restaurant/editProduct/${values.id}?quantity=${newQty}`
      putData(url, null).then(
        (result) => {
          setAlertMessage('Product updated: '+result.id)
          setOpenAddMenu(false);
                  setOpen(true);
          console.log(`here deleted one menu item id is ${newQty}`);
        }
      )
    }*/
    else {
      newQty = parseInt(existingQty) - parseInt(values.quantity);
      
      if (newQty<0)
      {
        setSeverity('warning');
        setAlertMessage('Quantity cannot be: '+newQty)
        setOpen(true);
      }
      else{
        values.quantity=newQty
        url = `/restaurant/update-product/${values.id}`;
        putData(url, values).then(
          (result) => {
            console.log(`here deleted one menu item id is ${newQty}`);
            setSeverity('success');
            setAlertMessage('Product updated: '+values.id)
            setProductOutOpen(false);
            setOpenAddMenu(false);
            setOpen(true);
            getTransactions();
          });        
      }     
        console.log(`here deleted one menu item id is ${newQty}`);
    }
  }

  const [editRowsModel, setEditRowsModel] = React.useState({});
  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model);
    console.debug("actionType???");
  }, []);
  const handleCellEditCommit = React.useCallback(
    () => {
      console.debug("2-actionType???");
    },
    [],
  );
  console.debug("editRowsModel: " + editRowsModel);
  // const [editProduct, setEditProduct] = React.useState(false);
 
  let role = user.role;
  let columns;
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'transactionKhiFormatTime',
      sort: 'desc',
    },
  ]);
 
 /*

    private String employee;
    private String transactionType;
    private int quantity;
    private String product;
    private String quantityType;
    private Date transactionTime;
     */
      columns = [

        { field: 'id', headerName: 'Trans. #', width: 150 },        
        { field: 'product', headerName: 'Product', width: 200},
        {
          field: 'fullName',
          headerName: 'Qty.',
          description: 'Quantity',
          //sortable: false,
          width: 160,
          valueGetter: (params) =>
            `${params.row.quantity || ''} ${params.row.quantityType || ''}s`,
        },
        { field: 'quantity', headerName: 'Qty.', width: 120, hide: true },
        { field: 'quantityType', headerName: 'Unit', width: 150, hide: true },
        { field: 'transactionType', headerName: 'Transaction', width: 150 },        
        { field: 'employee', headerName: 'Person', width: 150 },
        { field: 'department', headerName: 'Department', width: 200 },
        { field: 'transactionKhiFormatTime', headerName: 'Date & Time', width: 200 },        
      ]
  //console.log("customer: "+customer);
  return (
    <div className='container' style={{ maxWidth: '100%' }}>

      <PureBreadcrumbs className='onPrintDine' />

      {/*---- Main table----*/}
      {customer ?
        <div style={{ width: '100%' }} className={classes.root, 'onPrintDine'} id='product-details-list-table'>
          <DataGrid 
          rows={customer} 
          columns={columns} 
          pageSize={100} 
          autoHeight='true'
            editRowsModel={editRowsModel}
            onEditRowsModelChange={handleEditRowsModelChange}
            onCellEditCommit={handleCellEditCommit}
            sortModel={sortModel}
            density="compact"
            loading={receivedData}
            components={{
              Toolbar: BillTableToolBar
            }}
          />
          {/*<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='onPrintDine'>

            <IconButton onClick={() => { handleClickOpen(null, "add") }}><MdAdd style={{ fontSize: "42px" }} /></IconButton>

          </div>*/}

        </div>
        : <Spinner />}

{/* <Eidt/Add Product popu> */}
      <Dialog open={openAddMenu} onClose={()=>setOpenAddMenu(false)} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
        <DialogTitle id="product-details-form-dialog-title">
        {
            dialogType === "edit" ? "Edit Product" : 'Add Product'
          }
        </DialogTitle>
        <DialogContent>
          
              <TextField
                autoFocus
                margin="dense"
                id="product-details"
                label="Name"
                value={values.name}
                type="text"
                fullWidth
                onChange={handleChange('name')}
              />
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label">Item </InputLabel>
            <Select
          labelId="demo-simple-select-outlined-label"
          id="add-product-se-quantity_type"
          value={values.quantity_type}
          onChange={handleChange('quantity_type')}
          label="Item"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="liter">Liter</MenuItem>
          <MenuItem value="kilogram">Kilogram</MenuItem>
          <MenuItem value="packet">Packet</MenuItem>
          <MenuItem value="piece">Piece</MenuItem>
          <MenuItem value="bottle">Bottle</MenuItem>
          <MenuItem value="cane">Cane</MenuItem>
        </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenAddMenu(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => { postnDeleteMenu() }}  color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Admin Add Dialog */}


            {/*---- dialog PtoductOut----*/}
{      <Dialog open={productOutOpen} onClose={handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
        <DialogTitle id="product-details-form-dialog-title">
          {
            dialogType === "edit" ? "Edit Product" : ''
          }
          
          {
            dialogType === "decrease" ? "Stock out" : ''
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {values.name}
          </DialogContentText>          
              <TextField
                autoFocus
                margin="dense"
                id="product-details-quantity"
                label="Quantity"
                InputProps={{
                  endAdornment: <InputAdornment position="end">{values.quantity_type}</InputAdornment>,
                }}
                type="number"
                fullWidth
                onChange={handleChange('quantity')}
                error={stockError}              
                helperText={stockError?"Quantity cannot be less than in stock.":""} 
              />
              <Autocomplete
                //className={classes.Select}
                id="product-details-combo-box-demo"
                options={allWaiters}
                getOptionLabel={(option) => option.name}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Employee" />}
              //onChange={(event, value) => setSelectedCustomer(value)}
              /> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => { postnDeleteMenu() }} color="primary" disabled={isSubmitDisabled}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      }
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity={severity} onClose={handleClose}>
        {alertMessage}
      </Alert>
    </Snackbar>
    </div >
  )

}