export const userReducer = (state= {user:null},action) => {
    switch(action.type) {
        case "SET_USER":
            return {...state,user:action.payload}

        default:
            return {...state}
    }
}

export const usersReducer = (state= {users:null},action) => {
    switch(action.type) {
        case "SET_USERS":
            return {...state,users:action.payload}

        default:
            return {...state}
    }
}