import React from 'react'
import Para from '../../../component/partials/Para'
import Btn from '../../../component/partials/Button'
import './style.css'
//import { tableOrdersData } from './data'
import {useSelector,useDispatch} from 'react-redux'
import {setIsPrint} from '../../../redux/actions'


export const KitchenTableOrder = () => {
      /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }

let itemIdLength=[]
const dispatch=useDispatch()
    const {tableItem}=useSelector(state=>state.tableItemReducer)
    const {isPrint}=useSelector(state=>state.isPrintReducer)

const onPrint=()=>{
    dispatch(setIsPrint(true))
    window.print()
}
console.log('table item ',tableItem)
let tableData = [...new Map(tableItem[0].orderMenuItem.map(item => [item.menuItemId, item])).values()]

    const RenderItem = (tableOrderData, index) => {
        let itemId;
        let reet =tableOrderData.id?tableOrderData.id:tableOrderData.menuItemId
        console.log('reet id',reet)
        itemId=tableItem[0].orderMenuItem.filter((item)=>item.menuItemId === reet)
        console.log('item id reet',itemId)
        
             itemIdLength.push(itemId.length)
        
        return (
  <tr key={index} className='rowPad'>
        <td>{itemIdLength[index]}</td>
        {/* <td className='tdPad'>{tableOrderData.menuItemId}</td> */}
        <td>{tableOrderData.name}</td>
        <td>{tableOrderData.categoryName}</td>
        </tr>
        )
    }
    return (
    
        <div className='tableOrderContainer' >

            <div className='kitchen-table-order-nav' >
                <div style={{fontSize:'26px', fontWeight:'bolder'}}>
                    <Para title='Table Order' />
                </div>
                <div className="hiddenBtn">
                    <Btn title='Print' height='30px' width="90px" fontSize="13px" onClick={onPrint} />
                </div>
            </div>
            {/*  */}
            <div className='hideNShowOnPirnt'>
               <img src="https://ab-res.web.app/static/media/header-01.81675d4a.png" style={{height:'220px', display:'block', marginLeft:'auto', marginRight:'auto'}}/>
{/*<h1 style={{textAlign:'center',fontWeight:'bold' }}>Ab Restaurant</h1> */}
</div>
<div className='w-100'  >   
    <h3 style={{textAlign:'center', fontWeight:'bold' }}>Order Id: {tableItem[0].id}</h3>
<h3 style={{textAlign:'center', fontWeight:'bold' }}>Customer Id: {tableItem[0].customerId}</h3>
    <h1 style={{textAlign:'center',fontWeight:'bolder'}}>{tableItem[0].floorTableName==null?tableItem[0].dining_type: "Table Number: "+tableItem[0].floorTableName}</h1>
</div>
<br/>
<br/>
{/* printStyles */}
            <div className='printStyles kitchen-order-table container-fluid'>
                <table className='w-100'>
                    <thead className='kitchen-table-head'>
                    <td>QTY.</td>
                        {/* <td>Menu Id</td> */}
                        <td>ITEM</td>
                        <td>CATEGORY</td>
                    </thead>
                    <tbody>
                    {

                    tableData.map(RenderItem)}
                    </tbody>
                    </table>
                </div>
          
        </div >

    )
}