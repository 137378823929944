import {BaseURL} from './baseURL'

export function CreateDelCus(address,payload){


return new Promise((resolve,reject)=>{
var myHeaders = new Headers();
myHeaders.append("secret-key", "$2b$10$TEwfJR6/1ZSaluszaXWJxOX/fJMvS7im2V4agBm8y3r1r62qA8.Kq");
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify(payload)

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
};

fetch(BaseURL+address, requestOptions)
.then(response => response.json())
.then((result) => {
  resolve(result)
  console.log(result)})
.catch((error) => {
reject(error)
  console.log('error', error)
}
  )
    })
}