import React from 'react';
import { GeneralSetup } from "../../pages/GeneralSetup";
import { AdminSetupPage } from "../../pages/AdminSetupPage";
import { DepartmentSetupPage } from "../../pages/DepartmentSetupPage";
import { SubDepartmentSetupPage } from "../../pages/SubDepartmentSetupPage";
import { DeviceSetupPage } from "../../pages/DeviceSetupPage";
import { Settings, DesktopMac, Store, AccountBox } from "@material-ui/icons";
    // import myIcon from '../../assets/icons/'
import AddCustomer from '../../pages/Layout/reception/addCustomer'
import {CustomerList} from '../../pages/Layout/reception/CustomerList'
import {RecepDinning} from '../../pages/Layout/reception/dinningArea'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import {Menu,DinningArea,Bills,Logout,Customer,Buying,Delivery,Bike,OrderLists,AddOrder} from '../../component/partials/CustomDasboarIcon'


export const TakeawayMenu = [
    {
        id:1,
        menuName: 'Menu',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: CustomerList,
        exact: true,
        icon :<Menu />,
        submenu: []
    },
    {
        id:2,
        menuName: 'Takeaway Orders',
        menuDescription: 'Device mac id setup menus',
        path: '/takeaway',
        component: RecepDinning,
        exact: true,
        icon: <Buying />,
        submenu: []
    },
    {
        id:3,
        menuName: 'Delivery',
        menuDescription: 'General setups menus',
        path: '/takeaway/deivery',
        component: CustomerList,
        exact: true,
        icon :<Bike height="30px" width="40px"/>,
        submenu: [
            {
                id:5,
                menuName: 'Place Order',
                menuDescription: 'To Setup admins',
                path: '/takeaway/placeOrder',
                component: CustomerList,
                icon: <AddOrder  marginLeft= "20px"/>,
            },
            {
                id: 6,
                menuName: 'Order List',
                menuDescription: 'To Setup departments',
                path: '/takeaway/orderList',
                component: AddCustomer,
                icon: <OrderLists marginLeft= "20px"/>
            }
        ]
    },
    {
        id:4,
        menuName: 'Bills', 
        menuDescription: 'Device mac id setup menus',
        path: '/takeaway/bills',
        component: RecepDinning,
        exact: true,
        icon: <Bills />,
        submenu: []
    },
    
    {
        id: 7,
        menuName: 'Logout',
        menuDescription: 'Inventory setup menus',
        path: '/',
        component: DeviceSetupPage,
        exact: true,
        icon: <Logout />,
        submenu: []
        
    }
    ];




    // {
    //     id:1,
    //     menuName: 'Menu',
    //     menuDescription: 'General setups menus',
    //     path: '/takeaway/menu',
    //     component: CustomerList,
    //     exact: true,
    //     icon :<Customer />,
    //     submenu: []
    // },
    // {
    //     id:2,
    //     menuName: 'New Orders',
    //     menuDescription: 'Device mac id setup menus',
    //     path: '/takeaway',
    //     component: RecepDinning,
    //     exact: true,
    //     icon: <DinningArea />,
    //     submenu: []
    // },
    // {
    //     id: 3,
    //     menuName: 'Delivery',
    //     menuDescription: 'Inventory setup menus',
    //     path: '/takeaway/delivery',
    //     component: DeviceSetupPage,
    //     exact: true,
    //     icon: <Store />,
    //     submenu: [
    //         {
    //             id:5,
    //             menuName: 'Place Order',
    //             menuDescription: 'To Setup admins',
    //             path: '/delivery/placeOrder',
    //             component: RecepDining,
    //             icon: <AccountBox />,
    //         },
    //         {
    //             id: 6,
    //             menuName: 'Order List',
    //             menuDescription: 'To Setup departments',
    //             path: '/delivery/orderList',
    //             component: AddCustomer,
    //             icon: <AccountBox />
    //         }
    //     ]
        
    // },
    // {
    //     id: 4,
    //     menuName: 'Bills',
    //     menuDescription: 'Inventory setup menus',
    //     path: '/takeaway/bills',
    //     component: DeviceSetupPage,
    //     exact: true,
    //     icon: <Store />,
    //     submenu: []
        
    // },
    // {
    //     id: 7,
    //     menuName: 'Logout',
    //     menuDescription: 'Inventory setup menus',
    //     path: '/',
    //     component: DeviceSetupPage,
    //     exact: true,
    //     icon: <Logout />,
    //     submenu: []
        
    // }
    // ];