import React, { useEffect } from 'react';
import PureBreadcrumbs from '../../../component/Breadcrumb'

import './style.css';
//import { table } from '../reception/data'
import { MyCard } from '../../../component/partials/Card'
//import AccountTreeIcon from '@material-ui/icons/AccountTree';
//import Para from '../../../component/partials/Para'
//import Btn from '../../../component/partials/Button'
//import { BorderColor, CallMissedSharp } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom'
import { getMenuItem } from '../../../services/getMenuItem'

//import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { useDispatch, useSelector } from 'react-redux';
import { setDineTable, setMenuId, setOrderId,setMenuData,categoryItem } from '../../../redux/actions';
import { getDining } from '../../../services/getDiningData'
//import { dineData } from '../../../localDB/dinningData';
import Grid from '@material-ui/core/Grid';
import { Spinner } from "../../../component/partials/Spinner"


import MenuBookIcon from '@material-ui/icons/MenuBook';
import { IconButton } from '@material-ui/core';
import ApartmentIcon from '@material-ui/icons/Apartment';
export const Dinning = () => {
      /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.userReducer)
    const { dineTable } = useSelector(state => state.dineTableReducer)
    const { menuId } = useSelector(state => state.menuIdReducer)
    const history = useHistory()
    const moveToMenu = (id, orderId) => {
        dispatch(setMenuId(id))
        dispatch(setOrderId(orderId))
        history.push('/waiter/menu')
    }
    const {menuData}=useSelector(state=>state.menuDataReducer)
    const getData = () => {
        if (user) {
            getDining(`/restaurant/dinning?userid=${user.id}`).then(
                (result) => {
                    let response = result;
                    console.log('checking API data', response)
                    dispatch(setDineTable(response))
                })
        }
    }
    function apiCall() {
        if (user) {
            var path=`/restaurant/dinning?userid=${user.id}`
            if(user.role==='adminwaiter')
            {
                path=`/restaurant/dinning`
            }
            getDining(path).then(
                (result) => {
                    let response = result;
                    console.log('checking API data', response)
                    dispatch(setDineTable(response))
                })
        }
    }
    useEffect(() => {
        apiCall()
        if(menuData.length===0)
        {
            getMenuItem('/restaurant/getmenu').then(
                (result) => {
                    let response=result;
                    dispatch(setMenuData(response))
                    dispatch(categoryItem(response['Mutton']))
                }
            )
        }else
        {
          console.debug('menu data available!');
        }
        const interval = setInterval(() => {
            apiCall()
        }, 12000);

        return () => clearInterval(interval)

    }, [])


    return (
        <div>
            <PureBreadcrumbs />
            {/* <h1 style={{ textAlign: 'center' }}>Dining </h1> */}

            <br />


            { dineTable ? <div className='container-fluid waiter-dining-main'>
                <br className='m-5' />

                <div className='row ' >
                    {dineTable.map(data => {
                        console.log(dineTable);
                        var occ = data.table.filter((dataOccupied) => dataOccupied.status.includes("occupied"))
                        var free = data.table.filter((dataFree) => dataFree.status.includes("free"))
                        return (                                
                            <div >
                                <div className='row w-100 mt-2' >
                                                    <div className="col-lg-4 " >
                                                        <p style={{ textAlign: 'center' }}><u className="dinning-p-line "> <ApartmentIcon style={{ color: 'black' }} />{data.floorName.substring(2)}</u></p>
                                                    </div>
                                                    <div className="col-lg-4 ">
                                                        <p style={{ textAlign: 'center' }}><u className='dinning-p-line center'>occupied : {occ.length} </u></p>
                                                    </div>
                                                    <div className="col-lg-4 ">
                                                        <p style={{ textAlign: 'center' }}><u className='dinning-p-line center'>Free : {free.length} </u></p>
                                                     </div>
                                                </div>
                                                <br />
                                <Grid container spacing={0} alignItems="stretch">
                                    {
                                        data.table.map(data => {
                                            return (
                                                <Grid item xs style={{ position: 'relative' }}>
                                                    <MyCard id={data.id} number={data.tableNumber} status={data.status} seats={data.seats} />
                                                    <div style={{ position: 'absolute', bottom: '30px', left: '130px'  }}>
                                                        {/* color="primary" */}
                                                        {data.status == 'occupied' ? <IconButton onClick={() => { moveToMenu(data.tableid, data.orderid) }}><MenuBookIcon style={{ color: 'red' }}  /></IconButton> : ''}
                                                    </div>
                                                </Grid>
                                            )
                                        })

                                    }
                                </Grid>
                            </div>
                        )
                    }
                    )
                    }
                </div>
            </div> : <Spinner />
            }
        </div>


    )
}




