import React from 'react'
import AddCustomer from '../../pages/Layout/reception/addCustomer'
import {CustomerList} from '../../pages/Layout/reception/CustomerList'
import {Dinning} from '../../pages/Layout/waiter/diningArea'
import {Orders} from '../../pages/Layout/waiter/orders'
//import {TableOrder} from '../../pages/Layout/waiter/tableOrder'
import {waiterTableOrder} from '../../pages/Layout/waiter/waiterTableOrder'
import {CashierTableOrder} from '../../pages/Layout/cashier/CashierTableOrder'
import menuIcon from '../../assets/icons/menu.svg'
import {MenuItem} from '../../pages/Layout/menu/menuItem';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import {Menu,DinningArea,Bills,Logout,OrdersIcon} from '../../component/partials/CustomDasboarIcon'

// import src from '*.bmp'

export const waiterMenu=[
   // {    
    // {
    //     id:1,
    //     menuName: 'Dining Area',
    //     menuDescription: 'Device mac id setup menus',
    //     path: '/waiter',
    //     component: Dinning,
    //     exact: true,
    //     icon: <RoomServiceIcon />,
    //     submenu: []
    // },
    {
        id:1,
        menuName: 'Menu',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuItem,
        exact: true,
        icon :<Menu />,
        submenu: [
        ]
    },
    {
        id:2,
        menuName: 'Dining Area',
        menuDescription: 'Device mac id setup menus',
        path: '/waiter',
        component: Dinning,
        exact: true,
        icon: <DinningArea />,
        submenu: []
    },
    {
        id: 3,
        menuName: 'Orders',
        menuDescription: 'Inventory setup menus',
        path: '/waiter/orders',
        component: Orders,
        exact: true,
        icon: <OrdersIcon />,
        submenu: []
    },
    // },
    // {
    //     id:4,
    //     menuName: 'Table Order',
    //     menuDescription: 'Device mac id setup menus',
    //     path: '/waiter/waiterTableOrder',
    //     component: waiterTableOrder,        
    //     exact: true,
    //     icon: <AddCircleOutlineIcon />,
    //     submenu: []
    // },
    {
        id: 4,
        menuName: 'Logout',
        menuDescription: 'Inventory setup menus',
        path: '/',
        component: Dinning,
        exact: true,
        icon: <Logout />,
        submenu: []
        
    }
]
