import React from 'react'
import {RecepDinning} from '../../pages/Layout/reception/dinningArea'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {CustomerList} from '../../pages/Layout/reception/CustomerList'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {Menu,DinningArea,Bills,Logout} from '../../component/partials/CustomDasboarIcon'

// import {}

export const CashierMenu=[
    
    {
        id:1,
        menuName: 'Bills',
        menuDescription: 'Bills',
        path: '/cashier',
        component: CustomerList,
        exact: true,
        icon :<Bills />,
        submenu:[]
    },
    {
        id:2,
        menuName: 'Dining Area',
        menuDescription: 'Tables',
        path: '/cashier/dining',
        component: RecepDinning,
        exact: true,
        icon :<DinningArea />,
        submenu:[]
    },
    {
        id:3,
        menuName: 'Menu',
        menuDescription: 'Menu',
        path: '/cashier/menu',
        component: RecepDinning,
        exact: true,
        icon :<Menu />,
        // icon :<RestaurantMenuIcon />,
        submenu:[]
    }
    ,
    {
        id:5,
        menuName: 'Logout',
        menuDescription: 'Logout',
        path: '/',
        component: RecepDinning,
        exact: true,
        icon :<Logout />,
        submenu:[]
    }
]
