import React,{useEffect,useState} from 'react'
import Grid from '@material-ui/core/Grid';
import { ImageCards } from '../../../pages/Imagecards';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuData,categoryItem, setCardItem} from '../../../redux/actions';
import { getMenuItem } from '../../../services/getMenuItem';
import {delMenu} from '../../../services/deleteMenu';
import { addMenu } from '../../../services/addMenu';
import { editMenu } from '../../../services/editMenu';
import {Row,Col} from 'react-bootstrap';
import PureBreadcrumbs from '../../../component/Breadcrumb';
import Spinner from '../../../component/partials/Spinner';
import { MdAdd } from 'react-icons/md';
import { IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {MdModeEdit} from 'react-icons/md';
import { Delete } from '@material-ui/icons';
import NoImage from '../../../assets/images/no-image.png'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import MenuBookIcon from '@material-ui/icons/MenuBook';

class Inputi extends React.Component {
  render() {
      return (
          <input placeholder="Your input here" />
      );
  }
}
const useStyles = makeStyles((theme) => ({ 
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

export const MenuCard=()=>{
    /*---------disable the console logs when production environment--------*/
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
  const classes = useStyles();
const dispatch=useDispatch()
let fileInput = React.createRef()
const [inputList, setInputList] = useState([]);
const { user } = useSelector(state => state.userReducer)
const {menuData}=useSelector(state=>state.menuDataReducer)
const {card} =useSelector(state=>state.cardItemReducer)
const {item} =useSelector(state=>state.categoryItemReducer)
const {categoryName} =useSelector(state=>state.categoryNameReducer)
const [category,setCategory]=useState(categoryName)
const [price,setPrice]=useState()
const [image,setImage]=useState()
const [imageReader,setImageReader] =useState()
const [dish,setDish]=useState()
const[editCateg,setEditCateg]=useState()
const[editMenuId,setEditMenuId]=useState()
//const history=useHistory()
let [secondDialogOpen,setSecondDialogOpen]=useState(false)
let [deleteDialogOpen,setDeleteDialogOpen]=useState(false);
const[categ,setCateg]=useState([])
const [backdropOpen, setBackdropOpen] = React.useState(false);

useEffect(()=>{
 // console.debug('menu data:',menuData);
  if(menuData.length===0)
  {
    getMenuItem('/restaurant/getmenu').then(
      (result) => {
          let response=result;
          dispatch(setMenuData(response))
          setCateg(Object.keys(response))
          console.debug('here your dishes',categ)
          dispatch(categoryItem(response[categoryName]))
          
        }
  )
  
  dispatch(setCardItem(NoImage))
    
  }else
  {
    console.debug('menu data available!');
  }


},[])
const[adminDialogOpen,setAdminDialogOpen]=useState(false)
// const [secondDialogOpen,setSecondDialogOpen]=useState(false)
// let secondDialogOpen;
const handleClose=()=>{
  setAdminDialogOpen(false)
  setSecondDialogOpen(false)
  setDeleteDialogOpen(false)
  setBackdropOpen(false);
}
const postAddMenu=()=>{
    let pri=parseInt(price)
  let payload={
    "category_name":categoryName,
    "name":dish,
    "price":pri,
    
    // "status":'created',
    "admin_id":user.id
  }

 /* if(!imageReader)
  {
   const request = require('request-promise-native');
   // let jpgDataUrlPrefix = 'data:image/png;base64,';
    let jpgDataUrlPrefix = '';
    var getUrl = window.location;
    let imageUrl         =getUrl .protocol + "//" + getUrl.host + "/"+"no-image.png";//'http://localhost:3000/static/media/header-01.81675d4a.png';   
 request({
        url: imageUrl,
        method: 'GET',
        encoding: null // This is actually important, or the image string will be encoded to the default encoding
    }).then(result => {
            let imageBuffer  = Buffer.from(result);
            let imageBase64  = imageBuffer.toString('base64');
            let imageDataUrl = jpgDataUrlPrefix+imageBase64;
            payload.path=imageDataUrl;
            sendAddMenuItemRequest(payload);
        }).catch(error=>{
          console.debug("CBase64 image error: ",error);
       });
  }
  else{*/
    payload.path=imageReader;
    sendAddMenuItemRequest(payload)
  //}
 
setImage(false);
setImageReader(false);
setAdminDialogOpen(false);
}

const postEditMenu=()=>{
  let pri=parseInt(editPrice)
  let payload={
    "category_name":editCateg,
    "name":editName,
    "price":pri,
    "admin_id":user.id,    
  }
  if(imageReader){
  payload.path=imageReader
  }
  console.debug("imageReader",imageReader); 
    editMenu(`/restaurant/update-menu/${editMenuId}`,payload).then(
      (result)=>{
        console.debug('here your edit menu response',result)
        getMenuItem('/restaurant/getmenu').then(
          (result) => {
              let response=result;
              dispatch(setMenuData(response))
            setCateg(Object.keys(response))
              dispatch(categoryItem(response[editCateg]))
              console.debug("with categoryItem..")
              setBackdropOpen(false);
            }
      ).catch(error=>{
        console.debug("error: ",error);
     });
      }
    )
    .catch(error=>{
      console.debug("error: ",error);
   });
setImage(false);
setImageReader(false);
dispatch(setCardItem(NoImage))
setSecondDialogOpen(false)
}
const postDeleteMenu=()=>{
  delMenu(`/restaurant/deleteMenu/${editMenuId}`).then(
    (result)=>{      
      getMenuItem('/restaurant/getmenu').then(
        (result) => {
            let response=result;
            dispatch(setMenuData(response))
            console.debug("Object.keys(response) ",Object.keys(response))
          setCateg(Object.keys(response))
          console.debug("response[editCateg] ",response[categoryName])
            dispatch(categoryItem(response[categoryName]))
            console.debug("with categoryItem..")
            setBackdropOpen(false);
          }).catch(error=>{
            console.debug("error: ",error);
         });
         
    }
    
  ).catch(error=>{
    console.debug("error: ",error);
 });
 // document.getElementById(`menu-item-column-${editMenuId}`).remove()

  setDeleteDialogOpen(false);
}
//deleteDialogOpen()
const addMenuClick=()=>{ 
  //setEditCateg(get_icon_Key(menuData,data))   
  console.debug('from add popup editCategory',editCateg)
  //setBackdropOpen(true);
  setBackdropOpen(true);
  setAdminDialogOpen(true)
}
const editMenuClick=(data)=>{
  setEditCateg(get_icon_Key(menuData,data))
  setEditName(data.name)
  setEditPrice(data.price)
  setEditMenuId(data.id)
  console.debug('here your find selected category',get_icon_Key(menuData,data))
  setSecondDialogOpen(true)
  setBackdropOpen(true);
  console.debug('edit box open')
}
const deleteMenuClick=(data)=>{
  //setEditCateg(get_icon_Key(menuData,data))
  setEditName(data.name)
  setEditPrice(data.price)
  setEditMenuId(data.id)
  setDeleteDialogOpen(true)
  setBackdropOpen(true);
 }
 const sendAddMenuItemRequest=(payload)=>{
  addMenu(`/restaurant/add-menu`,payload).then(
    (result)=>{
      console.debug('here your push api response: ',result)
      getMenuItem('/restaurant/getmenu').then(
        (result) => {
            let response=result;
            dispatch(setMenuData(response))
            console.debug("Object.keys(response) ",Object.keys(response))
          setCateg(Object.keys(response))
          console.debug("response[editCateg] ",response[categoryName])
            dispatch(categoryItem(response[categoryName]))
            console.debug("with categoryItem..")
            setBackdropOpen(false);
          }).catch(error=>{
            console.debug("getmenu, error: ",error);
         });
    }
  ).catch(error=>{
    console.debug("No Menu item added, error: ",error);
 });
 }


// secondDialogOpen=false
//console.debug('here your image',image)
// if(image){console.debug('here your image with filter',image[0].name)}


const [pictures,setPictures]=useState()
function onDrop(e){
  // console.debug('here concat picture',picture)
  // setPictures(pictures.concat(picture))
  // setImage(pictures.concat(picture))
  setPictures(e.target.files[0])
  setImage(e.target.files[0])
  let file= e.target.files[0]
  let reader = new FileReader();

  reader.onloadend=(file)=>{
    setImageReader(reader.result)
  }
  reader.readAsDataURL(file)
  }

  function fileChange(e) { 
    document.getElementById('inp_img').value = '';
    
    var file = e.target.files[0];

    if (file.type == "image/jpeg" || file.type == "image/png") {

       var reader = new FileReader();  
       reader.onload = function(readerEvent) {
  
          var image = new Image();
          image.onload = function(imageEvent) {    
             var max_size = 300;
             var w = image.width;
             var h = image.height;
            
             if (w > h) {  if (w > max_size) { h*=max_size/w; w=max_size; }
             } else     {  if (h > max_size) { w*=max_size/h; h=max_size; } }
            
             var canvas = document.createElement('canvas');
             canvas.width = w;
             canvas.height = h;
             canvas.getContext('2d').drawImage(image, 0, 0, w, h);
                
             if (file.type == "image/jpeg") {
                var dataURL = canvas.toDataURL("image/jpeg", 1.0);
             } else {
                var dataURL = canvas.toDataURL("image/png");   
             }
             document.getElementById('inp_img').src = dataURL;
             setImageReader(dataURL);
             console.debug("dataURL",dataURL);   
          }
          image.src = readerEvent.target.result;
          
       }
       reader.readAsDataURL(file);
       
    } else {
       document.getElementById('inp_file').value = ''; 
       alert('Please only select images in JPG- or PNG-format.');  
    }
 }
// const [editCateg,setEditCateg] =useState()
const [editName,setEditName] =useState()
const [editPrice,setEditPrice] =useState()
// const [editName,setEdit] =useState()
/*useEffect(()=>{

  Object.values(menuData).forEach(element => {
    element.forEach(menuItem => {
      console.debug("the menu item id:",menuItem.id);
      if(menuItem.path===null)
      {
    
     getData('/restaurant/menu-item-image/'+menuItem.id).then(
        (result) => {
            let response=result;
    
            //setItemImage('data:image/png;base64,'+response)
            //console.debug("the image to be-->",'data:image/png;base64,'+response);
            menuData[category][index].path='data:image/png;base64,'+response
            
            dispatch(setMenuData(menuData)) 
            //console.debug("2-the Image menuData[category][index]",menuData[category][index])
          }).catch(error=>{
            console.error("ImageCards; error: ",error);
         });
        }else{
          console.debug("the Image data path is not NULL!")
        }
    });
   });

 },[])*/


const get_icon_Key =( icons_object,file_extention) => {
  // For each key we chack if the value is contained in the list of values
  let key = Object.keys(icons_object).find(
   k=> menuData[k].find(
           // At this leve we check if the extention exist in the array of the specific object value ie. 'music', 'video' ...
           icons_ext => icons_ext === file_extention)  
           // if we find it means this is the key we are looking for
           ? true: false);
   return key
}

    return(
        <div>

          <PureBreadcrumbs />
          <div style={{ paddingLeft: '15px' }}><Chip  label={categoryName.toUpperCase()} variant="outlined" color="primary" icon={<MenuBookIcon />} /></div>
          
          {item ?
 <Grid container spacing={2} >
        <Grid item xs={12} lg={12} md={12} spacing={0} >
          <div >
          {user.role == 'admin' ? <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='onPrintDine'>

<IconButton onClick={addMenuClick}><MdAdd style={{ fontSize: "42px" }} /></IconButton>

</div> : ''}
          <Row>
          {inputList}
            {
              item.map((data, index) => {
                /*if(editCateg===undefined)
                {
                  setEditCateg(data.category_name)
                  console.debug("data.category_name",data.category_name)
                }*/
                return(
<Col id={"menu-item-column-"+data.id}>
                  <div style={{ position: 'relative' } }>
                    <ImageCards price={data.price} image={data.path?'data:image/png;base64,'+data.path:NoImage}  name={data.name} category={categoryName} data={data} index={index} />
                  </div>
                 {user.role=='admin'? <div style={{position:'absolute',top: '10px', left: '30px'}} onClick={()=>editMenuClick(data)}>
                  <MdModeEdit style={{ color: '#212529' }}/>
                  </div>:''}
                  {user.role=='admin'?<div style={{position:'absolute',top: '10px', left: '200px'}} onClick={()=>deleteMenuClick(data)}>
                  <Delete style={{ color: '#212529' }}  />
                  
                    </div>:''}
</Col>
)

  }
  )
  

      }
       </Row>

      </div>
        </Grid>
      
      </Grid>
:<Spinner />}

{/* Admin add Menu Dialog */}
<Dialog open={adminDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{textAlign:'center'}}>Add New Menu Item</DialogTitle>
        <DialogContent>
       
    {/*<Form>                    
     <FormGroup>
        <Label for="exampleSelect">Category</Label>
        <Input type="select" name="Floor" id="exampleSelect" value={categoryName} onChange={(e)=>{setCategory(e.target.value)}}>
        
          {(categ || []).map((item, i) => {
             return (
               <option key={i} >
                 {item}
               </option>
             );
           })}
        </Input>
      </FormGroup>
          </Form> 
      <FormControl fullWidth >
        <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={editCateg}
         // onChange={handleChange('role')}
          onChange={(e)=>{setCategory(e.target.value)}}
          label="Category"
        >
          {(categ || []).map((item, i) => {
             return (
              <MenuItem value={i}>{item}</MenuItem>
             );
           })}          
        </Select>
      </FormControl>*/}
       
          {/* <TextField
            autoFocus
            margin="dense"
            id="tableNumber"
            label="Category Name"
            type="text"
            fullWidth
          /> */}
          <TextField disabled id={"category-"+categoryName} label="Category" defaultValue={categoryName} />
          <TextField
            autoFocus
            margin="dense"
            id="floor"
            label="Dish Name"
            type="text"
            fullWidth
            onChange={(e)=>{setDish(e.target.value)}}
          />
          <TextField
            autoFocus
            margin="dense"
            id="Price"
            label="Price"
            type="number"
            fullWidth
            onChange={(e)=>{setPrice(e.target.value)}}
          />

{/* <ImageUploader 
                withIcon={true}
                buttonText='Choose images'
                onChange={onDrop}
                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                maxFileSize={5242880}
          

            /> */}
            <img id="inp_img" src="" />
            <input type="file" onChange={fileChange} />
            
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={postAddMenu} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
{/* End admin dialog box */}


<Dialog open={secondDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{textAlign:'center'}}>Edit Menu Item</DialogTitle>
        <DialogContent>
       
      <h1>Category</h1>
      <label>{categoryName}</label> 
       
          {/* <TextField
            autoFocus
            margin="dense"
            id="tableNumber"
            label="Category Name"
            type="text"
            fullWidth
          /> */}
          <TextField
            autoFocus
            margin="dense"
            id="floor"
            label="Dish Name"
            type="text"
            fullWidth
            value={editName}
            onChange={(e)=>{setEditName(e.target.value)}}
          />
          <TextField
            autoFocus
            margin="dense"
            id="Price"
            label="Price"
            type="number"
            fullWidth
            value={editPrice}
            onChange={(e)=>{setEditPrice(e.target.value)}}
          />
          <img id="inp_img" src="" />
            <input type="file" onChange={fileChange} />

            {/* <input type="file" onChange={onDrop} /> */}

{/* <ImageUploader 
                withIcon={true}
                buttonText='Choose images'
                onChange={onDrop}
                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                maxFileSize={5242880}
          

            /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={postEditMenu} color="primary">
            Edit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{textAlign:'center'}}>Delete Menu Item</DialogTitle>
        <DialogContent>
        <h6>Are you sure you want ro remove the following Menu Item?</h6>
        <label>{categoryName}</label> <br/>
        <label>{editName}</label><br/>
        <label>{editPrice} Rs</label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={postDeleteMenu} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </div>
    )
}