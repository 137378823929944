import React, { useEffect, useState } from 'react'
// import { createBrowserHistory} from 'history'
// import {useLocation} from 'react-router-dom'
import { Component } from 'react'
// import {useSelector} from 'react-redux'
import { Row, Col } from 'react-bootstrap'
// import profilePicture from '../../../assets/images/profile/pfp.png';
import './style.css'
// import {waiterCard} from '../../../component/partials/waiterCard'
import  MenuData  from '../../../assets/menuImages/49.jpg'
import { getDining } from '../../../services/getDiningData'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import { useSelector, useLocation, useDispatch } from 'react-redux'
import { setTableMenu } from '../../../redux/actions'
import Para from '../../../component/partials/Para'
import Heading from '../../../component/partials/Heading'
import Btn from '../../../component/partials/Button'
import TextField from '@material-ui/core/TextField';

import { TableBill } from '../../../component/partials/TableBillCommon'


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Spinner } from '../../../component/partials/Spinner';
import { postBill } from '../../../services/postBill';
import { DateRange } from '@material-ui/icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';


const useStyles = makeStyles({
  box: {

    minWidth: '500px',
  },
  screen: {
    padding: '0px',
    textAlign: 'center'
  },

  inputField: {
    minHeight: '40px',
    minWidth: '200px'
  },
  table: {
    minWidth: 500,
  },
  tableHeader: {
    font:'17px monospace !important;',
    fontWeight:"bold !important;",
  },
  tableRow: {
    font:'17px monospace !important;',
  },
  tableRowTotal: {
    font:'18px monospace !important;',
    fontWeight:"bold !important;",
  },
});



export const CashierTableOrder = () => {
    /*---------disable the console logs when production environment--------*/
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
  const { bill } = useSelector(state => state.billReducer)
  const { table } = useSelector(state => state.currentTableReducer)
  const [recieve, setRecieve] = useState()
  const [remain, setRemain] = useState()
  const classes = useStyles();
  // const {table}=useSelector(state=>state.currentTablleReducer)
  const { menu } = useSelector(state => state.tableMenuReducer)
  const {billsInfo} =useSelector(state=>state.billInfoReducer)
  const { user } = useSelector(state => state.userReducer)

  // const [menu,setMenu]=useState()

  const [open, setOpen] = useState(false);
  const [paidStatus, setPaidStatus] = useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [enter, getEnter] = useState(0)

  let totalPrice = 0;
  let receiptTotal=0
  const dispatch = useDispatch()
  const [alertMessage, setAlertMessage] = useState('')
  // const [okButton,setOkButton]=useState();
  // const [submitButtonColor,setSubmitButtonColor] =useState();
  let okButton, submitButtonColor;
let date=new Date().toString()



  const handleOK = (value) => {
    setRecieve(value)
    console.log('my value', value)
    if (value < totalPrice) {
      setAlertMessage('Enter Right Amount')
    }
    else {
      setPaidStatus(true)
      handleClose()
    }


  }
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setLoading(true)
    }, 500);

    return () => clearInterval(interval)
  }, [])

console.log('current time ',date)

// if(paidStatus==true){
  //     okButton=false

  //     submitButtonColor="#ff0000"
  //     console.log('hello')
  // }
  // else{
  //   okButton=true
  //   submitButtonColor='gray'
  // }
  if(billsInfo){
  billsInfo[table].menuitemlistBillList.map((item)=>{return totalPrice+=item.price})

  }
  let billData = [...new Map(billsInfo[table].menuitemlistBillList.map(item => [item.id, item])).values()]

  console.log('check vill datat',billData)
console.log('check actual bill',billsInfo[table])
  const [billStatus, setBillStatus] = useState(billsInfo[table].orderItemBill.billStatus)
  let currentData = billsInfo[table].orderItemBill;


  const [updatePostStatus, setUpdatePostStatus] = useState(false)
  const postOrder = () => {
    setUpdatePostStatus(true)
    okButton = true
    submitButtonColor = 'gray'

    const payload = {
      "status": "paid",
      "total_amount": totalPrice,
      "payment_method": "cash",
      "paid": recieve,
      "return_amount": recieve - totalPrice
    }
    // okButton=true
    setBillStatus('Paid')
    postBill(`/restaurant/bill/${billsInfo[table].orderItemBill.billId}`,payload).then(
      (result)=>{
        console.log('result',result)

      }
    )
    window.print()




  }
  let itemIdLength=[]
  let statusColor;
  console.log('bill status', billsInfo[table].orderItemBill.billStatus)
  console.log('current date',date.slice(0,24))
  if (billStatus == 'new' || billStatus == 'progress'&& paidStatus == true ) {
    okButton = false

    submitButtonColor = "#ff0000"
    statusColor = 'red'
  }
  else {
    okButton = true

    submitButtonColor = "gray"

    statusColor = 'green'
  }


  const RenderItem = (tableOrderData, index) => {
    // receiptTotal += tableOrderData.price
    let itemId;
let reet =tableOrderData.id?tableOrderData.id:tableOrderData.menuItemId
console.log('reet id',reet)
itemId=billsInfo[table].menuitemlistBillList.filter((item)=>item.id === reet)
console.log('item id reet',itemId)
itemIdLength.push(itemId.length)
  let totalAmount=tableOrderData.price * itemIdLength[index];
    return (
      <tr key={index} className='rowPad'>
        <td >{tableOrderData.name}</td>
        <td >{itemIdLength[index]}</td>
        <td>{tableOrderData.price}</td>
        <td>{totalAmount}</td>
      </tr>
    )
  }
  

  return (
   <div >
    {billsInfo? <div>
      <div className='printStyle kitchen-order-table container-fluid'>
      <img src="https://ab-res.web.app/static/media/header-01.81675d4a.png" style={{height:'220px', display:'block', marginLeft:'auto', marginRight:'auto'}}/>
        {/*<h1 style={{fontWeight:'bold', textAlign:'center'}}>AB Restaurant</h1>*/} 
        <div style={{textAlign:'center'}}>Address: Near Malir Bridge, Malir Karachi</div>
        <div style={{textAlign:'center'}} >Phone: 021-34500919</div>
        <div style={{fontSize:'30px', textAlign:'center' }}>{user.role=='takeaway'?'Order : Takeaway':'Table : '+billsInfo[table].orderItemBill.dinningTable}</div>
        <div style={{textAlign:'center'}} >Bill Id : {billsInfo[table].orderItemBill.billId}</div>
        <div style={{textAlign:'center'}}>Served by : {currentData.waiter}</div>
        <div style={{textAlign:'center'}}> {date.slice(0,24)}</div>
        <table className='w-100'>
          <thead className='kitchen-table-head'>
            <td>ITEM</td>
            <td>QTY</td>
            <td>PRICE</td>
            <td>AMOUNT</td>
          </thead>
          <tbody>
            {billData.map(RenderItem)}
          </tbody>
        </table>
        <br />
        <Divider style={{borderTop:'5px dashed black'}}/>
        <h1 style={{textAlign:'center'}}>TOTAL AMOUNT : {totalPrice}</h1>
        <h1 style={{textAlign:'center'}}>RECIEVED : {recieve}</h1>
        <h1 style={{textAlign:'center'}}>REMAINING : {recieve - totalPrice}</h1>
        
      </div>


  {
    loading ? <Grid container spacing={0} alignItems="stretch" className='cashierTableOrder'>
 
      <Grid  className='container' style={{ backgroundColor: '#F5F5F5', padding: '10px' }}>
     { /*  <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Heading title={billStatus} fontSize='28px' />
          <Heading title=' Order' fontSize='28px' />

        </div>
        <Divider />*/}
        {user.role !=='takeaway'?
        <Row>
          <Col><Para title='Table Number' font='normal normal normal 14px/21px mont-med' /></Col>
          <Col><Para title={currentData.dinningTable} textAlign='right' font='normal normal normal 14px/21px mont-med' /></Col>

        </Row>:''}

        <Row>
          <Col><Para title={user.role=='takeaway'?'Delivered by':'Waiter'} font='normal normal normal 14px/21px mont-med' /></Col>
          <Col><Para title={currentData.waiter} textAlign='right' font='normal normal normal 14px/21px mont-med' /></Col>

        </Row>

        <Row>
          <Col><Para title='Bill Id' font='normal normal normal 14px/21px mont-med' /></Col>
          <Col><Para title={currentData.billId} textAlign='right' font='normal normal normal 14px/21px mont-med' /></Col>

        </Row>
        <Row>
          <Col>
            <Para title="Status" />
          </Col>

          <Col>
            <Para title={billStatus} textAlign='right' color={statusColor} fontSize="28px" fontWeight='800' />
          </Col>

          {updatePostStatus ?
            <Col>
              <Para title="Payment successful" color='green' /></Col> : ''}

        </Row>
        <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size="small">
      <TableHead>
          <TableRow>
          <TableCell></TableCell>
            <TableCell className={classes.tableHeader}>Name</TableCell>
            <TableCell className={classes.tableHeader} align="right">Qty.</TableCell>
            <TableCell className={classes.tableHeader} align="right">Price (Rs)</TableCell>
            <TableCell className={classes.tableHeader} align="right">Item Price (Rs)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
        billData.map((item, index) => {
            return (
              <TableRow key={item.name}>
                <TableCell className={classes.tableRow}><RestaurantMenuIcon fontSize="small" color="primary" /></TableCell>
              <TableCell className={classes.tableRow}>{item.name}</TableCell>
              <TableCell className={classes.tableRow}  align="right">{itemIdLength[index]}</TableCell>
              <TableCell className={classes.tableRow}  align="right">{item.price}</TableCell>
              <TableCell className={classes.tableRow}  align="right">
              { item.price*itemIdLength[index]}
              </TableCell>
              
            </TableRow>
            )
          })}
          <TableRow>
          <TableCell rowSpan={3} colSpan={2}/>
            <TableCell className={classes.tableRowTotal} align="right" colSpan={2}>TOTAL</TableCell>
            <TableCell className={classes.tableRowTotal} align="right">{totalPrice}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableRowTotal} align="right" colSpan={2}>Recieved</TableCell>
            <TableCell className={classes.tableRowTotal} align="right">{recieve} </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableRowTotal} align="right" colSpan={2}>Remaining</TableCell>
            <TableCell className={classes.tableRowTotal} align="right">{recieve ? recieve - totalPrice : 0} </TableCell>
          </TableRow>
          </TableBody>
        </Table>
    </TableContainer>



        <Divider />
       
        
   
        <Btn title="Cash" margin="20px 0 0 0" borderRadius='15px' height='60px' onClick={handleClickOpen} />
        <Btn title="Card" margin="20px 0 0 0" borderRadius='15px' height='60px' disabled={true} bgColor='gray' />
        <Btn title="Submit" margin="20px 0 0 0" borderRadius='15px' height='60px' bgColor={submitButtonColor} disabled={okButton} onClick={postOrder} />


      </Grid>
    </Grid> : <Spinner />
  }
  {/* aria-labelledby="form-dialog-title" */ }
  <Dialog open={open} onClose={handleClose} className={classes.box}>
    <div className={classes.screen}>
      <DialogTitle id="form-dialog-title" className={classes.screen, classes.box} >Cash</DialogTitle>

      <DialogContent className={classes.screen}>
        {/* <button className={classes.avatar}>green</button> */}
        <div style={{ backgroundColor: '#FF0000', height: '60px' }} >
          <Heading title="Cash" color='white' width='100%' fontSize='25px' />
        </div>
        <br />
        <form className={classes.root} noValidate autoComplete="off">
          <TextField id="outlined-basic"
            label="Enter Amount"
            type='number'
            variant="outlined"
            required='true'
            className={classes.inputfield}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5)
            }}
            onChange={(e) => { getEnter(e.target.value) }} />

          <Para title={alertMessage} color='red' />
        </form>

        <br />
        <Btn title='OK' width='50%' height="40px" onClick={() => { handleOK(enter) }} />
        <br />
      </DialogContent>

    </div>
  </Dialog>

</div>:''}
    </div >


  )
}



















































{/* <Grid item xs={4}>
        <List component="nav" aria-label="main mailbox folders">
        <ListItem >
          <ListItemText primary="Invoice number" />
          <ListItemText primary="192837" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="Cashier name" />
          <ListItemText primary="Nadeem" />
        </ListItem>
      </List>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folders">
        <ListItem >
          <ListItemText primary="Chicken karahi (full)" />
          <ListItemText primary="2000" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Spam" />
          <ListItemText primary="120" />
        </ListItem>
      </List>
      <Divider />
      <List >
        <ListItem >
          <ListItemText primary="Total" />
          <ListItemText primary="2120" />
        </ListItem>
        <ListItem >
          <ListItemText primary="Payment method" />
          <ListItemText primary="Cash" />
          </ListItem >
      </List>
        </Grid>        
      </Grid> */}