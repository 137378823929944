import React,{useEffect,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import {setMenuData } from '../../redux/actions';
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import './style.css';
import {getData} from '../../services/getData';
import NoImage from '../../assets/images/no-image.png';
import { Spinner } from '../../component/partials/Spinner';


const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: [12, "!important"],
        fontWeight:"bold",
        // lineHeight:"5px"
      }
    }
  }
});
export function ImageCards({image,id,price,category,name,maxHeight,height,data,index} ){
  const {menuData}=useSelector(state=>state.menuDataReducer);
  const [loading, setLoading] = React.useState(false);
 const [itemImage,setItemImage]=useState()
 const [values, setValues] = React.useState([{
  id: '',
  image: ''
}]);
const dispatch=useDispatch()
console.debug("------------"+data.id+"------------")

useEffect(()=>{
  if(category)
{  setLoading(true);
  if(data.path===null)
  {getData('/restaurant/menu-item-image/'+data.id).then(
    (result) => {
        let response=result;        
        setItemImage('data:image/png;base64,'+response)
        //console.debug("the image to be-->",'data:image/png;base64,'+response);
        menuData[category][index].path='data:image/png;base64,'+response       
        dispatch(setMenuData(menuData)) 
        console.debug("2-the Image menuData[category][index]",menuData[category][index])
        setLoading(false);
      }).catch(error=>{
        console.error("ImageCards; error: ",error);
     });
    }else{
      console.debug("the Image data path is not NULL!");
      setLoading(false);
    }}
 },category?[menuData[category][index]]:'')


  /*  if(menuData[category][index]=== "undefined" || menuData[category][index].path === "undefined")
    {
      console.debug("the Image path is: undefined");
    }else if(menuData[category][index].path === 'data:image/png;base64,')
    {
      console.debug("the Image path is: data:image/png;base64,");
      setItemImage(NoImage); 
    }else
    {
      console.debug("the Image path is: OK");
    }*/
    
      return(
      <div className="table-Cards" >
        <Card style={{width:'200px',maxHeight:maxHeight ,height:height}  }>
        {
          <CardMedia
             image={data.path?(menuData[category][index].path==='data:image/png;base64,'?NoImage:menuData[category][index].path):NoImage} 
            //image={itemImage?itemImage:NoImage}
             title={data.name+'-'+data.id}
          />
          
          } 
        <CardActionArea>
        
        <CardContent className='imagecards-contents' >

        <ThemeProvider theme={theme} >
      <div className="App">
          <Typography gutterBottom variant="body2" className='image-card-title' >
               {name}
          </Typography>
      </div>
    </ThemeProvider>



          <Typography  >
         <p className="image-card-typoo" style={{fontSize:"13px"}}> {category}</p>
          </Typography>
          {/* <Typography >
             <p className="image-card-typoo" style={{fontSize:"13px"}}>Item number {id}</p>
          </Typography> */}
          <Typography >
              <p className="image-card-typoo" style={{fontSize:"13px"}}> Rs {price}</p> 
          </Typography> 
        </CardContent>
      </CardActionArea> 
         
    </Card> 
    </div>
    
    
// </>
      );
  }
  
