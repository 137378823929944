import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
//import { Row, Col } from 'react-bootstrap'
//import './reception.css'
import { makeStyles } from '@material-ui/core/styles';
import {Divider} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import  {DataGrid}  from '@material-ui/data-grid';
//import {Delete} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
//import { MdAdd } from 'react-icons/md'
import { Avatar, IconButton } from '@material-ui/core'
import Btn from '../../../component/partials/Button';
import {setCustomer} from '../../../redux/actions';
import {getCustomers} from '../../../services/getCustomerList';
import PureBreadcrumbs from '../../../component/Breadcrumb';
import { Spinner } from '../../../component/partials/Spinner';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getUsers } from '../../../services/getUsers';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import InputAdornment from '@material-ui/core/InputAdornment';
import {putData} from '../../../services/getData';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {deleteRecord} from '../../../services/deleteRecord'
import {GridToolbar, GridOverlay } from "@material-ui/data-grid";
import PaymentIcon from '@material-ui/icons/Payment';
import FormHelperText from '@material-ui/core/FormHelperText';
import { SalesChart } from './SalesChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles((theme) => ({
  root: {
    width:'100%',
    '& .tr-table-order-new': {
      backgroundColor: 'rgba(255, 179, 0, 0.98)',
    },
    '& .tr-table-order-completed': {
      backgroundColor: '#fe0101',
    },
    '& .tr-table-order-in-progress': {
      backgroundColor: '#86d689',
    },
  },
  box: {
    
    minWidth:'500px',
    '@media print':{
      minWidth:'600px'
    }
    
  },
  screen:{
    padding:'0px',
    textAlign:'center',
    '@media print':{
      textAlign:'left'
    }
  },
  diaContent:  {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    '& > * + *': {
        marginTop: theme.spacing(2),
  }
},
backdrop: {
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
},
formControl: {
  margin: theme.spacing(1),
  minWidth: 120,
},
selectEmpty: {
  marginTop: theme.spacing(2),
},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function BillTableToolBar(props) {
  console.debug("props",props)
  return (
    <div>
      <GridToolbar />
  </div>
  );
}
function TableFooter() {
  return (
    <div>
      the footer
  </div>
  );
}

export const SalesOverview=()=>{
    /*---------disable the console logs when production environment--------*/
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
const [allWaiters, setAllWaiters] = React.useState([])
const [backdropOpen, setBackdropOpen] = React.useState(false);
const [receivedData, setReceivedData] = React.useState(true);
const [open, setOpen] = React.useState(false);
const classes=useStyles();

const [totalSales, setTotalSales] = React.useState(0);
useEffect(()=>{
  getSales(8);
},[]);
const getSales = (theHours) => {
  getUsers('/restaurant/sales?hours='+theHours).then(
    (result) => {
        let resp = result
        console.debug("sales",resp);
        setAllWaiters(resp)    
        setReceivedData(false) 
        var total=0;
        resp.forEach(datai =>{
          total+=Number(datai.totalAmount)
         // console.debug("The price:", datai.price);
        })
        console.debug("Total price:", total);
        setTotalSales(total)           
    }).catch(error=>{
      console.debug("error: ",error);
   });
};
const handleClose = () => {
  setOpen(false);
};
const handleClickOpen = () => {
  setOpen(true);
};
const [totalSalesVisibleRows, setTotalSalesVisibleRows] = React.useState(0);
const billFilterModelChange = (dataInRows) => {
  var total=0;
  dataInRows.forEach(datai =>{
          total+=Number(datai.totalAmount)
         // console.debug("The price:", datai.price);
        })
        //console.debug("Total price:", total);
        setTotalSalesVisibleRows(total) 
};
const [totalPrice, setTotalPrice] = React.useState(new Map())

let columns;
const [sortModel, setSortModel] = React.useState([
  {
    field: 'id',
    sort: 'asc',
  },
]);
const [hours, setHours] = React.useState(8);

  const handleChange = (event) => {
    console.debug("event.target.value:"+event.target.value);
    setHours(event.target.value);
    console.debug("hours:"+hours);
    getSales(event.target.value);
  };

{
columns=[ 
  { field: 'id', headerName: 'Menu Item #', width: 150 },
  { field: 'itemName', headerName: 'Item name', width: 250},
  { field: 'itemCategoryName', headerName: 'Category', width: 250 },
  { field: 'itemPrice', headerName: 'Item price', width: 150, 
    valueFormatter: (params) => {
      return `${params.value} Rs`;
    }
  },
  { field: 'totalItem', headerName: 'Total items', width: 150 },
  { field: 'totalAmount', headerName: 'Total amount', width: 150, 
    valueFormatter: (params) => {
      return `${params.value} Rs`;
    }
  },
]
}
        return(
  <div className='container' style={{ maxWidth: '100%' }}>
    <Stack
  direction="row"
  spacing={2}
>
<FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Sales in last</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={hours}
          onChange={handleChange}
          label="Sales in last"
        >
          <MenuItem value={8}>8 hours</MenuItem>
          <MenuItem value={16}>16 hours</MenuItem>
          <MenuItem value={24}>24 hours</MenuItem>
          <MenuItem value={168}>7 days</MenuItem>
          <MenuItem value={720}>30 days</MenuItem>
          <MenuItem value={1440}>60 days</MenuItem>
        </Select>
      </FormControl>
<IconButton  aria-label="increase" >
<ShowChartIcon  color="primary" onClick={() => { handleClickOpen() }} />
</IconButton>
</Stack>

{/*<PureBreadcrumbs className='onPrintDine'/>*/}


<div style={{  width: '100%' }} >
    
<DataGrid  
        rows={allWaiters} 
        columns={columns} 
        pageSize={100} 
        autoHeight='true' 
        sortModel={sortModel}
      //  getRowId={(row) => row.orderItemId}
        density="compact"
        components={{
          Toolbar: BillTableToolBar,
        }}
        rowsPerPageOptions={[100, 500, 1000]}
        loading={receivedData}
        onFilterModelChange={(filterModel) => {
           billFilterModelChange(filterModel.visibleRows)
         }}
        
        />
        <Typography gutterBottom variant="body1">
        Total Sales: {totalSales} Rs
        </Typography>
        <Typography gutterBottom variant="body2">
        Sub Total after filter: {totalSalesVisibleRows} Rs
        </Typography>
        
</div>
<Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
     
     {/*---- dialog chart----*/}
<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition} fullScreen>
     
        <DialogContent>
      
          <SalesChart />  

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

</div>
)

}