import React, { useEffect, useState } from 'react'
// import { createBrowserHistory} from 'history'
// import {useLocation} from 'react-router-dom'
import { Component } from 'react'
// import {useSelector} from 'react-redux'
import { Row, Col } from 'react-bootstrap'
// import profilePicture from '../../../assets/images/profile/pfp.png';
import './style.css'
// import {waiterCard} from '../../../component/partials/waiterCard'
import  MenuData  from '../../../assets/menuImages/49.jpg'
import { getDining } from '../../../services/getDiningData'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import { useSelector, useLocation, useDispatch } from 'react-redux'
import { setTableMenu,setBasket } from '../../../redux/actions'
import Para from '../../../component/partials/Para'
import Heading from '../../../component/partials/Heading'
import Btn from '../../../component/partials/Button'
import { Spinner } from '../../../component/partials/Spinner'
import {getCustomerOrder} from '../../../services/getCustomerOrder'
import { TableBill } from '../../../component/partials/TableBillCommon'
import { postBill } from '../../../services/postBill'
import {useHistory} from 'react-router-dom'

export const TakeawayOrder = () => {
      /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }
    const { table } = useSelector(state => state.currentTableReducer)
    const { menu } = useSelector(state => state.tableMenuReducer)
    const [loading, setLoading] = useState(false)
    const { dineTable } = useSelector(state => state.dineTableReducer)
    const {basket} =useSelector(state =>state.basketReducer)
const history=useHistory()
console.log('current takeaway basket',basket)
    // const [menu,setMenu]=useState()
    let _ = require('underscore')
    let totalPrice = 0;
    const dispatch = useDispatch()
    // function apiCall() {
    //     getCustomerOrder(`/restaurant/customer-orders/${table}?hour=16`).then(
    //         (result) => {
    //             let response = result
    //             const uniqueObjects = [...new Map(response.orderMenuItem.map(item => [item.menuItemId, item])).values()]
    //             dispatch(setBasket(uniqueObjects))

    //             dispatch(setTableMenu(response))
    //             console.log('its wrokihnaudauhdada')


    //         }
    //     )
    // }
    const postNprint=(id)=>{
        const payload={
            
            "status": "progress",
      "total_amount": totalPrice,
      "payment_method": "cash",
      "paid": 0,
      "return_amount": 0
        }
        postBill(`/restaurant/bill/${menu.billId}`,payload).then(
            (result)=>{
              console.log('result',result)
      
            }
          )
        console.log('check the payload ',payload)
        console.log('bil id waiter',menu.billId)
        window.print()
    }
    useEffect(() => {

        setTimeout(() => {
            setLoading(true);
        }, 1800);

        // apiCall()

        // const interval = setInterval(() => {
        //     apiCall()
        // }, 30000);

        // return () => clearInterval(interval)
    }, [])
    console.log('here the values of menu and loading',menu,loading)

    console.log('+++++++++--------', menu, 'this is table ', table)
    let TableNo;
if(menu && loading){
    // for(var i=0;i<dineTable;i++){
        // menu.orderMenuItem.map((item)=>{return totalPrice+=item.price})
       
    // }

  

// let tableNo=dineTable.map((data)=>data.table.filter(data=> data.tableid.includes(81)))
// console.log('table Number',tableNo)
// console.log('table Number',dineTable[0].table[0].tableid)
console.log('floor table id',menu.floorTableId)
// console.log('floor table new',check)


// console.log('table Number new',tableNo)

// var b=a.orderMenuItem.filter((data) => data.orderItemStatus.includes("pending"))

}

function editMenu(){
    
    history.push('/takeaway/menuOrder')
}
let itemIdLength=[]
    return (
<div>
  <div className='btnOnPrint'>
       {loading && menu? <Grid container spacing={0} alignItems="stretch" className='btnOnPrint'>        
        <Grid item xs={8} >
        <div class="cards">
       <div style={{marginLeft:'50%'}}><IconButton className='delivery-newOrder-iconBtn' onClick={editMenu}><AddIcon /></IconButton></div> 

        <div >
            <Row>
            {
                basket.map((item,index)=>{
                    
let itemId;
let reet =item.id?item.id:item.menuItemId
console.log('reet id',reet)
itemId=menu.orderMenuItem.filter((item)=>item.menuItemId === reet)
console.log('item id reet',itemId)

     itemIdLength.push(itemId.length)
     totalPrice+=item.price*itemIdLength[index]
                    return(
                    <Col>
                       <Card >
      <CardActionArea>
        <CardMedia
          image={MenuData}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
          {item.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
        {item.price}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
        {item.price*itemIdLength[index]}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
              Quantity
        {itemIdLength[index]}
          </Typography>
         
        </CardContent>
      </CardActionArea>     
    </Card>
    </Col>

                    
                    )
                })
            }</Row>
</div>

    </div>
</Grid>
        <Grid xs={4} className='container' style={{backgroundColor:'#F5F5F5',padding:'10px'}}>
<Heading title='New Orders' fontSize='28px'/>
<Divider />


<Row>
    <Col><Para title='Order Id'/></Col>
    <Col><Para title={menu.id} /></Col>

</Row>



<Row>
    <Col><Para title='Customer Id'/></Col>
    <Col><Para title={menu.customerId} /></Col>

</Row>
<Divider />

<Row>
    <Col><Para title='Item'/></Col>
    <Col><Para title='Price (Rs)' /></Col>
    <Col><Para title='Item Total'/></Col>
    <Col><Para title='Qt' /></Col>

</Row>
    {
    

basket.map(
    (item,index)=>{
        // totalPrice+=item.price
        return(
            <div>
            <Row>
                <Col>
                <Para color='#707070' title={item.name}  />
                </Col>
                <Col><Para color='#707070' title={item.price} /></Col>
                <Col><Para color='#707070' title={item.price*itemIdLength[index]} /></Col>

                <Col><Para color='#707070' title={itemIdLength[index]} /></Col>
                </Row>
                
            </div>
        )
    }
)

    }
    

<Divider />
<Row>
    <Col><Para title='Total Price' /></Col>
    <Col>Rs : <Para title={totalPrice} /></Col>
</Row>

<div className='btnOnPrint'>
<Btn title="Print" margin="20px 0 0 0" borderRadius='15px' height='60px' onClick={postNprint}/>
</div>
        </Grid>      


      </Grid>:<Spinner />}

</div>
       {/* Receipt View */}

<div className='hiddenView'>
{loading&& menu?<div >
    <img src="https://ab-res.web.app/static/media/header-01.81675d4a.png" className="pop-image"/>
{/*<h1 style={{textAlign:'center',fontWeight:'bold' }}>Ab Restaurant</h1> */}   
    <h1 style={{textAlign:'center',fontWeight:'bold' }}>Customer Order</h1>
<Divider />


<Row>
   <Col><h2 style={{fontWeight:'bold'}}>Order Id</h2></Col>
   <Col><h2 style={{fontWeight:'bold'}}>{menu.id}</h2></Col>

</Row>

<Row>
   <Col><h2 style={{fontWeight:'bold'}}>Floor Table Id'</h2></Col>
   <Col><h2 style={{fontWeight:'bold'}}>{menu.floorTableId}</h2></Col>

</Row>

<Row>
   <Col><h2 style={{fontWeight:'bold'}}>Customer Id</h2></Col>
   <Col><h2 style={{fontWeight:'bold'}}>{menu.customerId}</h2></Col>

</Row>
<Divider style={{borderTop:'10px solid black'}}/>
<br/><br/>
<Row>
   <Col><h2 style={{fontWeight:'bold'}}>Item</h2></Col>
   <Col><h2 style={{fontWeight:'bold'}}>Price(Rs)</h2></Col>
   <Col><h2 style={{fontWeight:'bold'}}>Item Price(Rs)</h2></Col>   
   <Col><h2 style={{fontWeight:'bold'}}>Qty</h2></Col>


</Row>
   {

basket.map(
   (item,index)=>{
    //    totalPrice+=item.price
       return(
           <div>
           <Row>
               <Col>
            <h2 style={{fontWeight:'bold'}}>   {item.name}</h2>
               </Col>
               <Col><h2 style={{fontWeight:'bold'}}> {item.price}</h2>
               </Col>
               <Col><h2 style={{fontWeight:'bold'}}> {item.price*itemIdLength[index]}</h2>
               </Col>
               <Col><h2 style={{fontWeight:'bold'}}> {itemIdLength[index]}</h2>
               </Col>
               </Row>
               
           </div>
   )
}
)

   }
   

<Divider style={{borderTop:'5px solid black'}}/>
<Row>
   <Col><h2 style={{textAlign:'center',fontWeight:'bold'}}>TotalPrice</h2></Col>
   <Col><h2 style={{textAlign:'center',fontWeight:'bold'}}>{totalPrice}</h2></Col>
</Row>

<div className='btnOnPrint'>
<Btn title="Print" margin="20px 0 0 0" borderRadius='15px' height='60px' onClick={()=>{postNprint(menu.billId)}}/>
</div></div>
:''}</div >
       




</div>





    )
        }






















