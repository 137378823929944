import React from 'react'
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//import Grid from '@material-ui/core/Grid';
//import AccountTreeIcon from '@material-ui/icons/AccountTree'
import Fourseats from './Fourseat'
//import {Delete} from '@material-ui/icons';
//import { Icon } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
const useStyles = makeStyles({
    boxRed:
    {
      minWidth: 240,
       boxShadow: "0px 2px 1px -1px red, 0px 1px 1px 0px red, 0px 1px 3px 0px red"
    },
    boxGreen:
    {
      minWidth: 240,
       boxShadow: "0px 2px 1px -1px green, 0px 1px 1px 0px green, 0px 1px 3px 0px green"
    },
    boxOrange:
    {
      minWidth: 240,
      boxShadow: "0px 2px 1px -1px orange, 0px 1px 1px 0px orange, 0px 1px 3px 0px orange" 
    }
    
    ,        
    title: {
      fontSize: 10,
      display: 'flex',
      justifyContent: 'space-between',
      marginTop:"0px",
      marginBottom:0
    },
    alignmant:{
        textfontSize: 14,
        textAlign:'left'
    },
    pos: {
      marginBottom: 12,
    },
    cardClass:{
      width:170,
     
    },
    bottomP: {
      marginTop: '80%',
      marginLeft:'59%',
      fontSize: 12,

    },
    cardContentTop:{
      paddingBottom: 0
    },
    cardContentBottom:{
      paddingTop:0
    },
    
  });



export function MyCard({id,number,status,Textcolor,seats,title,orderId}  ){
  const { user } = useSelector(state => state.userReducer)
  //console.log(status)
  var border =""
  if(status=="free"){
    border="1px solid green"
  }
  else if(status=="reserved"){
    border="1px solid orange"
  }
  else if(status=="occupied"){
    border="1px solid red"
  }
  const classes = useStyles();
     var fill="green"
     var cardClass=classes.boxGreen
     if(status=="reserved")
     {
      fill ="orange"
      cardClass=classes.boxOrange
     }
     else if(status=="occupied")
     {
      fill ="red"
      cardClass=classes.boxRed
     }
    // console.log(classes);
return(
<div>
    <Card className={classes.cardClass} style={{border:border}}>
    <CardContent className={classes.cardContentTop}>
    <Typography  variant="h6">
        {number}       
        </Typography>
        { 
        seats?
        <Typography className={classes.title} color="textSecondary" variant="body2">
          {title}: {seats}
          </Typography>
        : '' } 
        { 
        status?
        <Typography className={classes.title} color="textSecondary" variant="body2">
          {status}
          </Typography>
        : '' }      
        { 
        id?
        <Typography className={classes.title} color="textSecondary" variant="body2">
          Customer #: {id}
          </Typography>
        : '' }
        {
         orderId?
         <Typography className={classes.title} color="textSecondary" variant="body2">
          Order #: {orderId}
          </Typography>
         :''}
    </CardContent>
    <CardContent className={classes.cardContentBottom}>
        <Fourseats fill={fill}  />
    </CardContent>
 {/** <CardActions disableSpacing>
    <IconButton aria-label="add to favorites">
      <FavoriteIcon />
    </IconButton>
    <IconButton aria-label="share">
      <ShareIcon />
    </IconButton>
  </CardActions>*/} 
  </Card>
  </div>
)
}
MyCard.defaultProps={
  title:"Seating"
}
