import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
//import { Row, Col } from 'react-bootstrap'
//import './reception.css'
import { makeStyles } from '@material-ui/core/styles';
import {Divider} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import  {DataGrid}  from '@material-ui/data-grid';
//import {Delete} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
//import { MdAdd } from 'react-icons/md'
import { Avatar, IconButton } from '@material-ui/core'
import Btn from '../../../component/partials/Button';
import {setCustomer} from '../../../redux/actions';
import {getCustomers} from '../../../services/getCustomerList';
import PureBreadcrumbs from '../../../component/Breadcrumb';
import { Spinner } from '../../../component/partials/Spinner';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getUsers } from '../../../services/getUsers';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import InputAdornment from '@material-ui/core/InputAdornment';
import {putData} from '../../../services/getData';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {deleteRecord} from '../../../services/deleteRecord'


const useStyles = makeStyles((theme) => ({
  root: {
    width:'100%',
    '& .tr-table-order-new': {
      backgroundColor: 'rgba(255, 179, 0, 0.98)',
    },
    '& .tr-table-order-completed': {
      backgroundColor: '#fe0101',
    },
    '& .tr-table-order-in-progress': {
      backgroundColor: '#86d689',
    },
  },
  box: {
    
    minWidth:'500px',
    '@media print':{
      minWidth:'600px'
    }
    
  },
  screen:{
    padding:'0px',
    textAlign:'center',
    '@media print':{
      textAlign:'left'
    }
  },
  diaContent:  {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    '& > * + *': {
        marginTop: theme.spacing(2),
  }
},
backdrop: {
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const UserDetail=()=>{
    /*---------disable the console logs when production environment--------*/
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
const {customer} =useSelector(state=>state.customerReducer)
const { user } = useSelector(state => state.userReducer)
const [open, setOpen] = React.useState(false);
const [existingQty, setExistingQty] = React.useState(0);
const [allWaiters, setAllWaiters] = React.useState([])
const [allSuppliers, setAllSuppliers] = React.useState([])
const [values, setValues] = React.useState({
  username: '',
  password: '',
  name: '',
  email: '',
  address: '',
  role: '',
  phone: '',
});
const [backdropOpen, setBackdropOpen] = React.useState(false);
const dispatch=useDispatch()
  const classes=useStyles();

/*function apiCall(){
  getCustomers('/restaurant/product').then(
    (result)=>{
     let response=result        
     dispatch(setCustomer(response))
    }
  )
}
useEffect(()=>{
  apiCall()
  const interval = setInterval(() => {
    apiCall()
  },10000)
  return ()=>clearInterval(interval)
},[])*/

 
 const handleChange = (prop) => (event) => {
  setValues({ ...values, [prop]: event.target.value });
 /* if (prop === 'quantity'&& event.target.value < existingQty ) {
      console.debug(prop+" : <--Yes it is")
  }*/
  console.debug(prop+" : "+event.target.value)
};
const [dialogType, setDialogType] = React.useState("add");
 
const handleClickOpen = (rowdata,clickType) => {
  setDialogType(clickType)
  setValues(rowdata)
  setExistingQty(rowdata.quantity)
  setOpen(true);
  setBackdropOpen(true);
  console.debug("quantity:"+rowdata.quantity)
  
};
/*const deleteUserClick=(data)=>{
  //setEditCateg(get_icon_Key(menuData,data))
  setEditName(data.name)
  setEditPrice(data.price)
  setEditMenuId(data.id)
  setDeleteDialogOpen(true)
  setBackdropOpen(true);
 }*/

const handleClose = () => {
  setOpen(false);
  setBackdropOpen(false);
};

useEffect(()=>{
  getUsersDetails();
},[])


const postnDeleteMenu= () =>{
console.debug("update user with the following date: ",values)
if(dialogType==="edit")
{
  let url=`/restaurant/update-user/${values.id}`
  putData(url,values).then(
    (result)=>{      
      console.log(`here deleted one menu item id is ${values}`)
      getUsersDetails();
      setBackdropOpen(false);
    }
  );
}
else{
  let url=`/restaurant/delete-user/${values.id}`
  deleteRecord(url).then(
    (result)=>{  
      getUsersDetails();
      setBackdropOpen(false);   
    }
  ).catch(error=>{
    console.debug("error: ",error);
 });
}    
  setOpen(false);
  //setBackdropOpen(false);
}


const getUsersDetails= () =>{
  getUsers('/restaurant/users-details').then(
    (result) => {
        let resp = result
        setAllWaiters(resp)                
    }).catch(error=>{
      console.debug("error: ",error);
   });
}


const [editRowsModel, setEditRowsModel] = React.useState({});

const handleEditRowsModelChange = React.useCallback((model) => {
  setEditRowsModel(model);
    console.debug("actionType???");
  }, []);
  const handleCellEditCommit = React.useCallback(
    () => {
      console.debug("2-actionType???");
    },
    [],
  );
  console.debug("editRowsModel: "+editRowsModel);  

let columns;
const [sortModel, setSortModel] = React.useState([
  {
    field: 'id',
    sort: 'asc',
  },
]);
{
columns=[
  
  { field: 'id', headerName: 'ID', width: 150 },
  { field: 'name', headerName: 'Name', width: 150 },  
  { field: 'role', headerName: 'Role', width: 150 },
  { field: 'username', headerName: 'Username', width: 150, editable: true },
  { field: 'email', headerName: 'Email', width: 150 },
  { field: 'phone', headerName: 'Phone #', width: 150, 
  valueFormatter: (params) => {
    return `0${params.value}`;
  }
},
  { field: 'address', headerName: 'Address', width: 150 },
{field:'invEdit',headerName:'Edit',width:150,
  renderCell: 
  (params) => {
    return (
<IconButton  aria-label="increase" >
<EditIcon color="secondary" onClick={() => { handleClickOpen(params.row,"edit") }} />
</IconButton>
)},
disableClickEventBubbling: true,},
{field:'deleteUser',headerName:'Delete',width:150,
  renderCell: 
  (params) => {
    return (
<IconButton  aria-label="increase" >

<DeleteIcon color="secondary" onClick={() => { handleClickOpen(params.row,"delete") }} />
</IconButton>
)},
disableClickEventBubbling: true,},
]
}
        return(
  <div className='container' style={{ maxWidth: '100%' }}>

<PureBreadcrumbs className='onPrintDine'/>

{/*---- Main table----*/}
{allWaiters?
<div style={{  width: '100%' }} className={classes.root,'onPrintDine'} id='users-details-list-table'>
<DataGrid  rows={allWaiters} columns={columns} pageSize={12} autoHeight='true' 
        editRowsModel={editRowsModel}
        onEditRowsModelChange={handleEditRowsModelChange}
        onCellEditCommit={handleCellEditCommit} 
        sortModel={sortModel}
        />
</div>
:<Spinner />}

{/*---- dialog for add----*/}
<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
        <DialogTitle id="users-details-form-dialog-title">
        {
          dialogType==="edit"?"Edit User":''
          }
          {
          dialogType==="add"?"Add User":''
          }
          {
          dialogType==="delete"?"Delete User":''
          }
          </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {values.name}
          </DialogContentText>
              
          {
          dialogType==="edit"? 
          <div className={classes.diaContent}>
            <TextField
          required
          id="outlined-required"
          label="Name"
          variant="outlined"
          fullWidth
          value={values.name}
          onChange={handleChange('name')}
          rows={2} 
        /> 
          
        <TextField
          required
          id="outlined-required"
          label="Address"
          variant="outlined"
          fullWidth
          value={values.address}
          onChange={handleChange('address')}
          rows={2} 
        /> 
        
          <TextField
          id="outlined-number"
          label="Phone"
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">0</InputAdornment>
          }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          value={values.phone}
          onChange={handleChange('phone')}
          rows={2} 
        />
        
        <TextField
          required
          id="outlined-required"
          label="Email"
          variant="outlined"
          fullWidth
          value={values.email}
          onChange={handleChange('email')}
          rows={2} 
        /> 

        <FormControl variant="outlined" rows={2}  >
        <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.role}
          onChange={handleChange('role')}
          label="Role"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="waiter">Waiter</MenuItem>
          <MenuItem value="cashier">Cashier</MenuItem>
          <MenuItem value="cheif">Kitchen</MenuItem>
          <MenuItem value="storekeeper">Storekeeper</MenuItem>
          <MenuItem value="reception">Reception</MenuItem>
          <MenuItem value="takeaway">Takeaway User</MenuItem>
          <MenuItem value="delivery">Delivery boy</MenuItem>
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="accountant">Accountant</MenuItem>
        </Select>
      </FormControl>
        
        <TextField
          required
          id="outlined-required"
          label="User name"
          variant="outlined"
          fullWidth
          value={values.username}
          onChange={handleChange('username')}
          rows={2} 
        /> 
            
        <TextField
          id="outlined-password-input"
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          value={values.password}
          onChange={handleChange('password')}
          rows={2} 
        /></div>:''}
        {
          dialogType==="delete"? 
          <div className={classes.diaContent}>
        <h6>Are you sure you want ro remove the following User?</h6>
        <label>Name: {values.name}</label> <br/>
        <label>Username: {values.username}</label><br/>
        <label>Role: {values.role}</label>
          </div>:''
        }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {
          dialogType==="delete"? 
          <Button onClick={()=>{postnDeleteMenu()}}  color="secondary">
            Delete
          </Button>:
          <Button onClick={()=>{postnDeleteMenu()}}  color="primary">
            Submit
          </Button>}
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
</div>
)

}