export const setUser = (payload) =>{
    return {
        type: "SET_USER",
        payload
    }
}

export const setDineTable=(payload)=>{
    return{
        type:"SET_DINETABLE",
        payload
    }
}

export const setInventry=(payload)=>{
    return{
        type:"SET_INVENTRY",
        payload
    }
}

export const setReservedTable=(payload)=>{
    return{
        type:"SET_RESERVED",
        payload
    }
}
export const setTakeawayOrder=(payload)=>{
    return{
        type:'SET_TAKEAWAY_ORDER',
        payload
    }
}
export const setTableOrder=(payload)=>{
    return{
        type:"SET_ORDER",
        payload
    }
}
export const currentTable=(payload)=>{
return{
    type:"SET_TABLE",
    payload
}
}
export const setTableStatus=(payload)=>{
    return{
        type:"SET_TABLE_STATUS",
        payload
    }
}
export const setTableMenu=(payload)=>{
    return{
        type:'SET_TABLE_MENU_DATA',
        payload
    }
}

export const setUsers=(payload)=>{
    return{
        type:'SET_USERS',
        payload
    }
}


export const setOldItem=(payload)=>{
    return{
        type:'SET_TABLE_BASKET',
        payload
    }
}

export const setWaitersInfo=(payload)=>{
    return{
        type:'SET_CURRENT_WAITER',
        payload
    }
}
export const setIsPrint=(payload)=>{
    return{
        type:'SET_IS_PRINT',
        payload
    }
}
export const setTotalTableInfo=(payload)=>{
    return{
        type:'SET_TOTAL_TABLE_INFO',
        payload
    }
}
export const setPostOrder=(payload)=>{
    return{
        type:'SET_POST_ORDER',
        payload}
      
}
export const setMenuId=(payload)=>{
    return{
        type:'SET_MENU_ID',
        payload
    }
}


export const setMenuData=(payload)=>{
    return{
        type:"SET_MENU_DATA",
        payload
    }
}

export const setKitchenOrder=(payload)=>{
    return{
        type:'SET_KITCHEN_ORDER',
        payload
    }
}
export const addMenuItem=(payload)=>{
    return{
        type:'ADD_MENU_DATA',
        payload
    }
}

export const subtractMenuItem=(payload)=>{
    return{
        type:'SUBTRACT_MENU_DATA',
        payload
    }
}

export const setBasket=(payload)=>{
    return{
        type:'SET_BASKET',
        payload
    }
}

export const removeMenuItem=(payload)=>{
    return{
        type:'DELETE_MENU_DATA',
        payload
    }
}

export const categoryItem=(payload)=>{
    return{
        type:'SET_CATEGORY_ITEM',
        payload
    }
}
export const setCategoryName=(payload)=>{
    return{
        type:'SET_CATEGORY_NAME',
        payload
    }
}

export const setOrderId=(payload)=>{
    return{
        type:'SET_ORDER_ID',
        payload
    }
}

export const setCardItem=(payload)=>{
    return{
        type:'SET_CARD_ITEM',
        payload
    }
}

export const setBills=(payload)=>{
    return{
        type:'SET_BILLS',
        payload
    }
}
export const setBillInfo=(payload)=>{
    return{
        type:'SET_BILL_INFO',
        payload
    }
}
export const setWholeBasket=(payload)=>{
    return{
        type:'SET_WHOLE_BASKET',
        payload
    }
}
export const incWholeBasket=(payload)=>{
    return{
        type:'INCREASE_WHOLE_BASKET',
        payload
    }
}

export const decWholeBasket=(payload)=>{
    return {
        type:'DECREASE_WHOLE_BASKET',
        payload
    }
}
export const removeWholeBasket=(payload)=>{
    return{
        type:'REMOVE_WHOLE_BASKET',
        payload
    }
}
export const setDuplicatedWholeBasket=(payload)=>{
    return {
        type:'SET_DUPLICATE_WHOLE_BASKET',
        payload
    }
}
export const setItemLength=(payload)=>{
    return{
        type:'SET_ITEM_LENGTH',
        payload
    }
}

export const incItemLength=(payload)=>{
    return{
        type:'INC_ITEM_LENGTH',
        payload
    }
}
export const popCart=(payload)=>{
    return{
        type:'POP_CART',
        payload
    }
}
export const addCart=(payload)=>{
    return{
        type:'ADD_CART',
        payload
    }
}
export const emptyCart=()=>{
    return{
        type:'EMPTY_CART'
        
    }
}

export const removeCart=(payload)=>{
    return{
        type:'REMOVE_CART',
        payload
    }
}
export const setCustomer=(payload)=>{
    return{
        type:'SET_CUSTOMER',
        payload
    }
}
export const setSingleCustomer=(payload)=>{
    return{
        type:'SET_SINGLE_CUSTOMER',
        payload
    }
}

export const setTableItem=(payload)=>{
    return{
        type:'SET_TABLE_ITEM',
        payload
    }
}