import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';
import {useSelector,useDispatch} from 'react-redux';
import { postData } from '../../../services/getData';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '500px',
        '& > * + *': {
            marginTop: theme.spacing(2),
          },
    },
    theCard: {
        width: '1000px'
      },
  }));

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export const AddUser=()=>{
    /*---------disable the console logs when production environment--------*/
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('success');
    const [alertMessage, setAlertMessage] = React.useState('loading....');
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    name: '',
    email: '',
    address: '',
    role: '',
    phone: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    console.debug(event.target.value)
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const addUer = () => {
    let rend = {
        "username": values.username,
        "password": values.password,
        "name": values.name,
        "email":values.email,
        "address": values.address,
        "role": values.role
        ,"phone": values.phone
    }
    if (values.username, values.password) {
        postData('/restaurant/addUser', rend).then(
            (result) => {
                if(result.id)
                {
                    setSeverity('success');
                    setAlertMessage('User is added, system created id is: '+result.id)
                }
                else{
                    setSeverity('error');
                    setAlertMessage(result.message)
                }
                console.log('add user status')
                setOpen(true);
            }            
        )
       
        
        console.debug("form: "+rend)
    }    
   // handleClose()
}
const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

    return(
    <div >
        <Card className={classes.theCard} >
        <CardContent>  
        <Typography gutterBottom variant="h5" component="h2">
            Add User
          </Typography>
            <div className={classes.root}>    
        <TextField
          required
          id="outlined-required"
          label="Name"
          variant="outlined"
          fullWidth
          value={values.name}
          onChange={handleChange('name')}
        /> 
          
        <TextField
          required
          id="outlined-required"
          label="Address"
          variant="outlined"
          fullWidth
          value={values.address}
          onChange={handleChange('address')}
        /> 
        
          <TextField
          id="outlined-number"
          label="Phone"
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">0</InputAdornment>
          }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          value={values.phone}
          onChange={handleChange('phone')}
        />
        
        <TextField
          required
          id="outlined-required"
          label="Email"
          variant="outlined"
          fullWidth
          value={values.email}
          onChange={handleChange('email')}
        /> 
        
         {/*<TextField
          required
          id="outlined-required"
          label="Role"
          variant="outlined"
          fullWidth
          value={values.role}
          onChange={handleChange('role')}
        /> */}

        <FormControl variant="outlined" >
        <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.role}
          onChange={handleChange('role')}
          label="Role"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="waiter">Waiter</MenuItem>
          <MenuItem value="cashier">Cashier</MenuItem>
          <MenuItem value="cheif">Kitchen</MenuItem>
          <MenuItem value="storekeeper">Storekeeper</MenuItem>
          <MenuItem value="reception">Reception</MenuItem>
          <MenuItem value="takeaway">Takeaway User</MenuItem>
          <MenuItem value="delivery">Delivery boy</MenuItem>
          <MenuItem value="adminwaiter">Admin Waiter</MenuItem>
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="accountant">Accountant</MenuItem>
        </Select>
      </FormControl>
        
        <TextField
          required
          id="outlined-required"
          label="User name"
          variant="outlined"
          fullWidth
          value={values.username}
          onChange={handleChange('username')}
        /> 
            
        <TextField
          id="outlined-password-input"
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          value={values.password}
          onChange={handleChange('password')}
        /></div> 
        </CardContent>
        <CardActions>
          <Button edge="end" variant="contained" onClick={()=>addUer()} color="primary" startIcon={<SaveIcon />}>Add User</Button>
        </CardActions>
      </Card>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity={severity} onClose={handleClose}>
        {alertMessage}
      </Alert>
    </Snackbar>
      </div>
      
    )
}