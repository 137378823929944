import React from 'react'
import {NewOrders} from '../../pages/Layout/kitchen/NewOrders'
import {Home} from '../../pages/Home'
import {KitchenTableOrder} from '../../pages/Layout/kitchen/TableOrder'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import {Menu,DinningArea,Bills,Logout,NewOrder} from '../../component/partials/CustomDasboarIcon'
// import {Icon} from '../../component/partials/Icon'
// import profilePicture from '../../assets/icons/table.svg';
export const KitchenMenu=[
    {
        id:1,
        menuName: 'New Orders',
        menuDescription: 'General setups menus',
        path: '/kitchen',
        component: NewOrders,
        exact: true,
        icon :<NewOrder />,
        submenu:[]
    },
    {
        id:2,
        menuName: 'Menu',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: KitchenTableOrder,
        exact: true,
        icon :<Menu />,
        submenu:[]
    }
    ,
    {
        id:3,
        menuName: 'Logout',
        menuDescription: 'General  menus',
        path: '/',
        component: KitchenTableOrder,
        exact: true,
        icon :<Logout />,
        submenu:[]
    }
]
