export const billReducer = (state= {bill:null},action) => {
    switch(action.type) {
        case "SET_BILLS":
            return {...state,bill:action.payload}


        default:
            return {...state}
    }
}

export const billInfoReducer = (state= {billsInfo:null},action) => {
    switch(action.type) {
        case "SET_BILL_INFO":
            return {...state,billsInfo:action.payload}


        default:
            return {...state}
    }
}