import React,{useState,useEffect} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getUsers } from '../../../services/getUsers';



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' ,
    },
    title: {
      display: true,
      text: 'Sales chart',
    },
  },
};


export function SalesChart() {    
    const [salesData, setSalesData] = React.useState([]);
    const [salesLables, setSalesLables] = React.useState([]);
    useEffect(()=>{
      getSales(8);
    },[]);
    const getSales = (theHours) => {
      getUsers('/restaurant/sales-sum').then(
        (result) => {
            let resp = result
            const fooData=new Array();
            const fooLabels=new Array();
            resp.forEach(dt=>{
              fooData.push(dt.salesSum);
              fooLabels.push(dt.dateKhiFormat);
              
            }) ;
            setSalesData(fooData);  
            setSalesLables(fooLabels)      
        }).catch(error=>{
          console.debug("error: ",error);
       });
    };

    const data = {
      labels: salesLables,
      datasets: [{
        label: 'ABR Sales',
        data: salesData,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };
  return <Line options={options} data={data} style={{padding:'0 0 5% 0'}}/>;
}