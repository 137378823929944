import React, { useEffect,useState } from 'react'
import { Component } from 'react';
import './style.css'
import Para from '../../../component/partials/Para'
import Heading from '../../../component/partials/Heading'
import Btn from '../../../component/partials/Button'
import IconButton from '@material-ui/core/IconButton';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import { grey } from '@material-ui/core/colors';
import {useHistory} from 'react-router-dom'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {getBills} from '../../../services/getBills'
import {useDispatch,useSelector} from 'react-redux'
import {setBills,currentTable,setBillInfo} from '../../../redux/actions'
import {Spinner} from '../../../component/partials/Spinner'
import  {DataGrid}  from '@material-ui/data-grid';
import {Link} from 'react-router-dom'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


const StyledTableCell = withStyles((theme) => ({
  head: {
    //  backgroundColor: theme.palette.common.black,
     color: theme.palette.common.white,
    backgroundColor: 'red !important'
    
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
   
  },
  TableHead:{
    padding:"10px 0px"
  }
});


export const TakeawayBillsTable=()=>{
    /*---------disable the console logs when production environment--------*/
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }

  const dispatch=useDispatch()
  const {bill} = useSelector(state=>state.billReducer)  
const history=useHistory()
// const [billInfo,setBillInfo]=useState(null)
function apiCall(){
  getBills('/restaurant/bills?hour=16').then(
    (result)=>{
      console.log('hitting Bills Api',result)
      let onlyDelNTake=result.filter(item=>item.orderItemBill.diningType=="takeaway" || item.orderItemBill.diningType=="delivery")
 dispatch(setBills(onlyDelNTake))
  // dispatch(setBills(result))
    }
  )
}
useEffect(()=>{
  apiCall()

  const interval = setInterval(() => {
    apiCall()
}, 12000);

return () => clearInterval(interval)  
 

},[])
let billInfo=[]
let isInfo=false

const[isToggleOn,setIsToggleOn]=useState(false)
let filterTable=[];
    const  handleSwitchClick=()=>{
      setIsToggleOn(!isToggleOn)
  }
  let targetBill=[];
 
if(bill){
 
console.log('current bill',bill)
bill.forEach((item,index)=>{
let time =item.orderItemBill.billTime.slice(0,10)
time+=' '+item.orderItemBill.billTime.slice(11,19)
  let obj={
    "id":index,
    "billId":item.orderItemBill.billId,
    "billStatus":item.orderItemBill.billStatus,
    "billTime":time,
    "waiter":item.orderItemBill.waiter,
    "dinningTable":item.orderItemBill.dinningTable,
    "payment_method":item.orderItemBill.payment_method,
    "diningType":item.orderItemBill.diningType

  }
  targetBill.push(item)
  billInfo.push(obj)
})
}
let billLength=billInfo.length
if(billLength>0){
  isInfo=true
}
console.log('bill Info data',billInfo)

const tableDetail=(id)=>{
console.log('here its id',id)
console.log('here its bills',billInfo)

dispatch(currentTable(id))
dispatch(setBillInfo(targetBill))
history.push('/cashier/tableOrder')
}

console.log('value of Bill reducer are',bill) 
                          // const [myData,setMyData]=useState(billsData)
        const classes = useStyles();
        if(isInfo){
          if(!isToggleOn){
          billInfo=billInfo.filter((data)=>data.billStatus.includes('new') || data.billStatus.includes('progress'))
          targetBill=targetBill.filter((data)=>data.orderItemBill.billStatus.includes('new') || data.orderItemBill.billStatus.includes('progress'))
          }
          else{
              billInfo=billInfo
              targetBill=targetBill
          }
      }

        const columns = [
// {field:'id',headerName:'SNo',width:150},
          { field: 'billId', headerName: 'ID', width: 150 },
          { field: 'billStatus', headerName: 'Status', width: 150 },
         
      {field: 'billTime', headerName: 'Time', width: 200,},
       {field: 'payment_method',headerName: 'Payment',width: 150, },
       {field: 'diningType',headerName: 'Order Type',width: 150, },

       {field:'detail',headerName:'View Detail',width:150,
       renderCell: 
       (params) => (

  
     <Btn title='View Order' height='30px' width="90px" fontSize="9px" bgColor='' onClick={()=>{tableDetail(params.rowIndex)}}/>
),
  disableClickEventBubbling: true,}
          ,
          
      
        ]
      console.log('bill info',billInfo)
        return(
         



<div>
<ReceiptIcon  color="secondary" style={{ fontSize: '40' }}/> Bills Detail

      {isInfo?<div><FormGroup className='MatToggle'>
           <FormControlLabel
        control={<Switch 
        color='primary'
        label="Completed"
        onChange={handleSwitchClick}/>}
        label="Completed"
      />
      </FormGroup></div>:''}
      {bill?<div style={{ width: '100%'}} id='takeaway-bills-table'>{isInfo?<DataGrid autoHeight='true'  rows={billInfo} columns={columns} pageSize={15}/>:''}</div>:<Spinner />}

    {/* </TableContainer> */}
</div>







        )
}