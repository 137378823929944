import React from 'react'
import {MenuCard} from '../../pages/Layout/menu/menuCard'
import {Ribs,Prawn,Chicken,Salan ,Biryani,Vegetable ,Salad,Roti ,Bbq, 
    Drinks ,Icecream ,Soup,Fastfood,Chinees,Sandwich
,Burger,Roll,Pakistanidishes,Fish,Fries,Coffee} from "../../component/partials/CustomIcon"
 /*export const MenuCardItems=[
    {id:1,
        menuName: 'Mutton',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Ribs /></h3>,
        submenu:[]

    },
    {id:2,
        menuName: 'Prawn',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Prawn /></h3>,
        submenu:[]

    },
    {id:3,
        menuName: 'Chicken',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Chicken /></h3>,
        submenu:[]

    },
    {id:4,
        menuName: 'Salaan',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Salan /></h3>,
        submenu:[]

    },
    {id:5,
        menuName: 'Biryani',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Biryani /></h3>,
        submenu:[]

    },
    {id:6,
        menuName: 'Vegetable',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Vegetable /></h3>,
        submenu:[]

    },
    {id:8,
        menuName: 'Bread',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Roti /></h3>,
        submenu:[]

    },
    {id:9,
        menuName: 'BBQ',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Bbq /></h3>,
        submenu:[]

    },
    {id:10,
        menuName: 'Cold Drink',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Drinks /></h3>,
        submenu:[]

    },
    {id:11,
        menuName: 'Hot Drink',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Coffee/></h3>,
        submenu:[]

    },
    {id:12,
        menuName: 'Sweet',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Icecream /></h3>,
        submenu:[]

    },
    {id:13,
        menuName: 'Soup',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Soup /></h3>,
        submenu:[]

    },
   
    {id:14,
        menuName: 'Fast Food',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Fastfood /></h3>,
        submenu:[]

    },

 
    {id:15,
        menuName: 'CHINESE',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Chinees /></h3>,
        submenu:[]

    },

    
    {id:16,
        menuName: 'SANDWICH',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Sandwich /></h3>,
        submenu:[]

    },
    {id:17,
        menuName: 'BURGER',
        menuDescription: 'BURGER',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Burger /></h3>,
        submenu:[]

    },

    {id:18,
        menuName: 'ROLL',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Roll /></h3>,
        submenu:[]

    },
    {id:19,
        menuName: 'PAKISTANI DASHES',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Pakistanidishes /></h3>,
        submenu:[]

    },

    {id:20,
        menuName: 'FISH & PRAWN',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Fish /></h3>,
        submenu:[]
    }

    ,
    {id:21,
        menuName: 'FRENCH FRIES',
        menuDescription: 'FRENCH FRIES',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Fries/></h3>,
        submenu:[]
    },
  
    {id:22,
        menuName: 'FRESH SALAD',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Salad /></h3>
,submenu:[]
    }, {id:23,
        menuName: 'Other',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Pakistanidishes /></h3>,
        submenu:[]

    }
    , {id:24,
        menuName: 'Continental',
        menuDescription: 'General setups menus',
        path: '/waiter/menuItems',
        component: MenuCard,
        exact: true,
        icon:<h3><Pakistanidishes /></h3>,
        submenu:[]

    }
    
]

export const cashierMenuCardItems=[
   
    {id:1,
        menuName: 'Mutton',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Ribs /></h3>,
        submenu:[]

    },
    {id:2,
        menuName: 'Prawn',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Prawn /></h3>,
        submenu:[]

    },
    {id:3,
        menuName: 'Chicken',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Chicken /></h3>,
        submenu:[]

    },
    {id:4,
        menuName: 'Salaan',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Salan/></h3>,
        submenu:[]

    },
    {id:5,
        menuName: 'Biryani',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Biryani /></h3>,
        submenu:[]

    },
    {id:6,
        menuName: 'Vegetable',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Vegetable /></h3>,
        submenu:[]

    },
    {id:8,
        menuName: 'Bread',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Roti /></h3>,
        submenu:[]

    },
    {id:9,
        menuName: 'BBQ',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Bbq /></h3>,
        submenu:[]

    },
    {id:10,
        menuName: 'Cold Drink',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Drinks/></h3>,
        submenu:[]

    },
    {id:11,
        menuName: 'Hot Drink',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Coffee/></h3>,
        submenu:[]

    },
    {id:12,
        menuName: 'Sweet',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Icecream/></h3>,
        submenu:[]

    },
    {id:13,
        menuName: 'Soup',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Soup /></h3>,
        submenu:[]

    },
   
    {id:14,
        menuName: 'Fast Food',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Fastfood /></h3>,
        submenu:[]

    },

 
    {id:15,
        menuName: 'CHINESE',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Chinees /></h3>,
        submenu:[]

    },

    
    {id:16,
        menuName: 'SANDWICH',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Sandwich /></h3>,
        submenu:[]

    },
    {id:17,
        menuName: 'BURGER',
        menuDescription: 'BURGER',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Burger/></h3>,
        submenu:[]

    },

    {id:18,
        menuName: 'ROLL',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Roll /></h3>,
        submenu:[]

    },
    {id:19,
        menuName: 'PAKISTANI DASHES',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Pakistanidishes /></h3>,
        submenu:[]

    },

    {id:20,
        menuName: 'FISH & PRAWN',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Fish/></h3>,
        submenu:[]
    }

    ,
    {id:21,
        menuName: 'FRENCH FRIES',
        menuDescription: 'FRENCH FRIES',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Fries /></h3>,
        submenu:[]
    },
  
    {id:22,
        menuName: 'FRESH SALAD',
        menuDescription: 'General setups menus',
        path: '/cashier/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Salad /></h3>
,submenu:[]
    },
     {id:23,
         menuName: 'Other',
         menuDescription: 'General setups menus',
        path: '/cashier/menu',
         component: MenuCard,
         exact: true,
         icon:<h3><Salad /></h3>,
         submenu:[]

     }
     , {id:24,
         menuName: 'Continental',
         menuDescription: 'General setups menus',
         path: '/cashier/menu',
         component: MenuCard,
         exact: true,
         icon:<h3><Pakistanidishes /></h3>,
         submenu:[]
 
     }
    
]

export const KitchenMenuItems=[
   
    
    {id:1,
        menuName: 'Mutton',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Ribs /></h3>,
        submenu:[]

    },
    {id:2,
        menuName: 'Prawn',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Prawn /></h3>,
        submenu:[]

    },
    {id:3,
        menuName: 'Chicken',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Chicken /></h3>,
        submenu:[]

    },
    {id:4,
        menuName: 'Salaan',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Salan /></h3>,
        submenu:[]

    },
    {id:5,
        menuName: 'Biryani',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Biryani /></h3>,
        submenu:[]

    },
    {id:6,
        menuName: 'Vegetable',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Vegetable /></h3>,
        submenu:[]

    },
    {id:8,
        menuName: 'Bread',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Roti /></h3>,
        submenu:[]

    },
    {id:9,
        menuName: 'BBQ',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Bbq /></h3>,
        submenu:[]

    },
    {id:10,
        menuName: 'Cold Drink',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Drinks /></h3>,
        submenu:[]

    },
    {id:11,
        menuName: 'Hot Drink',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Coffee /></h3>,
        submenu:[]

    },
    {id:12,
        menuName: 'Sweet',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Icecream /></h3>,
        submenu:[]

    },
    {id:13,
        menuName: 'Soup',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Soup /></h3>,
        submenu:[]

    },
   
    {id:14,
        menuName: 'Fast Food',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Fastfood /></h3>,
        submenu:[]

    },

 
    {id:15,
        menuName: 'CHINESE',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Chinees /></h3>,
        submenu:[]

    },

    
    {id:16,
        menuName: 'SANDWICH',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Sandwich/></h3>,
        submenu:[]

    },
    {id:17,
        menuName: 'BURGER',
        menuDescription: 'BURGER',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Burger /></h3>,
        submenu:[]

    },

    {id:18,
        menuName: 'ROLL',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Roll /></h3>,
        submenu:[]

    },
    {id:19,
        menuName: 'PAKISTANI DASHES',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Pakistanidishes /></h3>,
        submenu:[]

    },

    {id:20,
        menuName: 'FISH & PRAWN',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Fish/></h3>,
        submenu:[]
    }

    ,
    {id:21,
        menuName: 'FRENCH FRIES',
        menuDescription: 'FRENCH FRIES',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Fries /></h3>,
        submenu:[]
    },
  
    {id:22,
        menuName: 'FRESH SALAD',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Salad /></h3>
,submenu:[]
    },{id:23,
        menuName: 'Other',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Salad /></h3>,
        submenu:[]

    }
    , {id:24,
        menuName: 'Continental',
        menuDescription: 'General setups menus',
        path: '/kitchen/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Pakistanidishes /></h3>,
        submenu:[]

    }
    
]

export const CommonMenuItems=[
   
    
    {id:1,
        menuName: 'Mutton',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Ribs /></h3>,
        submenu:[]

    },
    {id:2,
        menuName: 'Prawn',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Prawn /></h3>,
        submenu:[]

    },
    {id:3,
        menuName: 'Chicken',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Chicken /></h3>,
        submenu:[]

    },
    {id:4,
        menuName: 'Salaan',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Salan /></h3>,
        submenu:[]

    },
    {id:5,
        menuName: 'Biryani',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Biryani /></h3>,
        submenu:[]

    },
    {id:6,
        menuName: 'Vegetable',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Vegetable /></h3>,
        submenu:[]

    },
    {id:8,
        menuName: 'Bread',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Roti /></h3>,
        submenu:[]

    },
    {id:9,
        menuName: 'BBQ',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Bbq /></h3>,
        submenu:[]

    },
    {id:10,
        menuName: 'Cold Drink',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Drinks /></h3>,
        submenu:[]

    },
    {id:11,
        menuName: 'Hot Drink',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Coffee /></h3>,
        submenu:[]

    },
    {id:12,
        menuName: 'Sweet',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Icecream /></h3>,
        submenu:[]

    },
    {id:13,
        menuName: 'Soup',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Soup /></h3>,
        submenu:[]

    },
   
    {id:14,
        menuName: 'Fast Food',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Fastfood /></h3>,
        submenu:[]

    },

 
    {id:15,
        menuName: 'CHINESE',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Chinees /></h3>,
        submenu:[]

    },

    
    {id:16,
        menuName: 'SANDWICH',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Sandwich/></h3>,
        submenu:[]

    },
    {id:17,
        menuName: 'BURGER',
        menuDescription: 'BURGER',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Burger /></h3>,
        submenu:[]

    },

    {id:18,
        menuName: 'ROLL',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Roll /></h3>,
        submenu:[]

    },
    {id:19,
        menuName: 'PAKISTANI DASHES',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Pakistanidishes /></h3>,
        submenu:[]

    },

    {id:20,
        menuName: 'FISH & PRAWN',
        menuDescription: 'General setups menus',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Fish/></h3>,
        submenu:[]
    }

    ,
    {id:21,
        menuName: 'FRENCH FRIES',
        menuDescription: 'FRENCH FRIES',
        path: '/takeaway/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Fries /></h3>,
        submenu:[]
    },
  
    {id:22,
        menuName: 'FRESH SALAD',
        menuDescription: 'General setups menus',
        path: '/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Salad /></h3>
,submenu:[]
    },
    {id:23,
        menuName: 'Other',
        menuDescription: 'General setups menus',
        path: '/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Salad /></h3>,
        submenu:[]

    }
    , {id:24,
        menuName: 'Continental',
        menuDescription: 'General setups menus',
        path: '/menu',
        component: MenuCard,
        exact: true,
        icon:<h3><Pakistanidishes /></h3>,
        submenu:[]

    }
    
]

export const TakeAwayMenuItems=[
   
    
    {id:1,
        menuName: 'Mutton',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Ribs /></h3>,
        submenu:[]

    },
    {id:2,
        menuName: 'Prawn',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Prawn /></h3>,
        submenu:[]

    },
    {id:3,
        menuName: 'Chicken',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Chicken /></h3>,
        submenu:[]

    },
    {id:4,
        menuName: 'Salaan',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Salan /></h3>,
        submenu:[]

    },
    {id:5,
        menuName: 'Biryani',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Biryani /></h3>,
        submenu:[]

    },
    {id:6,
        menuName: 'Vegetable',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Vegetable /></h3>,
        submenu:[]

    },
    {id:8,
        menuName: 'Bread',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Roti /></h3>,
        submenu:[]

    },
    {id:9,
        menuName: 'BBQ',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Bbq /></h3>,
        submenu:[]

    },
    {id:10,
        menuName: 'Cold Drink',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Drinks /></h3>,
        submenu:[]

    },
    {id:11,
        menuName: 'Hot Drink',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Coffee /></h3>,
        submenu:[]

    },
    {id:12,
        menuName: 'Sweet',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Icecream /></h3>,
        submenu:[]

    },
    {id:13,
        menuName: 'Soup',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Soup /></h3>,
        submenu:[]

    },
   
    {id:14,
        menuName: 'Fast Food',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Fastfood /></h3>,
        submenu:[]

    },

 
    {id:15,
        menuName: 'CHINESE',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Chinees /></h3>,
        submenu:[]

    },

    
    {id:16,
        menuName: 'SANDWICH',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Sandwich/></h3>,
        submenu:[]

    },
    {id:17,
        menuName: 'BURGER',
        menuDescription: 'BURGER',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Burger /></h3>,
        submenu:[]

    },

    {id:18,
        menuName: 'ROLL',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Roll /></h3>,
        submenu:[]

    },
    {id:19,
        menuName: 'PAKISTANI DASHES',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Pakistanidishes /></h3>,
        submenu:[]

    },

    {id:20,
        menuName: 'FISH & PRAWN',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Fish/></h3>,
        submenu:[]
    }

    ,
    {id:21,
        menuName: 'FRENCH FRIES',
        menuDescription: 'FRENCH FRIES',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Fries /></h3>,
        submenu:[]
    },
  
    {id:22,
        menuName: 'FRESH SALAD',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Salad /></h3>
,submenu:[]
    },
     {id:23,
        menuName: 'Other',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Salad /></h3>,
        submenu:[]

    }
    , {id:24,
        menuName: 'Continental',
        menuDescription: 'General setups menus',
        path: '/takeaway/menuOrder',
        component: MenuCard,
        exact: true,
        icon:<h3><Pakistanidishes /></h3>,
        submenu:[]

    }
    
]*/

export function getFoodCategoryMenuItems(menuPath) {
    const AdminMenuItems=[
        {
            id:1,
            menuName: 'Mutton',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Ribs /></h3>,
            submenu:[]
        },
        {   id:2,
            menuName: 'Prawn',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Prawn /></h3>,
            submenu:[]
    
        },
        {   id:3,
            menuName: 'Chicken',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Chicken /></h3>,
            submenu:[]
    
        },
        {   id:4,
            menuName: 'Salaan',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Salan /></h3>,
            submenu:[]
    
        },
        {   id:5,
            menuName: 'Biryani',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Biryani /></h3>,
            submenu:[]
    
        },
        {   id:6,
            menuName: 'Vegetable',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Vegetable /></h3>,
            submenu:[]
    
        },
        {   id:8,
            menuName: 'Bread',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Roti /></h3>,
            submenu:[]
    
        },
        {   id:9,
            menuName: 'BBQ',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Bbq /></h3>,
            submenu:[]
    
        },
        {   id:10,
            menuName: 'Cold Drink',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Drinks /></h3>,
            submenu:[]
    
        },
        {   id:11,
            menuName: 'Hot Drink',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Coffee /></h3>,
            submenu:[]
    
        },
        {   id:12,
            menuName: 'Sweet',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Icecream /></h3>,
            submenu:[]
    
        },
        {   id:13,
            menuName: 'Soup',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Soup /></h3>,
            submenu:[]
    
        },
       
        {   id:14,
            menuName: 'Fast Food',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Fastfood /></h3>,
            submenu:[]
    
        },
        {   id:15,
            menuName: 'CHINESE',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Chinees /></h3>,
            submenu:[]
    
        },
        {   id:16,
            menuName: 'SANDWICH',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Sandwich/></h3>,
            submenu:[]
    
        },
        {   id:17,
            menuName: 'BURGER',
            menuDescription: 'BURGER',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Burger /></h3>,
            submenu:[]
    
        },
        {   id:18,
            menuName: 'ROLL',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Roll /></h3>,
            submenu:[]
    
        },
        {   id:19,
            menuName: 'PAKISTANI DASHES',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Pakistanidishes /></h3>,
            submenu:[]
    
        },
        {   id:20,
            menuName: 'FISH & PRAWN',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Fish/></h3>,
            submenu:[]
        },
        {   id:21,
            menuName: 'FRENCH FRIES',
            menuDescription: 'FRENCH FRIES',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Fries /></h3>,
            submenu:[]
        }, { id:22,
            menuName: 'Tandoori',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Pakistanidishes /></h3>,
            submenu:[]
    
        },
        {   id:23,
            menuName: 'FRESH SALAD',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Salad /></h3>,
            submenu:[]
        }
        , { id:24,
            menuName: 'Continental',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Pakistanidishes /></h3>,
            submenu:[]
    
        },
        , { id:25,
            menuName: 'Starter',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Pakistanidishes /></h3>,
            submenu:[]
    
        },
        , { id:26,
            menuName: 'Handi',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Pakistanidishes /></h3>,
            submenu:[]
    
        },
        , { id:27,
            menuName: 'Arabic Plater',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Pakistanidishes /></h3>,
            submenu:[]
    
        },
        , { id:28,
            menuName: 'Shakes & Smoothies',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Pakistanidishes /></h3>,
            submenu:[]
    
        },
        {   id:29,
            menuName: 'Other',
            menuDescription: 'General setups menus',
            path: menuPath,
            component: MenuCard,
            exact: true,
            icon:<h3><Salad /></h3>,
            submenu:[]
    
        }
        
         
    ]
   return AdminMenuItems
  }
