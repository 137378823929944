import React from 'react'
import {RecepDinning} from '../../pages/Layout/reception/dinningArea'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {Orders} from '../../pages/Layout/waiter/orders'
import {CustomerList} from '../../pages/Layout/reception/CustomerList'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {NewOrders} from '../../pages/Layout/kitchen/NewOrders'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import {Menu,DinningArea,Bills,Logout,OrdersIcon , Customer,Cooking} from '../../component/partials/CustomDasboarIcon'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupIcon from '@material-ui/icons/Group';
import ListAltIcon from '@material-ui/icons/ListAlt';

export const AccountantMenu=[
    {
        id:9,
        menuName: 'Sales overview',
        menuDescription: 'Sales overview',
        path: '/admin/sales-overview',
        component: RecepDinning,
        exact: true,
        icon :<ListAltIcon />,
        submenu:[]
    },
    {
        id:9,
        menuName: 'Sales Daetails',
        menuDescription: 'Sales Daetails',
        path: '/admin/sell-overview',
        component: RecepDinning,
        exact: true,
        icon :<ListAltIcon />,
        submenu:[]
    },
    {
        id:10,
        menuName: 'Logout',
        menuDescription: 'Logout',
        path: '/',
        component: RecepDinning,
        exact: true,
        icon :<Logout />,
        submenu:[]
    }
    
]