export const waitersInfoReducer = (state= {waitersInfo:null},action) => {
    switch(action.type) {
        case "SET_CURRENT_WAITER":
            return {
                ...state,waitersInfo:action.payload
            }


        default:
            return {...state}
    }
}