import { Badge } from '@material-ui/core';
import { useState } from 'react';
import { CardBody } from 'reactstrap';
import { useStatevalue } from '../Context-Api/StateProvider'
import {BaseURL} from './baseURL'

export function Auth(name,pass) {

    // const [userName, pass] = useStatevalue();


// let BaseURL=BaseURL
var raw=" "
var myHeaders=new Headers();
myHeaders.append('content-type','application/json');
myHeaders.append('Accept','application/json');
var requestOption={
    method:'POST',
    Headers:myHeaders,
    body:raw
    // body:'working'
}    
// login?name=deepak&password=deepak
return new Promise(( resolve, reject ) => {
    // fetch(BaseURL+`/restaurant/login/${name}/${pass}`,requestOption)
        fetch(BaseURL+`/restaurant/login?name=${name}&password=${pass}`,requestOption)
        // fetch(BaseURL)
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson)
            let result = responseJson
            return result
// dispatch({
//     type:'SET_USER',
//     responseJson
// })
        })
        .catch((error) => {
            reject(error)   

            alert('please enter correct email and password')
            // let error=error
            // return error
            
        })
})
}