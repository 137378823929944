import React, { useState, useEffect } from 'react';
import Heading from '../../../component/partials/Heading'
import Para from '../../../component/partials/Para';
import Input from '../../../component/partials/Input';
import Btn from '../../../component/partials/Button'
import '../../style.css';
import { Auth } from '../../../services/Auth'
import { useHistory } from 'react-router-dom'
import { useStatevalue } from '../../../Context-Api/StateProvider';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setDineTable } from '../../../redux/actions';
import { getDining } from '../../../services/getDiningData'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { BorderBottom } from '@material-ui/icons';
import Logo from '../../../assets/images/header-01.png'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      color: 'white',
      borderBottom: "1px solid white",
      // width: "380px",
      // marginLeft: "50px",
      // marginRight: "10%",

    },
    '& .MuiInputBase-input':{
      width: '300px'
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },

  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const SignIn = () => {
    /*---------disable the console logs when production environment--------*/
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
  const classes = useStyles();

  const history = useHistory();

  const { user } = useSelector(state => state.userReducer)
  const [open, setOpen] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  console.log("state from reducer working", user)


  const dispatch = useDispatch()

  //  [{user,userName,password}]=useStatevalue();
  // let[user,userName,pass]=useStatevalue();

  // const [data,setData] = useState(user);



  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  let serializedState;

  var a = JSON.parse(localStorage.getItem('user'))
  // dispatch(setUser(JSON.parse(localStorage.getItem('user'))))
  // {a.role?RouteUser():''}

  const RouteUser = () => {
    {
      if (a) {
        // dispatch(setUser(a))
        switch (a.role) {
          case 'reception':
            // console.log('switch working')
            history.push('/reception');
            // getDining('/restaurant/dinning').then(
            //   (result) => {
            //       let response = result;
            //       console.log('checking API data', response)
            //       dispatch(setDineTable(response))


            //   })
            const RouteUser = () => {
              {
                if (a) {
                  // dispatch(setUser(a))
                  switch (a.role) {
                    case 'reception':
                      // console.log('switch working')
                      history.push('/reception');
                      // getDining('/restaurant/dinning').then(
                      //   (result) => {
                      //       let response = result;
                      //       console.log('checking API data', response)
                      //       dispatch(setDineTable(response))


                      //   })

                      break;
                    //  case 'admin':
                    //    history.push('/admin')
                    //    break;

                    // case 'waiter':
                    //   history.push('/waiter');
                    //   break;

                    //   case 'cashier':
                    //     history.push('/cashier')
                    //     break;

                    //   case 'kitchen':
                    //     history.push('/kitchen')
                    //     break;
                    // default :
                    // console.log('default');

                  }
                }
              }
            }

            break;

          case 'admin':
            history.push('/admin');
            break;

          case 'waiter':
            history.push('/waiter');
            break;
          case 'adminwaiter':
              history.push('/waiter');
              break;
          case 'cashier':
            history.push('/cashier')
            break;

          case 'kitchen':
            history.push('/kitchen')
            break;
          case 'cheif':
              history.push('/kitchen')
              break;

          case 'takeaway':
            history.push('/takeaway')
            break;
          case 'storekeeper':
            history.push('/storekeeper')
            break;
            console.log('default');

        }
      }
    }
  }


  useEffect(() => {
    if (a) {
      dispatch(setUser(a))

      RouteUser()
    }
  }, [])


  const handleSignIn = (e) => {
    setBackdropOpen(true);
    let a;
    e.preventDefault()

    Auth(email, password).then(
      (result) => {
        
        console.log('here your api status',result.status)
        let responseJson = result;
        console.log('this is my response', responseJson)
        serializedState = JSON.stringify(responseJson);

        localStorage.setItem('user', serializedState)
        // localStorage.setItem('user',JSON.stringify(user))
        a = JSON.parse(localStorage.getItem('user'))
        dispatch(setUser(a))
        // dispatch(setUser(responseJson))
        // const role=responseJson;
        if (a) {
          console.log('chal naaa bhai')
          
          switch (a.role) {
            case 'reception':
              history.push('/reception');
              getDining('/restaurant/dinning').then(
                (result) => {
                  let response = result;
                  console.log('checking API data', response)
                  dispatch(setDineTable(response))
                  localStorage.setItem('user', serializedState)
                  // localStorage.setItem('user',JSON.stringify(user))
                  a = JSON.parse(localStorage.getItem('user'))
                  dispatch(setUser(a))
                  // dispatch(setUser(responseJson))
                  // const role=responseJson;
                  if (a) {
                    console.log('chal naaa bhai')
                    switch (a.role) {
                      case 'reception':
                        history.push('/reception');
                        getDining('/restaurant/dinning').then(
                          (result) => {
                            let response = result;
                            console.log('checking API data', response)
                            dispatch(setDineTable(response))
                          })
                    }
                  }
                })
              break;
            case 'admin':
              history.push('/admin');
              break;
            case 'waiter':
              history.push('/waiter');
              break;
            case 'adminwaiter':
              history.push('/waiter');
              break;
            case 'cashier':
              history.push('/cashier')
              break;
            case 'kitchen':
                history.push('/kitchen')
                break;
            case 'cheif':
              history.push('/kitchen')
              break;
            case 'takeaway':
              history.push('/takeaway')
              break
            case 'storekeeper':
              history.push('/storekeeper')
              break;
            case 'accountant':
              history.push('/accountant')
              break;
            default:
              setBackdropOpen(false);
              setOpen(true);
              //alert('Please enter correct Email and Password')
          }
        }
      }
    )
  };

  const handleAlert = (e) => {
    e.preventDefault()
    // alert('its working')
    alert('btn working')
  }


  console.log('user value is ', user)
  return (
    <>
      <div className="container-fluid signIn">
        <div className="row justify-content-center bg" style={{position:"relative" ,top:"12%"}}>
         
            <div className="Logo_conatiner col-12 col-sm-6 col-md-3">
           
              <div className=" ">
                <div className='image'>
                  <img src={Logo} alt="" style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%',
                    zIndex: "1"
                  }} />
                </div>
              </div>
            </div>
            {/* row */}
            <div className="main_conatiner col-12 col-sm-6 col-md-3" style={{padding:''}}>

              <div className="heading" >
                <h1 style={{ color: 'white', }}>Login</h1>
                <p style={{ color: 'white', fontSize: "14px" }}> Please Enter Your User Name and Password</p>
              </div>


              <form className={classes.root} noValidate autoComplete="off"  >

                <div style={{display: 'flex', justifyContent: 'center'}} className=" textFeild">

                  <TextField id="standard-basic"
                    InputLabelProps={{
                      style: { color: '#fff', marginLeft: "" },
                    }}
                    className={classes.input}
                    label="User Name"
                    type=""
                    style={{ marginTop: "" }}
                    onChange={e => setEmail(e.target.value)}
                    inputProps={{ style: { color: 'white',  } }} />


                </div>
                <div style={{display: 'flex', justifyContent: 'center'}} className=" textFeild">
                  <TextField id="standard-basic"
                    InputLabelProps={{
                      style: { color: '#fff', marginLeft: "" },
                    }}
                    className={classes.input}
                    label="Password"
                    type="password"
                    style={{ marginTop: "10%" }}
                    onChange={e => setPassword(e.target.value)}
                    inputProps={{ style: { color: 'white' } }} />
                </div>
                <div  className="buttonss" style={{ display: 'flex', justifyContent: 'center', padding: '50px', backgroundColor:"black" }}>
                  <Btn title="Sign In" type='submit' onClick={handleSignIn} width='200px' height='45px' borderRadius='2.5px' />
                  
                </div>
              </form>
            </div>
       
        </div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Please enter the correct username and password!
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </div>
    </>
  )
}



