import React,{useEffect,useState} from 'react'
import './style.css'
import {useSelector,useDispatch} from 'react-redux'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {delMenu} from '../../../services/deleteMenu'
import {getCustomerOrder} from '../../../services/getCustomerOrder'
import {setTableItem} from '../../../redux/actions'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
backdrop: {
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
},
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const KitchenEditTableOrder = () => {
  /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }
    const {tableItem}=useSelector(state=>state.tableItemReducer)
    let [deleteDialogOpen,setDeleteDialogOpen]=useState(false);
    const[selectedOrderId,setSelectedOrderId]=useState();
    const[selectedOrderItemId,setSelectedOrderItemId]=useState();
    const[selectedOrderItemName,setSelectedOrderItemName]=useState();
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const[snackOpen,setSnackOpen]=useState(false)
    const classes=useStyles();
    const dispatch=useDispatch();
    const handleClose=()=>{
        setDeleteDialogOpen(false)
      }
      const snackHandleClose=(event, reason)=>{
        if (reason === 'clickaway') {
          return;
        }
        setSnackOpen(false);
      }
      
    const postnDeleteMenu=()=>{
      setBackdropOpen(true);
        delMenu(`/restaurant/order-menu-item/${selectedOrderItemId}`).then(
          (result)=>{
            console.log(`here deleted one menu item id is ${selectedOrderItemId}`)
            getCustomerOrder(`/restaurant/customer-orders?order-id=${tableItem[0].id}`).then(
              (result)=>{
                let response =result
                dispatch(setTableItem(response))
                setBackdropOpen(false);
              }
          )
            setSnackOpen(true);
          }
        )
       // document.getElementById(`order-item-${selectedOrderItemId}`).remove() 
        setDeleteDialogOpen(false);
      }
     const deleteMenu=(data)=>{
        setSelectedOrderId(data.orderId)
        setSelectedOrderItemId(data.orderItemId)
        setSelectedOrderItemName(data.name)
        setDeleteDialogOpen(true)
      }

console.log('table item ',tableItem)
    return(
        <Grid item xs={12} md={6}>
          <Typography variant="h6" >
            Remove items from:<br/>  Table: {tableItem[0].floorTableName}<br/>  Order: {tableItem[0].id}
          </Typography>
          <div >
            <List >
            {
                tableItem[0].orderMenuItem.map((data, tableIndex) => {
                    return (
                        <ListItem id={"order-item-"+data.orderItemId}>
                            <ListItemText
                            primary={data.name}
                            />
                            <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={()=>deleteMenu(data)}>
                                <DeleteIcon color="primary" />
                            </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        )
                    })
            } 
            </List>
          </div>
           <Dialog open={deleteDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{textAlign:'center'}}>Delete Menu Item</DialogTitle>
                <DialogContent>
                <h6>Are you sure you want ro remove the following Menu Item?</h6>
                    <label>{selectedOrderItemName}</label>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={postnDeleteMenu} color="secondary">
                    Delete
                </Button>
                </DialogActions>
            </Dialog>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={snackOpen} autoHideDuration={6000} onClose={snackHandleClose}>
        <Alert  severity="success">
          {selectedOrderItemName} is removed from order: {selectedOrderId}!
        </Alert>
      </Snackbar>
        </Grid>
        
    )
}