import React, { useEffect, useState } from 'react'
import { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import { ImageCards } from '../../../component/partials/Imagecards';
//import { Tablebill } from  '../../../component/partials/Tablebills'
import { Tablebills } from '../../../component/partials/Tablebills'
import { TablebillCom } from '../../../component/partials/TableBillCommon'
import '../cashier/style.css'
// import { MenuData } from '../cashier/data'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { useLocation } from 'react-router-dom'
import {IconButton} from '@material-ui/core' 
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuData, addMenuItem, categoryItem, setBasket, setDuplicatedWholeBasket,setCardItem,incWholeBasket, setOldItem,setTotalTableInfo,addCart,setWholeBasket } from '../../../redux/actions';
import { MenuData } from '../waiter/meuData'
import { getMenuItem } from '../../../services/getMenuItem'
import { Row, Col } from 'react-bootstrap'
import PureBreadcrumbs from '../../../component/Breadcrumb'
import { getDining } from '../../../services/getDiningData'
import Spinner from '../../../component/partials/Spinner';
import mutton from '../../../assets/images/Dishes/mutton.jpg'
import {getCustomerOrder} from '../../../services/getCustomerOrder';
import NoImage from '../../../assets/images/no-image.png'



export const MenuItem = () => {
  /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }
  const dispatch = useDispatch()
  const { menuId } = useSelector(state => state.menuIdReducer)
  const { basket } = useSelector(state => state.basketReducer)
  const { item } = useSelector(state => state.categoryItemReducer)
  const { menuData } = useSelector(state => state.menuDataReducer)
  const { card } = useSelector(state => state.cardItemReducer)
  const { orderId } = useSelector(state => state.orderIdReducer)
  const [loading, setLoading] = useState(false)
  const { oldItem } = useSelector(state => state.oldItemReducer)
  const {wholeBasket} =useSelector(state =>state.wholeBasketReducer)
  const location = useLocation();
  const {cart} =useSelector(state=>state.cartReducer)
  const {duplicatedWholeBasket} = useSelector(state=>state.duplicatedWholeBasketReducer)
  const [addAction, setAddAction] = useState(false);
  const {categoryName} =useSelector(state=>state.categoryNameReducer)
  let a;
  let response;
console.log('here order is',orderId)
  useEffect(() => {
    if(menuData.length===0)
    {
    getMenuItem('/restaurant/getmenu').then(
      (result) => {
        response = result;
        dispatch(categoryItem(response['Mutton']))
        dispatch(setMenuData(response))
       // dispatch(setCardItem(mutton))
      }

    )
  }else
  {
      console.debug('menu data available!');
  }
    setTimeout(() => {
      setLoading(true);
    }, 1000);

    getCustomerOrder(`/restaurant/customer-orders/${orderId}?hours=16`).then(
      (result) => {

        let response = result;
        dispatch(setDuplicatedWholeBasket(result))
        dispatch(setWholeBasket(result))
        console.log('checking ORDER DATA', response)
      let uniqueObjects;
// Duplicate Code
const arr={"id":248,"userId":10,"floorTableId":83,"customerId":319,"status":"progress","date":null,"billId":0,"orderMenuItem":[{"orderItemId":646,"orderItemStatus":"done","orderId":248,"menuItemId":3,"name":"Mutton Shahi Karahi (Full)","categoryName":"Mutton","price":2200},{"orderItemId":645,"orderItemStatus":"done","orderId":248,"menuItemId":7,"name":"Mutton white Karahi (full)","categoryName":"Mutton","price":2300},{"orderItemId":651,"orderItemStatus":"done","orderId":248,"menuItemId":45,"name":"Roti","categoryName":"Bread","price":20},{"orderItemId":650,"orderItemStatus":"done","orderId":248,"menuItemId":45,"name":"Roti","categoryName":"Bread","price":20},{"orderItemId":649,"orderItemStatus":"done","orderId":248,"menuItemId":45,"name":"Roti","categoryName":"Bread","price":20},{"orderItemId":648,"orderItemStatus":"done","orderId":248,"menuItemId":45,"name":"Roti","categoryName":"Bread","price":20},{"orderItemId":647,"orderItemStatus":"done","orderId":248,"menuItemId":45,"name":"Roti","categoryName":"Bread","price":20}]}
if(orderId ){
 uniqueObjects = [...new Map(response.orderMenuItem.map(item => [item.menuItemId, item])).values()]


}
else{
 uniqueObjects = [...new Map(arr.orderMenuItem.map(item => [item.menuItemId, item])).values()]
}

  // const obj = [...new Map(response.orderMenuItem.map(item => [JSON.stringify(item), item])).values()];
  // console.log('kuch log bht yaad aate',obj);
 

  // const arr=response.orderMenuItem
  // const uniqueObjects = [...new Map(arr.map(item => [item.id, item])).values()]
  // const filteredArr = arr.reduce((acc, current) => {
  //   const x = acc.find(item => item.id === current.id);
  //   if (!x) {
  //     return acc.concat([current]);
  //   } else {
  //     return acc;
  //   }
  // }, []);

  console.log('filteres data',uniqueObjects)
// End Duplicated Code
        
dispatch(setTotalTableInfo(result))
        // console.log('ordermenu data', response.orderMenuItem.length)
        dispatch(setOldItem(uniqueObjects.length));
        //  setLength(arr.length)
        //  len=arr.length
        // dispatch(setTableOrder(response))
        dispatch(setBasket(uniqueObjects))

        // if (mounted) {
        //     setList(table)
        //     console.log('dinetable after action', dineTable)
        // }


      })
  }, [])
  console.log('order length 1', oldItem)


  console.log('valeu of a', a)
  console.log('this is menu redux menu item =========', menuData)

  console.log('this is menu redux item =========', item)
  const addMenu = (data) => {
    setAddAction(true)
    
    console.log('this is current item id', data)
    let pay = {
      name: data.name,
      price: data.price,
      id: data.id?data.id:data.menuItemId,
      // quantity:1
    }
    let amt={
      name: data.name,
      price: data.price,
      menuItemId: data.id?data.id:data.menuItemId,
    }

    // console.log('check comming data',data)
dispatch(incWholeBasket(amt))
    dispatch(addMenuItem(pay))
    dispatch(addCart(data.id))
  }

console.log('whole basket in parent',wholeBasket)

  console.log('hahahaha--------++++++++', menuData['FRENCH FRIES'])



  return (
    <div >
      {/* <h1 style={{position:'absolute'}}>Check is it working?</h1> */}
      <PureBreadcrumbs />
      {/* alignItems="stretch" */}
      <Grid container spacing={0} >
        <Grid item xs={6} lg={6} md={6} spacing={0} >
          <div >
            {/* class="cards" */}
            <Row>
              {


                item ?
                  item.map((data, index) => {
                    return (


                      <Col>
                        <div style={{ position: 'relative' }}>
                          <ImageCards price={data.price} image={data.path?'data:image/png;base64,'+data.path:NoImage} name={data.name} maxHeight="290px" data={data} index={index} category={categoryName} />

                         <IconButton style={{position: 'absolute', top: '10px', left: '10px'}}  onClick={() => { addMenu(data) }}> <AddCircleRoundedIcon style={{  color: '#FF0000', fontSize: '50px' }} /></IconButton>
                        </div>
                      </Col>

                    )

                  }
                  ) : <Spinner />
              }
            </Row>



          </div>
        </Grid>
     <Grid item xs={6} lg={6} md={6} spacing={0}>
          {loading && wholeBasket && duplicatedWholeBasket? <TablebillCom action={addAction} /> : <Spinner />}
        </Grid>
      </Grid>
    </div>
  )
}