import React,{useEffect,useState, useRef} from 'react';
import {Link,useHistory} from 'react-router-dom';
import {checkData} from './data';
import Btn from '../../../component/partials/Button';
import Para from '../../../component/partials/Para';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import './style.css';
import {getBills} from '../../../services/getBills';
import {getCustomerOrder} from '../../../services/getCustomerOrder';
import {useDispatch,useSelector} from 'react-redux';
import {setKitchenOrder,categoryItem,setTableItem,setMenuData} from '../../../redux/actions';
import  {DataGrid}  from '@material-ui/data-grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {Spinner} from '../../../component/partials/Spinner';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {Theme} from '@material-ui/core';
import { getMenuItem } from '../../../services/getMenuItem';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Button from '@material-ui/core/Button';
import NotificationsIcon from '@material-ui/icons/Notifications';

const useStyles = makeStyles({
  theCell: {     
      color: '#1a3e72',
      fontWeight: '600',
      fontSize: '16px',
  },
    root: {
    //   `& .text-strike-${results.id}`:{
    //  backgroundColor:'orange'
    //   },
      '& .tr-table-order-new': {
        backgroundColor: 'rgba(255, 179, 0, 0.98)',
      },
      '& .tr-table-order-completed': {
        backgroundColor: '#fe0101',
      },
      '& .tr-table-order-in-progress': {
        backgroundColor: '#86d689',
      },
      
      
    },
    
  });

export const NewOrders=()=>{
  
  /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }
    const classes = useStyles();
    const[snackOpen,setSnackOpen]=useState(false)
    
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(true);
    const { user } = useSelector(state => state.userReducer);
    const {menuData}=useSelector(state=>state.menuDataReducer)
    const [receivedData, setReceivedData] = React.useState(true);

    
    
let [results,setResult]=useState({id:''});
let [newOrderBgColor,setNewOrderBgColor]=useState('#e0e0e0')
let [newOrderClick,setNewOrderClick]=useState(true)
const {order}=useSelector(state=>state.kitchenOrderReducer)
const {item}=useSelector(state=>state.categoryItemReducer)
const dispatch=useDispatch()
let kitchenOrderBeforeInitialize;
const [isChange,setIsChange]=useState(false)
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
console.log('color of newOrderBgColor',newOrderBgColor)
console.log('status of newOrderClick',newOrderClick)
const snackHandleClose=(event, reason)=>{
  if (reason === 'clickaway') {
    return;
  }

  setSnackOpen(false);
}
const colorAndActiveness=(id)=>{
  let qot=order.filter((data)=>data.id == id)
  var shot=qot[0].orderMenuItem.filter((data) => data.orderItemStatus.includes("pending"))
if(shot.length >= 1){
  console.log('shot color')
  setNewOrderBgColor('#800080')
}
else{
  setNewOrderClick(false)
}

}
const history=useHistory()
    useEffect(()=>{
      getCustomerOrder(`/restaurant/customer-orders?hour=16`).then(
        (result)=>{
            let response =result
            dispatch(setKitchenOrder(response))
            setReceivedData(false) ;
            kitchenOrderBeforeInitialize=response
            if(menuData.length===0)
                {
                    getMenuItem('/restaurant/getmenu').then(
                        (result) => {
                            let response = result;
                            dispatch(setMenuData(response))
                            dispatch(categoryItem(response['Mutton']))
                        }
                    )
                }else
                {
                    console.debug('menu data available!');
                }

        }
    )
      const interval = setInterval(() => {
        apiCall()
    }, 7000);
 
    return () => clearInterval(interval)  
       
    },[])
    function apiCall(){
      var orderStatusParameter='&status=progress';
      if(switchOnRef.current)
      {
        orderStatusParameter='';
      }
      getCustomerOrder(`/restaurant/customer-orders?hour=16`+orderStatusParameter).then(
        (result)=>{
            let response =result
            dispatch(setKitchenOrder(response))
            let dineTableData=JSON.stringify(kitchenOrderBeforeInitialize)
            let myResult;
             myResult = response.filter(({ id: id1 }) => !kitchenOrderBeforeInitialize.some(({ id: id2 }) => id2 === id1));
            setResult(myResult);           
            if(myResult.length>=1){
              setIsChange(true)
            }else{
              setIsChange(false)
            }

            if( dineTableData === JSON.stringify(response)){
                console.log('dine table match')
          // alert('some thing added in dining')
            } 
            else{
          console.log('dine table does not match')
          setSnackOpen(true)

          // alert('hey lookie')
            }
            kitchenOrderBeforeInitialize=response


        }
    )
    
    }    
    const[isToggleOn,setIsToggleOn]=useState(false)
    const switchOnRef = useRef(false);
    let filterTable=[];
    const  handleSwitchClick=(event)=>{
            //setIsToggleOn(!isToggleOn)
            if(event.target.checked)
            {
             // setFilterText('progress');
              setIsToggleOn(true);
              switchOnRef.current=true;
              //apiCall();
            }
            else{
              //setFilterText('');
              setIsToggleOn(false);
              switchOnRef.current=false;
              //apiCall();
            }
           // setState({ ...state, [event.target.name]: event.target.checked });
  }
  if(order){
    if(!isToggleOn){
      // data.status.includes('new')
    filterTable=order.filter((data)=>data.status.includes('progress') )
    }
    else{
        filterTable=order.filter((data)=>data.status.includes('progress') || data.status.includes('completed')  )
    }
}

console.log('this is filter table',filterTable)
console.log('this is order table',order)

const handleClick=(index,theEvent)=>{    
    console.log('dekho kya aya hai',index)
    // let mot=order.filter((data)=>data.id)
    // console.log('dkihao mot',mot)
    let mot=order.filter((data)=>data.id == index)
    console.log('order index k sath',order[index])
    console.log('transfer data',mot)
    dispatch(setTableItem(mot))
    if(theEvent==='edit')
    {
      history.push('/kitchen/edit-table-order');      
    }
    else{
      history.push('/kitchen/tableOrder');
    }
// dispatch(categoryItem(order[index]))
}
console.log('full rows info',order)
const handleNewOrder=(index)=>{
// console.log('handle 2',order[index])
let mot=false
mot=order.filter((data)=>data.id == index)
console.log('full order',order)
console.log('full order index',index)

    // console.log('dkihao mot',mot)
if(mot){
    var b=mot[0].orderMenuItem.filter((data) => data.orderItemStatus.includes("pending"))
    console.log('length of new order',b.length)
if(b.length >= 1){
  setNewOrderBgColor('#800080')
  // <Link to='/kitchen/newTableOrder' /> 
  history.push('/kitchen/newTableOrder')
  
}
else{
  setNewOrderClick(false)
}
console.log('handle 2',b)
dispatch(setTableItem(mot))
}
}

const columns = [
    { field: 'id', headerName: 'Order #', width: 110 },    
    { field: 'floorTableName', headerName: 'Table #', width: 150, 
    cellClassName: classes.theCell, 
    renderCell: 
    (params) => {
      return (
        <Link to='/kitchen/tableOrder' onClick={()=>{handleClick(params.id)}} color="inherit">
          {params.value}
        </Link>
        )}
    },    
    { field: 'status', headerName: 'Status', width: 110, },
    { field:'dining_type',headerName:'Order Type',width:130},
    { field: 'col5',
        headerName: 'Order',
        width: 150,
        renderCell: 
             (params) => (
              <Button
              variant="outlined" 
              color="primary"
              size="small"
              className={classes.button}
              startIcon={ <FormatListBulletedIcon />}
              onClick={()=>{handleClick(params.id)}} 
            >
              View
            </Button>
     ),
        disableClickEventBubbling: true,
    },   
    
    { field:'newCol',headerName: 'New',
    width: 150,
    renderCell:(params)=>{
    var val003=0
    var isColor=false
    var val001=params.id
    console.log('console param id',val001)
    var val002=  filterTable.filter(item=>item.id==params.id)
    console.log('console val002 id',val002)
    if(val001 == val002[0].id){
       val003 = val002[0].orderMenuItem
     let val004= val003.filter(item=>item.orderItemStatus === 'pending')
      console.log('full data of val003',val004)
      console.log(`length of ${params.id} is`,val004.length)
      if(val004.length >= 1){
        return(
          <IconButton aria-label="delete" className={classes.margin} onClick={()=>{handleNewOrder(params.id)}} color="secondary">
          <NotificationsActiveIcon fontSize="large" />
        </IconButton>
          )
      }
      else{
        return(
           
          <IconButton aria-label="delete" className={classes.margin} onClick={()=>{handleNewOrder(params.id)}} color="secondary" disabled>
          <NotificationsIcon fontSize="inherit" />
        </IconButton>)
      } 
    }
  }},
  { field: 'col7', headerName: 'Time', width: 100 ,
  renderCell:(params)=>{
    let val=params.row.date
    var orderTime = new Date(params.row.date+' UTC');
    console.log('here your changes order time',orderTime)
    console.log('value of param',params)
    console.log('value of val',val)
  val=  val.slice(10,19)
let theTime=orderTime.getHours()+":"+orderTime.getMinutes()+":"+orderTime.getSeconds()
  return(
    <p style={{marginTop:'auto'}}>{theTime}</p>
  )
  }
},
{ field: 'col8', headerName: 'Order Time', width: 150 ,
renderCell:(params)=>{
  var orderTime = new Date(params.row.date+' UTC');
  let newDate=new Date()

  var theDiff=newDate-orderTime
  var msec = theDiff;
  var hh = Math.floor(msec / 1000 / 60 / 60);
  msec -= hh * 1000 * 60 * 60;
  var mm = Math.floor(msec / 1000 / 60);
  msec -= mm * 1000 * 60;
  var ss = Math.floor(msec / 1000);
  msec -= ss * 1000;
  
var orderWaitingTime= String(hh).padStart(2, '0')+":"+String(mm).padStart(2, '0')+":"+String(ss).padStart(2, '0');
var isOver= false
  if(mm>29 || hh>=1){
  isOver=true
  }
  
  return(
  <p style={{marginTop:'auto',color: `${isOver ?'red':''}`}} className={`${isOver ?'blink_me':''}`}>{orderWaitingTime} <AccessAlarmIcon fontSize="small" color="action"/></p>
  )
}},
{ field: 'customerId', headerName: 'Cutomer #', width: 100 },
{ field: 'userId', headerName: 'Served by ID', width: 135 },
{ field: 'editButtonCol',
        headerName: 'Edit',
        width: 150,
        renderCell: 
             (params) => (
              user.role=='admin'?
               <IconButton aria-label="delete" className={classes.margin} onClick={()=>{handleClick(params.id,'edit')}} color="secondary" >
          <EditIcon fontSize="inherit" />
        </IconButton>:
        <IconButton aria-label="delete" className={classes.margin} disabled >
                  <EditIcon fontSize="inherit" />
                </IconButton>  ),
        disableClickEventBubbling: true,
    },
  ];

return(   
  <div>
    {order?<div><FormGroup className='MatToggle'>
           <FormControlLabel
        control={<Switch 
        color='primary'
        label="Completed"
        onChange={handleSwitchClick}/>}
        label="Completed"
      />
      </FormGroup></div>:''}
<div style={{ marginTop:'50px' , width: '100%' }} className={classes.root} id='kitchenNewOrders-order-table'>
<DataGrid  
rows={filterTable} 
columns={columns} 
pageSize={50} 
density="compact"
//autoPageSize='true' 
autoHeight='true'
//getRowClassName={(params) => (`tr-table-order-${params.getValue('status')} `)  }
rowsPerPageOptions={[50, 100, 200, 400,800]}
loading={receivedData}
/*className={isChange?'text-strike':''}*/
 />
</div>
<Snackbar open={snackOpen} autoHideDuration={6000} onClose={snackHandleClose}>
        <Alert onClose={snackHandleClose} severity="success">
        New order received!
        </Alert>
      </Snackbar>
</div>
)
}