import React from 'react';
import { Switch, Route,useLocation } from 'react-router-dom';
import { useStyles } from '../static/MiniDrawerStyles';
import clsx from 'clsx';
import './style.css';


import { AdminSetupPage } from '../pages/AdminSetupPage';
import { DepartmentSetupPage } from '../pages/DepartmentSetupPage';
import { SubDepartmentSetupPage } from '../pages/SubDepartmentSetupPage';
import { DeviceSetupPage } from '../pages/DeviceSetupPage';
import {Dinning} from '../pages/Layout/waiter/diningArea';
import{RecepDinning} from '../pages/Layout/reception/dinningArea';
import{Orders} from '../pages/Layout/waiter/orders';
import {TableOrder} from '../pages/Layout/waiter/tableOrder';
import {TakeawayBillsTable} from '../pages/Layout/takeAway/Bills';
import {MenuItem} from '../pages/Layout/menu/menuItem';
import{MenuCard} from '../pages/Layout/menu/menuCard';
import {KitchenTableOrder} from '../pages/Layout/kitchen/TableOrder';
import {KitchenEditTableOrder} from '../pages/Layout/kitchen/EditTableOrder';
import {CustomerList} from '../pages/Layout/reception/CustomerList';
import{NewOrders} from '../pages/Layout/kitchen/NewOrders';
import {KitchenNewTableOrder} from '../pages/Layout/kitchen/newTableOrder';
import {CashierTableOrder} from '../pages/Layout/cashier/CashierTableOrder';
import {CashierBillsTable} from '../pages/Layout/cashier/CashierBillsTable';
import {CashierDining} from '../pages/Layout/cashier/dineTable';
import {PlaceOrder} from '../pages/Layout/takeAway/Delivery/PlaceOrder';
import {OrderList} from '../pages/Layout/takeAway/Delivery/orderList';
import {useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {DeliveryNewOrder} from '../pages/Layout/takeAway/newOrder';
import {TakeawayOrder} from '../pages/Layout/takeAway/TakeawayOrder';
import {ProductDetail} from '../pages/Layout/storeKeeper/inventry/productDetail';
import {InvoiceDetail} from '../pages/Layout/storeKeeper/invoice/invoiceDetail';
import {OrdersDetail} from '../pages/Layout/storeKeeper/ordersDetail';
import {Suppliers} from '../pages/Layout/storeKeeper/suppliers';
import {Invoices} from '../pages/Layout/storeKeeper/invoices';
import {storeDashboard} from '../pages/Layout/storeKeeper/dashboard';
import {Inventory} from '../pages/Layout/storeKeeper/inventory';
import {AddProduct} from '../pages/Layout/storeKeeper/inventry/addProduct';
import {Transactions} from '../pages/Layout/storeKeeper/transactions';
import {Reports} from '../pages/Layout/storeKeeper/reports';
import {AddUser} from '../pages/Layout/admin/AddUser';
import {UserDetail} from '../pages/Layout/admin/UserDetail';
import {SellOverview} from '../pages/Layout/admin/SellOverview';
import {SalesOverview} from '../pages/Layout/admin/SalesOverview';



const Styles = makeStyles({
menuContent:{
    padding: '80px 10px 80px 80px',
    flexGrow:1
}    

});


export const Routing = (props) => {
    const { content, toolbar, contentShift } = useStyles();
    const { openSideBar } = props;
    const {user} = useSelector(state => state.userReducer)
    const location=useLocation()
    const classes=Styles()
let isTrue=true
let menuCss=false
    // const [isTrue,setIsTrue]=useState(true)
if(location.pathname == '/waiter/menu'){
isTrue=false
menuCss=true
}



    return (
        <main className={clsx('marginEnd', {            
            [contentShift]: openSideBar,
            [content]:isTrue,
            [classes.menuContent]:menuCss
})} >
    {/*
    user && window.location.pathname ==='/storekeeper/reports'?
    <Reports />
    :
    ""
    */
    }
            {user? 
            
            <Switch>
                {console.debug("user ",user,"--->",window.location.pathname )}
{/* Cashier */}
                {/*<Route path='/cashier' exact component={Dinning}/>*/}
                <Route path='/cashier' exact component={CashierBillsTable}/>
                <Route path='/cashier/tableOrder' exact component={CashierTableOrder}/>
                <Route path='/cashier/dining' component={CashierDining}/>
                <Route path='/cashier/menu' exact component={MenuCard} />    
{/* Kitchen */}
                <Route path='/kitchen' exact component={NewOrders} />
                <Route path='/kitchen/menu'  component={MenuCard} />
                <Route path='/kitchen/tableOrder'   component={KitchenTableOrder}/>
                <Route path='/kitchen/edit-table-order'   component={KitchenEditTableOrder}/>                
                <Route path='/kitchen/newTableOrder' component={KitchenNewTableOrder}/>
{/* Admin */}
                <Route path='/admin/menu'  exact component={MenuCard}/>
                <Route path='/admin' exact   component={RecepDinning}/>
                <Route path='/admin/customerList'  exact component={CustomerList}/>
                <Route path='/admin/orders' exact  component={Orders}/>
                <Route path='/admin/kitchen' exact  component={NewOrders}/>
                <Route path='/admin/bills'  exact component={CashierBillsTable}/>
                <Route path='/admin/add-user'  exact component={AddUser}/>
                <Route path='/admin/users-detail'  exact component={UserDetail}/>
                <Route path='/admin/sell-overview'  exact component={SellOverview}/>
                <Route path='/admin/sales-overview'  exact component={SalesOverview}/>
{/* store keeper */}
                <Route path='/storekeeper'  exact component={storeDashboard}/>
                <Route path='/storekeeper/inventry' exact component={Inventory} />
                {/*<Route path='/storekeeper/inventry/addProduct' exact component={AddProduct} />
                <Route path='/storekeeper/inventry/productDetail'  exact component={ProductDetail}/>
                <Route path='/storekeeper/invoice/invoiceDetail'  exact component={InvoiceDetail}/>
                <Route path='/storekeeper/invoice/addProduct'  exact component={InvoiceDetail}/>*/}
                <Route path='/storekeeper/orders'  exact component={OrdersDetail}/>
                <Route path='/storekeeper/invoices'  exact component={Invoices}/>
                <Route path='/storekeeper/suppliers'  exact component={Suppliers}/>
                <Route path='/storekeeper/transactions'  exact component={Transactions}/>
                
                <Route  path='/storekeeper/reports' component={Reports}></Route>
                {/*<Route path='/storekeeper/reports'  exact component={Reports} />*/}
 {/* waiter */}
                <Route path='/waiter' exact component={Dinning} />
                <Route path='/waiter/orders' component={Orders}></Route>
                <Route path='/waiter/tableOrder' component={TableOrder}></Route>
                <Route path='/waiter/waiterTableOrder' component={TableOrder}></Route>
                <Route path='/waiter/menu' exact component={MenuItem}></Route>
                <Route path='/waiter/menuItems' exact component={MenuCard} />   
{/* Reception */}
                <Route path='/reception' component={RecepDinning} /> 
                <Route path='/customerList' exact component={CustomerList}/>
{/* takeaway */}
                <Route path='/takeaway' exact component={DeliveryNewOrder}/>
                <Route path='/takeaway/menu' exact component={MenuCard} />
                <Route path='/takeaway/placeOrder'  component={PlaceOrder} />
                <Route path='/takeaway/bills'  component={TakeawayBillsTable} />
                <Route path='/takeaway/orderList'  component={OrderList} />
                <Route path='/takeaway/menuOrder' component={MenuItem}/>
                <Route path='/takeaway/orderTable' component={CashierTableOrder}/>
                <Route path='/takeaway/tableOrder' component={TakeawayOrder}/>
{/* Accountant */}
                <Route path='/admin/sell-overview'  exact component={SellOverview}/>
                <Route path='/admin/sales-overview'  exact component={SalesOverview}/>
{/* General */}
                <Route path='/generalSetup/adminSetup' component={AdminSetupPage} />
                <Route path='/generalSetup/department' component={DepartmentSetupPage} />
                <Route path='/generalSetup/sub-department' component={SubDepartmentSetupPage} />
                <Route path='/device'  component={DeviceSetupPage} />
    </Switch>
    :
    <Switch><Route path="/" /></Switch>}
</main>);
}