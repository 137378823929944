import React from 'react'
// import { createBrowserHistory} from 'history'
// import {useLocation} from 'react-router-dom'
import { Component } from 'react'
//import profilePicture from '../../../assets/images/profile/pfp.png';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
//import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
//import MyCard from '../../../component/partials/Card'
import './style.css'
//import {useSelector} from 'react-redux'
import {MenuData} from './meuData'
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});



export class waiterTableOrder extends Component{
    constructor(props){
        super(props)

    }
    
    //  App=()=>{
    //   const  tableOrder  = useSelector(state => state.tableOrderReducer)
    //   return tableOrder
    // }
    render(){
      console.log('check maaro',)
      
        return(
          
 <div >
    <Grid container spacing={0} alignItems="stretch">        
        <Grid item xs={8}>
        <div class="cards">
        {
MenuData.map(data=>{
    return(
<Card >
      <CardActionArea>
        <CardMedia
          image={data.image}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
          {data.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          {data.category}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Item number {data.id}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Rs {data.price}
          </Typography>
        </CardContent>
      </CardActionArea>     
    </Card>

    )
})
    }
    </div>
</Grid>
        <Grid item xs={4}>
        <List component="nav" aria-label="main mailbox folders">
        <ListItem >
          <ListItemText primary="Invoice number" />
          <ListItemText primary="192837" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="Cashier name" />
          <ListItemText primary="Nadeem" />
        </ListItem>
      </List>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folders">
        <ListItem >
          <ListItemText primary="Chicken karahi (full)" />
          <ListItemText primary="2000" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Spam" />
          <ListItemText primary="120" />
        </ListItem>
      </List>
      <Divider />
      <List >
        <ListItem >
          <ListItemText primary="Total" />
          <ListItemText primary="2120" />
        </ListItem>
        <ListItem >
          <ListItemText primary="Payment method" />
          <ListItemText primary="Cash" />
          </ListItem >
      </List>
        </Grid>        
      </Grid>
</div>

    )
        }
}