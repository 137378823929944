import React,{useState,useEffect} from 'react'
import { Component } from 'react';
import {checkData} from './data';
import { Row, Col } from 'react-bootstrap'
import './reception.css'
import { makeStyles } from '@material-ui/core/styles';
import Para from '../../../component/partials/Para'
import Heading from '../../../component/partials/Heading'
import Btn from '../../../component/partials/Button'
import {Divider} from '@material-ui/core'
import {setCustomer,setSingleCustomer} from '../../../redux/actions'
import {useDispatch,useSelector} from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {getCustomers} from '../../../services/getCustomerList'
import {getUsers} from '../../../services/getUsers'
import PureBreadcrumbs from '../../../component/Breadcrumb'
import {Link} from 'react-router-dom'
import  {DataGrid}  from '@material-ui/data-grid';
import { Spinner } from '../../../component/partials/Spinner';
import {Delete} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { MdAdd } from 'react-icons/md'
import { Avatar, IconButton } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    '& .tr-table-order-new': {
      backgroundColor: 'rgba(255, 179, 0, 0.98)',
    },
    '& .tr-table-order-completed': {
      backgroundColor: '#fe0101',
    },
    '& .tr-table-order-in-progress': {
      backgroundColor: '#86d689',
    },
  },
  box: {
    
    minWidth:'500px',
    '@media print':{
      minWidth:'600px'
    }
    
  },
  screen:{
    padding:'0px',
    textAlign:'center',
    '@media print':{
      textAlign:'left'
    }
  }
});



export const CustomerList=()=>{
  /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }
const {customer} =useSelector(state=>state.customerReducer)
const {customerPop} =useSelector(state=>state.singleCustomerReducer)
const { user } = useSelector(state => state.userReducer)
const { users } = useSelector(state => state.usersReducer)
const[adminDialogOpen,setAdminDialogOpen]=useState(false)

const [arrayOne,setArrayOne ]=useState([{customerId:1}]);
const [arrayTwo,setArrayTwo]=useState([{customerId:1}]) ;
const [billRow,setBillRow]=useState(0)
const [customerInfo,setCustomerInfo]=useState({
  "customerId": null,
  "customerEmail": "",
  "customerName": "",
  "customerPhone": null,
  "date": '2021-5-16 08:11:4',
  "user_id": null,
  "table_number": ""
})
const dispatch=useDispatch()
  const classes=useStyles();
  const [open, setOpen] =useState(false);
const handleClickOpen = (index) => {
  // setBillRow(data)
  dispatch(setSingleCustomer(customer[index]))
// console.log('loruu',customer[data])

  setOpen(true);
  // setCustomerInfo(data)
};
const adminAddRecep=()=>{
  setAdminDialogOpen(true)
}

const handleClose = () => {
  setOpen(false);
  setAdminDialogOpen(false)

};

const printNClose=()=>{
  handleClose()
  window.print()
}
function apiCall(){
  getCustomers('/restaurant/customers?hour=16').then(
    (result)=>{
     let response=result
response.map(data=>{let d=data.date;
  // let n=new Date()
  // let m=n.split(' ')[2]
  // console.log('check date',m)

  // d=d.split(' ')[1]
  console.log('parse date',d)})
     
    //  setArrayOne(result)
     dispatch(setCustomer(response))
    }
  )
  
}


useEffect(()=>{
  apiCall()
  // setArrayOne(customer)

  const interval = setInterval(() => {
    apiCall()
    // setArrayTwo(customer)

  },10000)
  return ()=>clearInterval(interval)
},[])

// const arrayOne = [ 
//   {name:'mubeen',value:'admin'},
//   {name:'wasy',value:'cashier'},
//   {name:'ali',value:'waiter'},
//   {name:'ahmed',value:'chief'},
//   {name:'junaid',value:'receptionist'}

// ];
          
// const arrayTwo = [ 
//   {name:'mubeen',value:'admin'},
//   {name:'wasy',value:'cashier'},
//   {name:'ali',value:'waiter'},
//   {name:'khan',value:'dekh'}
//   ]
// console.log('list check 1',arrayOne)
// console.log('list check 2',arrayTwo)

// const results = arrayOne.filter(({ customerId: id1 }) => !arrayTwo.some(({ customerId: id2 }) => id2 === id1));

// console.log('Check the value of results',results.length);
// useEffect(
//   ()=>{getCustomers('/restaurant/customers').then(
//     (result)=>{
//      let response=result
//      dispatch(setCustomer(response))

//      const interval = setInterval(() => {
//       getCustomers('/restaurant/customers').then(
//         (result)=>{
//          let response=result
//          dispatch(setCustomer(response))
          
        
//       })
//   }, 3000);
        
//   return () => clearInterval(interval)


//     }
//   )},[]
// )
let role=user.role;
let columns;
{role =='admin'?
columns=[
  { field: 'customerId', headerName: 'Customer ID', width: 150 },
  //{ field: 'customerEmail', headerName: 'Customer Email', width: 150 },
 // { field: 'customerName', headerName: 'Customer Name', width: 150 },
 // { field: 'customerPhone', headerName: 'Customer Phone', width: 150 },
  { field: 'user_id', headerName: 'Floor', width: 150 },
 // { field: 'floorTableId', headerName: 'No. of Customer', width: 150 },
  { field: 'table_number', headerName: 'Table No.', width: 150 },
  { field: 'date', headerName: 'Time in', width: 200},
  {
      field: 'col5',
      headerName: 'Print',
      width: 150,
      renderCell: 
           (params) => (

              //TODO: with href-->    <Btn title='view Order' height='30px' bgColor='' onClick={()=>{handleClick(params.rowIndex)}} href='/kitchen/tableOrder'/>
      
          // <Link to='/kitchen/tableOrder'><Btn title='view Order' height='30px' bgColor='' onClick={()=>handleClickOpen(checkData)}/></Link>
           <Btn title='print' height='30px' width="90px" onClick={()=>{handleClickOpen(params.rowIndex)}} />
   ),
      disableClickEventBubbling: true,
  },
  {field: 'col6', headerName: 'Remove', width: 150,
renderCell:
(params)=>(
  <Delete style={{color:'red'}}/>
)}
]:
columns=[{ field: 'customerId', headerName: 'Customer ID', width: 150 },
//{ field: 'customerEmail', headerName: 'Customer Email', width: 150 },
// { field: 'customerName', headerName: 'Customer Name', width: 150 },
// { field: 'customerPhone', headerName: 'Customer Phone', width: 150 },
{ field: 'user_id', headerName: 'Served By', width: 150 },
// { field: 'floorTableId', headerName: 'No. of Customer', width: 150 },
{ field: 'table_number', headerName: 'Table No.', width: 150 },
//{ field: 'date', headerName: 'Time in', width: 200},
{ field: 'col7', headerName: 'Time in', width: 300 ,
renderCell:(params)=>{
  var orderTime = new Date(params.row.date+' UTC');
  //let theTime=orderTime.getFullYear()+"-"+orderTime.getMonth()+"-"+orderTime.getDate()+" "+orderTime.getHours()+":"+orderTime.getMinutes()+":"+orderTime.getSeconds()
 let theTime=orderTime.getHours()+":"+orderTime.getMinutes()+":"+orderTime.getSeconds()
return(
  <p style={{marginTop:'auto'}}>{theTime}</p>
)
}


},
{
    field: 'col5',
    headerName: 'Print',
    width: 150,
    renderCell: 
         (params) => (

            //TODO: with href-->    <Btn title='view Order' height='30px' bgColor='' onClick={()=>{handleClick(params.rowIndex)}} href='/kitchen/tableOrder'/>
    
        // <Link to='/kitchen/tableOrder'><Btn title='view Order' height='30px' bgColor='' onClick={()=>handleClickOpen(checkData)}/></Link>
         <Btn title='print' height='30px' width="90px"  onClick={()=>{handleClickOpen(params.rowIndex)}} />
 ),
    disableClickEventBubbling: true,
}]
}


console.log("customer: "+customer);
        return(
            <div className='container'>

<PureBreadcrumbs className='onPrintDine'/>
{user.role == 'admin' ? <div style={{ display: 'flex', justifyContent: 'center' }} className='onPrintDine'>

                                <IconButton onClick={adminAddRecep}><MdAdd style={{ fontSize: "42px" }} /></IconButton>

                            </div> : ''}
{customer?
<div style={{ height: 400, width: '100%' }} className={classes.root,'onPrintDine'} id='reception-customer-list-table'>
<DataGrid getRowId={(r) => r.customerId} rows={customer} columns={columns} pageSize={100} 
 />
</div>
:<Spinner />}
<Dialog open={open} onClose={handleClose}  >
        <div className={classes.screen}>
        <img src="https://ab-res.web.app/static/media/header-01.81675d4a.png" className='rec-pop-image'/>
{/*<h1 style={{textAlign:'center',fontWeight:'bold' }}>Ab Restaurant</h1>*/}

{/* Reeption same dialog */}
                                <Divider style={{ borderTop: '10px solid black' }} />
                                <Row>
                                    <Col>
                                        <h2 className="customerPop-style">Customer Id</h2>
                                    </Col>
                                    <Col>
                                        <h2 className="customerPop-style">{customerPop.customerId}</h2>

                                    </Col>
                                </Row>



                                <Row>
                                    <Col>
                                        <h2 className="customerPop-style">Table No.</h2>
                                    </Col>
                                    <Col>
                                        <h2 className="customerPop-style">{customerPop.table_number}</h2>

                                    </Col>
                                </Row>

                               


                                <Row>
                                    <Col>
                                        <h2 className="customerPop-style">Time</h2>
                                    </Col>
                                    <Col>
                                        <h2 className="customerPop-style">{Date(customerPop.date+' UTC')}
                                        </h2></Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <h2 className="customerPop-style">Table Served By</h2>
                                    </Col>
                                    <Col>
                                        <h2 className="customerPop-style">{customerPop.user_id}</h2>
                                    </Col>
                                </Row>
                                <div className='onPrintDine'>
<Btn title='OK' width='60px' height='20px'  onClick={()=>{printNClose()}}/>
</div>
{/* End reception same dialog */}
{/* <DialogTitle id="form-dialog-title" className={classes.screen} >Customer Detail</DialogTitle>
<Divider/>
<DialogContent className={classes.screen,classes.box}>
  <div  style={{display:'flex',justifyContent:'space-between'}}>
    <Para title="Customer Id"/>
    <Para title={customerPop.customerId}/>
  </div>
  <div  style={{display:'flex',justifyContent:'space-between'}}>
    <Para title="Table No"/>
    <Para title={customerPop.table_number}/>
  </div>
  <div  style={{display:'flex',justifyContent:'space-between'}}>
    <Para title="Current Time"/>
    <Para title={customerPop.date}/>
  </div>
  <div  style={{display:'flex',justifyContent:'space-between'}}>
    

    <Para title="Table Served By"/>
    <Para title={customerPop.user_id}/>

  </div>
  <div className='onPrintDine'>
<Btn title='OK' width='60px' height='20px'  onClick={()=>{printNClose()}}/>
</div>
</DialogContent> */}

</div>
</Dialog>

{/* Admin Add Dialog */}
<Dialog open={adminDialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{textAlign:'center'}}>Add New Table</DialogTitle>
        <DialogContent>
         
          <TextField
            autoFocus
            margin="dense"
            id="tableNumber"
            label="Table Number"
            type="text"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="floor"
            label="Floor"
            type="text"
            fullWidth
          />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

{/* End Admin Add Dialog */}
</div>
)

}