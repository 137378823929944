import React from 'react'
import Button from '@material-ui/core/Button'
import './style.css'

function Btn({title,height,bgColor,color,margin,type,width, marginBottom, borderRadius,maxWidth , justifyContent,onClick,className,href,disabled , fontSize}){
    return (
        <div>
<Button  variant="contained"  type={type} className='my-btn' disabled={disabled} style={{marginBottom:marginBottom,maxWidth:maxWidth,height:height,backgroundColor:bgColor,color:color,margin:margin,width:width ,borderRadius:borderRadius,justifyContent:justifyContent ,fontSize:fontSize}} onClick={onClick} href={href} >
                {title} 
            </Button>
                    </div>
    )

}

Btn.defaultProps={
    height:'78px',
bgColor:"#FF0000",
color:'white'
}
export default Btn;