import { SignalCellularNullRounded } from "@material-ui/icons"




export const basketReducer = (state = { basket: [] }, action) => {
    const curentState = action.payload


    switch (action.type) {
        case "SET_BASKET":
            let info = action.payload
            return {
                ...state,
                basket: info
            }


        case "ADD_MENU_DATA":
            let data = action.payload

            console.log('basket payload', data)
            console.log('==================')
            console.log('current state', curentState)

            // if(curentState.unit=0){
            // data=data    
            // }
            // else if(curentState){
            //     data.unit=data.unit+1
            // }
            // return  {...state,
            //     basket:[...state.basket,data]}
            // try new redux
            let mew = state.basket
            const index = state.basket.findIndex(el => el.name === curentState.name);
            let updatedState;
            if (index === -1) {
                updatedState = { ...state, basket: [...state.basket, data] };
                console.log('only one item', updatedState)
            } else {
                // Use map to create a new state object 
                let updatedBasket = state.basket.map((item, i) =>


                    index === i ? { ...item, quantity: item.quantity + 1 } : item
                );
                updatedState = { ...state, basket: updatedBasket }
                console.log('multiple item', updatedState)
                console.log('multiple item basket', updatedBasket)

            }

            return updatedState

            // end test
            break;
        case "SUBTRACT_MENU_DATA":
            const ind = state.basket.findIndex(el => el.name === curentState.name);

            // Use map to create a new state object 
            let updatedBasket = state.basket.map((item, i) =>

                ind === i ? { ...item, quantity: item.quantity - 1 } : item

            );
            updatedState = { ...state, basket: updatedBasket }
            console.log('multiple item', updatedState)
            console.log('multiple item basket', updatedBasket)

            return updatedState


            break;
        case "DELETE_MENU_DATA":
            // let data=action.payload



            // const value=state.basket.filter((a,i)=>a.id !== action.payload.id && i !== action.payload.index );



            let newBasket = [...state.basket];

            newBasket.splice(action.payload, 1);


            // else{
            //  console.warn(`Cant remove product (id: ${data.id} as its not in basket)`)
            // }
            return {
                ...state,
                basket: newBasket
            };

        default:
            return { ...state }
    }
}

export const oldItemReducer = (state = { oldItem: null }, action) => {
    switch (action.type) {
        case "SET_TABLE_BASKET":
            let info = action.payload
            return {
                ...state,
                oldItem: info
            }

        default:
            return { ...state }
    }
}

export const duplicatedWholeBasketReducer = (state = { duplicatedWholeBasket: null }, action) => {
    switch (action.type) {
        case "SET_DUPLICATE_WHOLE_BASKET":
            let dup = action.payload
            return {
                ...state,
                duplicatedWholeBasket: dup
            }

        default:
            return { ...state }
    }
}

export const wholeBasketReducer = (state = { wholeBasket: null }, action) => {
    switch (action.type) {
        case "SET_WHOLE_BASKET":
            let whole = action.payload
            console.log('whole now',action.payload)
            return {
                ...state,
                wholeBasket: whole.orderMenuItem
            }
            case "INCREASE_WHOLE_BASKET":
                let inc =action.payload
                console.log('check is updated',state.wholeBasket)
                return{
                    ...state,
                    wholeBasket:[...state.wholeBasket,inc]
                }
                case "DECREASE_WHOLE_BASKET":
                    let wholeDec=action.payload;
                 let newWholeBasket;  
                   
// var index = state.wholeBasket.findIndex(function(o){
//      return o.menuItemId === wholeDec;

// })
newWholeBasket=state.wholeBasket.splice(state.wholeBasket.findIndex(e => e.menuItemId === wholeDec),1);
console.log('check new whole basket',newWholeBasket);
// console.log('check the index of',index)
// if (index !== -1) { newWholeBasket=state.wholeBasket.splice(index, 1)}
//                     console.log('check is updated',newWholeBasket)
                    return{
                        ...state,
                        
                    }
                    
                    case "REMOVE_WHOLE_BASKET":
                        let remWholeBasket =[...state.wholeBasket]
                 
                        remWholeBasket = remWholeBasket.filter((n) => {return n.menuItemId != action.payload});
                 
                 
                 
                             return {
                                 ...state,
                                 wholeBasket: remWholeBasket
                             }
        default:
            return { ...state }
    }
}

export const itemLengthReducer=(state={itemLength:null},action)=>{
    switch(action.type){
        case "SET_ITEM_LENGTH":
            let len=action.payload
            return{
                ...state,
                itemLength:len

            }

        case "INC_ITEM_LENGTH":
            let wow=action.payload
         let rot=   state.itemLength
         rot[wow]+=1


            return{
                ...state,
                itemLength:rot
            }
default:
    return{
        ...state
    }

        }
}

export const cartReducer = (state = { cart: [] }, action) => {
    switch (action.type) {
        case "ADD_CART":
            let add = action.payload
            return {
                ...state,
                cart: [...state.cart, add]
            }

           
case "POP_CART":

    let    popCart = [...state.cart];
    console.log('remove pay',action.payload)
    
    // remCart = remCart.filter((n) => {return n != action.payload});
    
    const index = popCart.indexOf(action.payload);
    if (index > -1) {
      popCart.splice(index, 1);
    }
    
    
                return {
                    ...state,
                    cart: popCart
                }
    

        case "EMPTY_CART":
            return{
                ...state,
                cart:[]
            }
        case "REMOVE_CART":
       let remCart =[...state.cart]

  remCart = remCart.filter((n) => {return n != action.payload});



            return {
                ...state,
                cart: remCart
            }

            default:
                return{...state}
    }

}