export const tableOrderReducer = (state= {tableOrder:null},action) => {
    switch(action.type) {
        case "SET_ORDER":
            return {...state,tableOrder:action.payload}


        default:
            return {...state}
    }
}


export const currentTableReducer=(state={table:0},action)=>{
    switch(action.type){
        case "SET_TABLE":
            return {...state,table:action.payload}

        default:
            return{...state}
        
    }
}

export const totalTableInfoReducer=(state={totalTableInfo:{id:null,
customerId:null,
floorTableId:null}},action)=>{
    switch(action.type){
        case "SET_TOTAL_TABLE_INFO":
        return {...state,totalTableInfo:action.payload}
    

    default:
        return{...state}
    }
    }
