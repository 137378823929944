import React, { useEffect,useState, useRef } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import './style.css';
import {getBills} from '../../../services/getBills'
import {setBills,currentTable,setBillInfo,setMenuData,categoryItem} from '../../../redux/actions';
import Btn from '../../../component/partials/Button';

import ReceiptIcon from '@material-ui/icons/Receipt';
import {makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { getMenuItem } from '../../../services/getMenuItem';
import Link from '@material-ui/core/Link';
import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';


const useStyles = makeStyles({
  table: {
    '& .table-name-cell': {      
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  TableHead:{
    padding:"10px 0px"
  },
  

});
function QuickSearchToolbar(props) {
  //const classes = useStyles();

  return (
   // <div className={classes.root}>
    <div >
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
       // className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
  
export const CashierBillsTable=()=>{
  //disable the console logs when production environment
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }
  const history=useHistory(); 
  const dispatch=useDispatch();
  const switchOnRef = useRef(false);
  const classes = useStyles();

  const {bill} = useSelector(state=>state.billReducer);
  const {billsInfo} =useSelector(state=>state.billInfoReducer);
  const {menuData}=useSelector(state=>state.menuDataReducer);
  
  const [filterText, setFilterText] = React.useState('progress');
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'orderId',
      sort: 'asc',
    },
  ]);
  const [receivedData, setReceivedData] = React.useState(true);
  const [state, setState] = React.useState(false);
  const[isToggleOn,setIsToggleOn]=useState(false);

const apiCall = ()=>{ 
  var statusParameter='&inProgressStatus=true';
  if(switchOnRef.current)
  {
    statusParameter='';
  }
  getBills('/restaurant/bills?hour=16'+statusParameter).then(
    (result)=>{
      setReceivedData(false)
      dispatch(setBills(result))
     // console.debug('2-bill!',bill);
     // console.debug('2-billInfo!',billInfo);
    }
  ) .catch((error)=>{
    console.log('Bill error',error)
});
}

const filterRows = (searchValue) => {
  setFilterText(searchValue);
};
const billFilterModelChange = (searchValue) => {
  console.debug("searchValue:",searchValue);
  setFilterText(searchValue);
  setMenuRequistInterval(0);
};
let billInfo=[]
const [searchText, setSearchText] = React.useState('');
const [rows, setRows] = React.useState();
const requestSearch = (searchValue) => {
  setSearchText(searchValue);
  const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
  const filteredRows = billInfo.filter((row) => {
    return Object.keys(row).some((field) => {
      return searchRegex.test(row[field].toString());
    });
  });
  setRows(filteredRows);
};
const [menuRequistInterval, setMenuRequistInterval] = React.useState(6000);
useEffect(()=>{
  if(!bill )
  {
    apiCall()
  }else
  {
    setReceivedData(false);
  }
  console.debug('1-bill!',bill);
  console.debug('1-billInfo!',billInfo);
  console.debug('1-billsInfo!',billsInfo);
  console.debug('1-rows!',rows);
  //setRows(billInfo);
  if(menuData.length===0)
  {
    getMenuItem('/restaurant/getmenu').then(
    (result) => {
        let response = result;
        dispatch(setMenuData(response))
        dispatch(categoryItem(response['Mutton']))
      }
    )
  }else
  {
    	console.debug('menu data available!');
  } 
  const interval = setInterval(() => {
    async function fetchData() {
      // You can await here...
      apiCall();
      // ...
    }
    fetchData();
}, menuRequistInterval);

return () => clearInterval(interval)
},[])
let switchBill=[]

let isInfo=false;
let filterTable=[];
const  handleSwitchClick=()=>{
      setIsToggleOn(!isToggleOn)
  }
 
if(bill){
console.log('current bill',bill);

bill.forEach((item,index)=>{
let time =item.orderItemBill.billTime.slice(0,10)
time+=' '+item.orderItemBill.billTime.slice(11,19)
let withoutOrderBill=item.menuitemlistBillList
  let obj={
    "id":index,
    "billId":item.orderItemBill.billId,
    "billStatus":item.orderItemBill.billStatus,
    "billTime":time,
    "waiter":item.orderItemBill.waiter,
    "dinningTable":item.orderItemBill.dinningTable,
    "payment_method":item.orderItemBill.payment_method,
    "orderId":item.orderItemBill.orderId,
    "diningType":item.orderItemBill.diningType
  }
  switchBill.push(item)
  billInfo.push(obj)
  /*if(rows)
  {
    setRows(billInfo);
  }*/
  
})
}
let billLength=billInfo.length
if(billLength>0){
  isInfo=true
}
console.log('bill Info data',billInfo)

const tableDetail=(table)=>{
console.debug('the row',table)
var tableIndex=0;
switchBill.map((item, index)=>{
  console.debug('The item.orderItemBill.billId',item.orderItemBill.billId)
  console.debug('The equals?',item.orderItemBill.billId===table.billId)
  if(item.orderItemBill.billId===table.billId)
  {
    console.debug('The index',index)
    tableIndex=index
  }
})
dispatch(currentTable(tableIndex))
console.debug('billInfo',billInfo)
console.debug('switchBill',switchBill)
dispatch(setBillInfo(switchBill))
history.push('/cashier/tableOrder')


}
const moveFunction=()=>{
  console.log('confirm bilsInfo',billsInfo)
    setTimeout(()=>{
    history.push('/cashier/tableOrder')

    },800)
  
}
console.log('value of Bill reducer are',bill) 



const handleChange = (event) => {  
  if(!event.target.checked)
  {
    setFilterText('progress');
    setIsToggleOn(false);
    switchOnRef.current=false;
    apiCall();
  }
  else{
    setFilterText('');
    setIsToggleOn(true);
    switchOnRef.current=true;
    apiCall();
  }
  console.debug("event.target.checked", event.target.checked)
  setState({ ...state, [event.target.name]: event.target.checked });
  
};

        const columns = [
// {field:'id',headerName:'SNo',width:150}, diningType
          { field: 'billId', headerName: 'Bill #', width: 100 },
          { field: 'orderId', headerName: 'Order #', width: 150 },
          {field:'dinningTable',headerName:'Table',width:150,
         /* valueFormatter: (params) => {
            return params.value==="null"||null?"-":params.value;
          },*/
          cellClassName: 'table-name-cell',         
          renderCell: 
            (params) => {
              return (
                <Link href="#" onClick={()=>{tableDetail(params.row)}} color="inherit">
                  {params.value==="null"||null?"-":params.value}
                </Link>
                )}
           },
          { field: 'billStatus', headerName: 'Status', width: 150 },
          { field: 'diningType', headerName: 'Type', width: 150 },
          { field: 'waiter', headerName: 'Served By', width: 150, 
          valueFormatter: (params) => {
            return params.value==="null"||null?"-":params.value;
          },          
        },
      {field: 'billTime', headerName: 'Time', width: 200,},
       {field: 'payment_method',headerName: 'Payment',width: 150, },
       {field:'detail',headerName:'View Bill',width:150,
       renderCell: 
       (params) => {
         return (
          <Button
          variant="outlined" 
          color="primary"
          size="small"
          className={classes.button}
          startIcon={ <FormatListBulletedIcon />}
          onClick={()=>{tableDetail(params.row)}}
        >
          View
              {/*<Btn title='View Order' height='30px' width='90px' fontSize="9px" bgColor='' onClick={()=>{tableDetail(params.row)}}/>*/}
     
        </Button>
         )},
        disableClickEventBubbling: true,},     
        ]
      console.log('bill info',billInfo)
        return(
<div>
<ReceiptIcon  color="secondary" style={{ fontSize: '40' }}/> Bills Detail      
      <div><FormGroup className='MatToggle'>
           <FormControlLabel
        control={
        <Switch 
        color='primary'
        label="Show all"
        onChange={handleChange}/>
      }
      label="Show all"
      />
      </FormGroup></div>      
      <div style={{ width: '100%'}} id='cashier-bills-table'>     
       {billInfo?<DataGrid 
      autoHeight={true}  
      rows={billInfo} 
      columns={columns} 
      pageSize={100}
      components={{
        Toolbar: QuickSearchToolbar
      }}
      filterModel={{
        items: [
          { columnField: 'billStatus', operatorValue: 'contains', value: filterText },
        ],
        onChange: (event) => filterRows(event.target.value),           
      }}
      onFilterModelChange={(filterModel) => {
       billFilterModelChange(filterModel.filterModel.items[0].value)
      }}
      sortModel={sortModel}
      density="compact"
      loading={receivedData}
      className={classes.table}
      componentsProps={{
        toolbar: {
          value: searchText,
          onChange: (event) => requestSearch(event.target.value),
          clearSearch: () => requestSearch(''),
        },
      }}
      />:<Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>}
      </div>

    {/* </TableContainer> */}
</div>
)
}