export const categoryItemReducer=(state={item:null},action)=>{
    switch(action.type){
        case 'SET_CATEGORY_ITEM':
            return {...state,item:action.payload}

        default:
            return {...state}
    }
}

export const cardItemReducer=(state={card:null},action)=>{
    switch(action.type){
        case 'SET_CARD_ITEM':
            return {...state,card:action.payload}

        default:
            return {...state}
    }
}

export const tableItemReducer=(state={tableItem:[]},action)=>{
    switch(action.type){
        case 'SET_TABLE_ITEM':
            return {...state,tableItem:action.payload}

        default:
            return {...state}
    }
}
export const categoryNameReducer=(state={categoryName:null},action)=>{
    switch(action.type){
        case 'SET_CATEGORY_NAME':
            return {...state,categoryName:action.payload}

        default:
            return {...state}
    }
}