import React, { PureComponent,useState } from 'react';
import ListItemLink from '../component/ListItemLink';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import {setMenuId,categoryItem,setCategoryName,setMenuData,setCardItem} from '../redux/actions'
import {useSelector,useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
// images
import burger from '../assets/menuImages/129.jpg'
import fish_prawn from '../assets/menuImages/160.jpg'
import fries from '../assets/images/Dishes/164.jpg'
import hot_drink from '../assets/images/Dishes/79.jpg'
import salad from '../assets/menuImages/166.jpg'
import prawn from '../assets/images/Dishes/13.jpg'
import biryani from '../assets/menuImages/40.jpg'
import fastFood from '../assets/menuImages/97.jpg'
import chineese from '../assets/menuImages/101.jpg'
import mutton from '../assets/images/Dishes/3.jpg'
import sandwich from '../assets/images/Dishes/125.jpg'
import bread from '../assets/images/Dishes/45.jpg'
import bbq from '../assets/menuImages/49.jpg'
import soup from '../assets/menuImages/90.jpg'
import pakistani from '../assets/images/Dishes/151.jpg'
import sweet from '../assets/menuImages/83.jpg'
import chicken from '../assets/menuImages/15.jpg'
import salan from '../assets/images/Dishes/28.jpg'
import roll from '../assets/menuImages/139.jpg'
import coldDrink from '../assets/images/Dishes/75.jpg'
import vegetable from '../assets/images/Dishes/42.jpg'
import other from '../assets/menuImages/43.jpg'


// extends PureComponent
const useStyles = makeStyles({
    // menu: {

    //     "&:hover": {
    //         color: 'white'
    //       }

    // },
    side:{
        marginLeft:'30px'
        
    }
    

});
function ListItemLinkContainer(menuObj){
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         isOpen: false
    //     }

    // }
    const classes = useStyles();
    const dispatch=useDispatch()
    const {user} = useSelector(state => state.userReducer)
    const history=useHistory()

const {menuId}=useSelector(state=>state.menuIdReducer)
const {item} =useSelector(state=>state.categoryItemReducer)
const {menuData} =useSelector(state=>state.menuDataReducer)
const {card} =useSelector(state=>state.cardItemReducer)

    const [isOpen,setIsOpen]=useState(false)

const check =(id,name)=>{
    console.log('its working again bro',id,name)
}


const checkSidebar=(name)=>{

    /*As Mutton will be the first munu item by default selected we set the ctegory name to it; 
    after each item is selected by uer, the name will be set*/
    dispatch(setCategoryName('Mutton'))  

    if(name=="BURGER"){
        dispatch(categoryItem(menuData['BURGER']))
        dispatch(setCardItem(burger))
        dispatch(setCategoryName(name))
        console.debug('the CategoryName: ',name)
    }
    else if(name=="FRENCH FRIES"){
        dispatch(categoryItem(menuData['FRENCH FRIES']))
        dispatch(setCardItem(fries))
        dispatch(setCategoryName(name))
    }
    else if(name=="FISH & PRAWN"){
        dispatch(categoryItem(menuData['FISH & PRAWN']))
        dispatch(setCardItem(fish_prawn))
        dispatch(setCategoryName(name))
    }
    else if(name=="FRESH SALAD"){
        dispatch(categoryItem(menuData['FRESH SALAD']))
        dispatch(setCardItem(salad))
        dispatch(setCategoryName(name))
    }
    else if(name=="Hot Drink"){
        dispatch(categoryItem(menuData['Hot Drink']))
        dispatch(setCardItem(hot_drink))
        dispatch(setCategoryName(name))
    }
    else if(name=="Biryani"){
        dispatch(categoryItem(menuData['Biryani']))
        // dispatch(setCardItem(menuData['Biryani']))
        dispatch(setCardItem(biryani))
        dispatch(setCategoryName(name))

    }
    else if(name=="Fast Food"){
        dispatch(categoryItem(menuData['Fast Food']))
        // dispatch(setCardItem(menuData['Fast Food']))
        dispatch(setCardItem(fastFood))
        dispatch(setCategoryName(name))

    }
    else if(name=="Prawn"){
        dispatch(categoryItem(menuData['Prawn']))
        // dispatch(setCardItem(menuData['Prawn']))
        dispatch(setCardItem(prawn))
        dispatch(setCategoryName(name))

    }
    else if(name=="CHINESE"){
        dispatch(categoryItem(menuData['CHINESE']))
        dispatch(setCardItem(chineese))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['CHINESE']))
    }
    else if(name=="Mutton"){
        dispatch(categoryItem(menuData['Mutton']))
        dispatch(setCardItem(mutton))
        dispatch(setCategoryName(name))
        console.debug('the CategoryName: ',name)
        // dispatch(setCardItem(menuData['Mutton']))
    }
    else if(name=="SANDWICH"){
        dispatch(categoryItem(menuData['SANDWICH']))
        dispatch(setCardItem(sandwich))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['SANDWICH']))
    }
    else if(name=="Bread"){
        dispatch(categoryItem(menuData['Bread']))
        dispatch(setCardItem(bread))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['Bread']))
    }
    else if(name=="BBQ"){

        dispatch(categoryItem(menuData['BBQ']))
        dispatch(setCardItem(bbq))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['BBQ']))
    }
    else if(name=="Soup"){
        dispatch(categoryItem(menuData['Soup']))
        dispatch(setCardItem(soup))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['Soup']))
    }
    else if(name=="PAKISTANI DASHES"){
        dispatch(categoryItem(menuData['PAKISTANI DASHES']))
        dispatch(setCardItem(pakistani))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['PAKISTANI DASHES']))
    }
    else if(name=="Sweet"){
        dispatch(categoryItem(menuData['Sweet']))
        dispatch(setCardItem(sweet))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['Sweet']))
    }
    else if(name=="Chicken"){
        dispatch(categoryItem(menuData['Chicken']))
        dispatch(setCardItem(chicken))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['Chicken']))
    }
    else if(name=="Salaan"){
        dispatch(categoryItem(menuData['Salaan']))
        dispatch(setCardItem(salan))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['Salaan']))
    }
    else if(name=="ROLL"){
        dispatch(categoryItem(menuData['ROLL']))
        dispatch(setCardItem(roll))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['ROLL']))
    }
    else if(name=="Cold Drink"){
        dispatch(categoryItem(menuData['Cold Drink']))
        dispatch(setCardItem(coldDrink))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['Cold Drink']))
    }
    else if(name=="Vegetable"){
        dispatch(categoryItem(menuData['Vegetable']))
        dispatch(setCardItem(vegetable))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['Vegetable']))
    }
    else if(name=="Other"){
        dispatch(categoryItem(menuData['Other']))
        dispatch(setCardItem(other))
        dispatch(setCategoryName(name))

        // dispatch(setCardItem(menuData['Other']))
    }
    else if(name=="Continental"){
        dispatch(categoryItem(menuData[name]))
       // dispatch(setCardItem(other))
        dispatch(setCategoryName(name))
    }
    else if(name=="Tandoori"){
        dispatch(categoryItem(menuData[name]))
       // dispatch(setCardItem(other))
        dispatch(setCategoryName(name))
    }
    else if(name=="Starter"){
        dispatch(categoryItem(menuData[name]))
       // dispatch(setCardItem(other))
        dispatch(setCategoryName(name))
    }
    else if(name=="Handi"){
        dispatch(categoryItem(menuData[name]))
       // dispatch(setCardItem(other))
        dispatch(setCategoryName(name))
    }
    else if(name=="Arabic Plater"){
        dispatch(categoryItem(menuData[name]))
       // dispatch(setCardItem(other))
        dispatch(setCategoryName(name))
    }
    else if(name=="Shakes & Smoothies"){
        dispatch(categoryItem(menuData[name]))
       // dispatch(setCardItem(other))
        dispatch(setCategoryName(name))
    }

    else{
        console.log('pakistan')
    }
}


    const handleClick = (id,name) => {
        // this.setState({
        //     isOpen: !this.state.isOpen
        // }
        // )
        setIsOpen(!isOpen)
        dispatch(setMenuId(name))
        
console.log('redux id and name',id ,name)
    }


    const getDrawerInfo=(id,name)=>{

        if(user.role == 'waiter' || 'cashier' || 'kitchen' ||  'cheif' || 'adminwaiter'){
dispatch(setMenuId(name))
console.log('drawer click info',name)
if(name=='Logout'){
    localStorage.removeItem('user')
    history.push('/')
}

checkSidebar(name)
        }
    }
//console.debug('check real time value of item ',item)
//console.log('check real time value of item ',card)

return (
            <React.Fragment>
                {menuObj.menuObj.submenu.length > 0 ?
                    (
                        <ListItemLink className={classes.menu}
                            key={menuObj.menuObj.id}
                            to={menuObj.menuObj.path}
                            menuText={menuObj.menuObj.menuName}
                            icon={menuObj.menuObj.icon}
                            open={isOpen}
                            onClick={()=>handleClick(menuObj.menuObj.id,menuObj.menuObj.menuName)} />
                    )
                    :
                    (
                        <ListItemLink className={classes.menu}
                            key={menuObj.menuObj.id}
                            to={menuObj.menuObj.path}
                            menuText={menuObj.menuObj.menuName}
                            icon={menuObj.menuObj.icon}
                            onClick={()=>getDrawerInfo(menuObj.menuObj.id,menuObj.menuObj.menuName)}
                             />
                            //  onClick={()=>check(menuObj.id,menuObj.menuName)}
                    )
                }

                <Collapse component="li" in={isOpen} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        {menuObj.menuObj.submenu.map((sub, index) => (
                            <ListItemLink

                                key={sub.id}
                                to={sub.path}
                                menuText={sub.menuName}
                                icon={sub.icon}
                                className={classes.nested,classes.menu,classes.side} />
                        ))}
                    </List>
                </Collapse>
            </React.Fragment>
        )
    // }

}

export default ListItemLinkContainer;
