import React from 'react'
import {RecepDinning} from '../../pages/Layout/reception/dinningArea'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {Orders} from '../../pages/Layout/waiter/orders'
import {CustomerList} from '../../pages/Layout/reception/CustomerList'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {NewOrders} from '../../pages/Layout/kitchen/NewOrders'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import {Menu,DinningArea,Bills,Logout,OrdersIcon , Customer,Cooking} from '../../component/partials/CustomDasboarIcon'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupIcon from '@material-ui/icons/Group';
import ListAltIcon from '@material-ui/icons/ListAlt';

export const AdminMenu=[
    {
        id:1,
        menuName: 'Menu',
        menuDescription: 'Menu',
        path: '/admin/menu',
        component: RecepDinning,
        exact: true,
        icon :<Menu />,
        submenu:[]
    },
    {
        id:2,
        menuName: 'Dining Area',
        menuDescription: 'Tables',
        path: '/admin',
        component: RecepDinning,
        exact: true,
        icon :<DinningArea />,
        submenu:[]
    },
    {
        id:3,
        menuName: 'Customer List',
        menuDescription: 'customer List',
        path: '/admin/customerList',
        component: CustomerList,
        exact: true,
        icon :<Customer />,
        submenu:[]
    },
    {
        id:4,
        menuName: 'Orders',
        menuDescription: 'Orders',
        path: '/admin/orders',
        component: Orders,
        exact: true,
        icon :<OrdersIcon />,
        submenu:[]
    },
  
    ,
    {
        id:5,
        menuName: 'Kitchen Orders',
        menuDescription: 'kitchen',
        path: '/admin/kitchen',
        component: NewOrders,
        exact: true,
        icon :<Cooking />,
        submenu:[]
    },
    {
        id:6,
        menuName: 'Bills',
        menuDescription: 'Bills',
        path: '/admin/bills',
        component: RecepDinning,
        exact: true,
        icon :<Bills />,
        submenu:[]
    },
    {
        id:7,
        menuName: 'Add user',
        menuDescription: 'Add user',
        path: '/admin/add-user',
        component: RecepDinning,
        exact: true,
        icon :<PersonAddIcon />,
        submenu:[]
    },
    {
        id:8,
        menuName: 'Users detail',
        menuDescription: 'Users detail',
        path: '/admin/users-detail',
        component: RecepDinning,
        exact: true,
        icon :<GroupIcon />,
        submenu:[]
    },
    {
        id:9,
        menuName: 'Sales overview',
        menuDescription: 'Sales overview',
        path: '/admin/sales-overview',
        component: RecepDinning,
        exact: true,
        icon :<ListAltIcon />,
        submenu:[]
    },
    {
        id:9,
        menuName: 'Sell Daetails',
        menuDescription: 'Sell Daetails',
        path: '/admin/sell-overview',
        component: RecepDinning,
        exact: true,
        icon :<ListAltIcon />,
        submenu:[]
    },
    {
        id:10,
        menuName: 'Logout',
        menuDescription: 'Logout',
        path: '/',
        component: RecepDinning,
        exact: true,
        icon :<Logout />,
        submenu:[]
    }
    
]