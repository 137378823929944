import React, { Component } from 'react'
import profile from '../../../assets/images/profile/pfp.png'
import Para from '../../../component/partials/Para'
import Heading from '../../../component/partials/Heading'
import Avatar from '@material-ui/core/Avatar';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import  {DataGrid}  from '@material-ui/data-grid';
import './style.css';
export const storeDashboard = () => {
    /*---------disable the console logs when production environment--------*/

  
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
      ];
    
      const columns = [
          
          { field: 'id', headerName: 'Product Id', width: 150, headerClassName:'invoice-table-head'},
        {
          field: 'firstName',
          headerName: 'Products',
          headerClassName:'invoice-table-head',
          width: 150,
          editable: true,
        },
        {
          field: 'lastName',
          headerName: 'Quantity',
          headerClassName:'invoice-table-head',
          width: 150,
          editable: true,
        },
        {
          field: 'age',
          headerName: 'Quality',
          type: 'number',
          width: 110,
          headerClassName:'invoice-table-head',
          editable: true,
        },
        {
          field: 'fullName',
          headerName: 'Full name',
          headerClassName:'invoice-table-head',
          sortable: false,
          description: 'This column has a value getter and is not sortable.',
          width: 160,
          valueGetter: (params) =>
            `${params.getValue(params.id, 'firstName') || ''} ${
              params.getValue(params.id, 'lastName') || ''
            }`,
        },
      ];
    return (
        <div>

            <div className='storeDash-upper'>

                <div className='storeDash-upper-left'>
                    {/* <img src={profile} alt=''/> */}
                    {/*<Avatar alt="Remy Sharp" src={profile} style={{height:'auto',width:'auto'}}/>*/}
                    <WarehouseIcon sx={{ fontSize: 66 }}/>
                </div>

                <div className='storeDash-upper-right'>
                    <Heading title='Welcome to inventory' />
                    <Heading title='Anwar Baloch' color='red' />
                    <br />
                    <Para title='Address: Near Malir Bridge, Malir Karachi' fontSize='12px' />
     
                    <div className='storeDash-upper-right-bottom'>

                        <div>
                            <Para title='email' fontSize='13px' />
                        </div>

                        <div>
                            <Para title='Phone: 021-34500919' fontSize='13px' />
                        </div>

                        <div style={{ display: 'flex' }}>
                        Current Time : --:--
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}