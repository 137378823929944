import React,{useState} from 'react'
import Para from '../../../component/partials/Para'
import Btn from '../../../component/partials/Button'
import {useHistory} from 'react-router-dom'
import './style.css'
import { tableOrdersData } from './data'
import {useSelector} from 'react-redux'
import {postTableOrder} from '../../../services/postTableOrder'
import {setPostOrder} from '../../../redux/actions'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';






export const KitchenNewTableOrder = () => {
      /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }
    const[snackOpen,setSnackOpen]=useState(false)
    const {tableItem}=useSelector(state=>state.tableItemReducer)
    const {order}=useSelector(state=>state.kitchenOrderReducer)
    const [okButton,setOkButton]=useState(false)
    const [submitButtonColor,setSubmitButtonColor]=useState('#ff0000')
    const {postOrder}=useSelector(state=>state.postOrderReducer)
const history=useHistory()
    // const dispatch=useDispatch()
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
      const snackHandleClose=(event, reason)=>{
        if (reason === 'clickaway') {
          return;
        }
      
        setSnackOpen(false);
      }
      let tableData;
// let tableData=tableItem[0].orderMenuItem
let pendData= tableItem[0].orderMenuItem.filter(item=>item.orderItemStatus == 'pending' )
console.log('here your table data initial',tableData)

 tableData = [...new Map(pendData.map(item => [item.menuItemId, item])).values()]
//    tableData= tableData.filter(item=>item.orderItemStatus == 'pending' )
console.log('table data only pending',tableData)

let itemIdLength=[]
console.log('table data item',tableItem)

console.log('table data new',tableData)
const handleDone=(item)=>{
    setOkButton(true)
    setSubmitButtonColor('gray')
history.push('/kitchen')
// let db=tableItem.forEach(element => {
//   "orderItemId": null,
// "orderId":105,
// "menuItemId":163,
// "orderItemStatus":"add"
// });

console.log('table item',tableItem)
let pay=[]
// let done=[]
pendData.forEach(item=>{
    let obj={
        "orderItemId": item.orderItemId,
"orderId":item.orderId,
"menuItemId":item.menuItemId,
"orderItemStatus":"done"
    }

   pay.push(obj) 
} )

console.log('blaaaaaaaaaaa',pay)
      postTableOrder('/restaurant/customer-items',pay,'PUT').then(
        (result)=>{
          let response=result
        }
      )    
      setSnackOpen(true)
      console.log('working')
}
const[localtime,setLocalTime]=useState(false)

function getLocalTime(date) {
    console.debug("date",date);
   // var localDate = new Date(date).toLocaleString('en-GB', { timeZone: 'Asia/Karachi' })
    var localDate = new Date(date)
    var anotherdate = new Date(localDate+'UTC')
   // var localDate = new Date(date);
    console.debug("localDate",localDate);
    console.debug("anotherdate",anotherdate);
    return anotherdate.toLocaleString('en-GB');
  }
    const RenderItem = (tableOrderData, index) => {
        let itemId;
        let reet =tableOrderData.id?tableOrderData.id:tableOrderData.menuItemId
        console.log('reet id',reet)
        itemId=pendData.filter((item)=>item.menuItemId === reet)
        console.log('item id reet',itemId)
        
             itemIdLength.push(itemId.length)
        return (
               

            <tr key={index}>
                <td>{itemIdLength[index]}</td>
                {/* <td>{tableOrderData.menuItemId}</td> */}
                <td>{tableOrderData.name}</td>
                <td>{tableOrderData.categoryName}</td>
            </tr>
        )
    }
    return (
        <div >

            <div className='kitchen-table-order-nav' >
                <div style={{fontSize:'26px', fontWeight:'bolder'}}>
                    <Para title='Table Order' />
                </div>
                <div>
                    <Btn height='30px' width="90px" fontSize="13px" title='Print' onClick={()=>{window.print()}} />
                </div>
            </div>
            <div className='hideNShowOnPirnt'>
               <img src="https://ab-res.web.app/static/media/header-01.81675d4a.png" style={{height:'220px', display:'block', marginLeft:'auto', marginRight:'auto'}}/>
{/*<h1 style={{textAlign:'center',fontWeight:'bold' }}>Ab Restaurant</h1>*/ }
<h2 style={{textAlign:'center' ,fontWeight:'bold'}}>{getLocalTime(tableItem[0].date)}</h2> 
</div>
<div className='w-100' >

<h3 style={{textAlign:'center', fontWeight:'bold' }}>Order Id: {tableItem[0].id}</h3>
<h3 style={{textAlign:'center', fontWeight:'bold' }}>Customer Id: {tableItem[0].customerId}</h3>
<h1 style={{textAlign:'center',fontWeight:'bolder'}}>{tableItem[0].floorTableName==null?tableItem[0].dining_type: "Table Number: "+tableItem[0].floorTableName}</h1>
 </div>
 <br/>
<br/>
            <div className='printStyles kitchen-order-table ' style={{  boxShadow: "5px 10px 30px #888888"}}>
                <table className='w-100'>
                    <thead className='kitchen-table-head' style={{fontSize:"10px"}}>
                    <td>QTY.</td>
                        {/* <td>Menu Id</td> */}
                        <td>ITEM</td>
                        <td>CATEGORY</td>
                    </thead>
                    <tbody>

                        {
                        
                        tableData.map(RenderItem)}
                  
                    </tbody>
                </table>
<div className='newItemBtn' style={{width:"100%" ,display: 'flex', justifyContent: 'center' , marginBottom:"15px"}}>
<Btn title='Done' marginBottom="30px" width="250px" borderRadius='5px' height="50px" margin='15px 0 30' bgColor={submitButtonColor} disabled={okButton} onClick={()=>handleDone(tableItem)}/>

</div>
            </div>
            <Snackbar open={snackOpen} autoHideDuration={6000} onClose={snackHandleClose}>
        <Alert onClose={snackHandleClose} severity="success">
          Successfully submit the order!
        </Alert>
      </Snackbar>
        </div >

    )
}