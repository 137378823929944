import React,{useEffect,useState} from 'react'
// import { createBrowserHistory} from 'history'
// import {useLocation} from 'react-router-dom'
//import { Component } from 'react'
//import profilePicture from '../../../assets/images/profile/pfp.png';
//import { makeStyles } from '@material-ui/core/styles';
//import List from '@material-ui/core/List';
//import ListItem from '@material-ui/core/ListItem';
//import ListItemText from '@material-ui/core/ListItemText';
//import Divider from '@material-ui/core/Divider';
//import Paper from '@material-ui/core/Paper';
//import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setDineTable, setTableStatus, setTableOrder, setTableMenu,setMenuData,setWaitersInfo,setMenuId,setOrderId,categoryItem } from '../redux/actions';
import {getData} from '../services/getData';
import NoImage from '../assets/images/no-image.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Spinner } from '../component/partials/Spinner'



const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: [12, "!important"],
        fontWeight:"bold",
        // lineHeight:"5px"
      }
    }
  }
});
export function ImageCards({image,id,price,category,name,maxHeight,height,data,index} ){
  const {menuData}=useSelector(state=>state.menuDataReducer);
  const [loading, setLoading] = React.useState(false);
  //console.debug("the Image menuData[category][index]",menuData[category][index])
  //console.debug("the Image category",category)
  //console.debug("the Image datay name",data.name)
  //console.debug("the Image data path",data.path===null)
 const [itemImage,setItemImage]=useState()
 const [values, setValues] = React.useState([{
  id: '',
  image: ''
}]);
const dispatch=useDispatch()
console.debug("------------"+data.id+"------------")
 useEffect(()=>{
  setLoading(true);
  if(data.path===null)
  {getData('/restaurant/menu-item-image/'+data.id).then(
    (result) => {
        let response=result;

        setItemImage('data:image/png;base64,'+response)
        //console.debug("the image to be-->",'data:image/png;base64,'+response);
        menuData[category][index].path='data:image/png;base64,'+response       
        dispatch(setMenuData(menuData)) 
        console.debug("2-the Image menuData[category][index]",menuData[category][index])
        setLoading(false);
      }).catch(error=>{
        console.error("ImageCards; error: ",error);
     });
    }else{
      console.debug("the Image data path is not NULL!");
      setLoading(false);
    }
 },[menuData[category][index]])


      return(
        // <>
         <div className="table-Cards" style={{width:'200px',maxHeight:maxHeight ,height:height}  } >
        
         <Card style={{width:'200px',maxHeight:maxHeight ,height:height}  }>
        <CardActionArea>
          {data.path?<CardMedia
            image={menuData[category][index].path==='data:image/png;base64,'?NoImage:menuData[category][index].path}
             title={data.name+'-'+data.id}
          />:<Spinner theWidth='200px' theHight='140px' theTilte='Aks pedaken..'/>}
        <CardContent className='imagecards-contents' >
        
        <ThemeProvider theme={theme} >
      <div className="App">
          <Typography gutterBottom variant="body2" className='image-card-title' >
               {name}
          </Typography>
      </div>      
    </ThemeProvider>
    <Typography color="textSecondary" variant="caption" >
          id: {data.id}
          </Typography>
          <Typography color="textSecondary" variant="body2" >
          {category}
         {/*<p className="image-card-typoo" style={{fontSize:"13px"}}> {category}</p>*/}
          </Typography>
          {/* <Typography >
             <p className="image-card-typoo" style={{fontSize:"13px"}}>Item number {id}</p>
          </Typography> */}
          <Typography color="textSecondary" variant="body2">
          Rs {price}
              {/*<p className="image-card-typoo" style={{fontSize:"13px"}}> Rs {price}</p>*/}
          </Typography> 
        </CardContent>
      </CardActionArea>     
    </Card>
    </div>    
// </>
      );
  }
  
