import React from 'react'
// import Button from '@material-ui/core/Button'
import './style.css'

function Para({title,color,textAlign,fontSize,font,style,fontWeight,margin}){
    return (
        <div>
{/* className='para-sign' */}
<p style={{color:color,textAlign:textAlign,fontSize:fontSize,fontWeight:fontWeight,margin:margin}} className='para-sign' >{title}</p>

</div>
    )


}
Para.defauftProps = {
    color:"black",
// textAlign:'center'
     fontSize:'10px'
}
export default Para;