import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { Row, Col } from 'react-bootstrap'
//import './reception.css'
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { postData, putData } from '../../../services/getData';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DataGrid } from '@material-ui/data-grid';
//import {Delete} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
//import { MdAdd } from 'react-icons/md'
import { Avatar, IconButton } from '@material-ui/core'
import Btn from '../../../component/partials/Button';
import { setCustomer } from '../../../redux/actions';
import { getCustomers } from '../../../services/getCustomerList';
import PureBreadcrumbs from '../../../component/Breadcrumb';
import { Spinner } from '../../../component/partials/Spinner';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getUsers } from '../../../services/getUsers';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import InputAdornment from '@material-ui/core/InputAdornment';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import EditIcon from '@material-ui/icons/Edit';
import { MdAdd } from 'react-icons/md';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {GridToolbar } from "@material-ui/data-grid";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& .tr-table-order-new': {
      backgroundColor: 'rgba(255, 179, 0, 0.98)',
    },
    '& .tr-table-order-completed': {
      backgroundColor: '#fe0101',
    },
    '& .tr-table-order-in-progress': {
      backgroundColor: '#86d689',
    },
  },
  box: {

    minWidth: '500px',
    '@media print': {
      minWidth: '600px'
    }

  },
  screen: {
    padding: '0px',
    textAlign: 'center',
    '@media print': {
      textAlign: 'left'
    }
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function BillTableToolBar() {
    /*---------disable the console logs when production environment--------*/
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
  return (
    <div>
      
      <GridToolbar />
    {/*<GridToolbarFilterButton />
    <GridToolbarDensitySelector />
    <GridToolbarExport />*/}
  </div>
  );
}

export const Invoices = () => {
  const { customer } = useSelector(state => state.customerReducer)
  const { user } = useSelector(state => state.userReducer)
  const [open, setOpen] = React.useState(false);
  const [openAddMenu, setOpenAddMenu] = React.useState(false);
  const [openAddItemDialog, setOpenAddItemDialog] = React.useState(false);
  //const [existingQty, setExistingQty] = React.useState(0);
  const [allWaiters, setAllWaiters] = React.useState([])
  const [allSuppliers, setAllSuppliers] = React.useState([]);
  const [selectedSuplierName, setSelectedSuplierName] = React.useState('');
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('loading....');
  const [receivedData, setReceivedData] = React.useState(true);
  const [item, setItem] = React.useState('');
  const [openn, setOpenn] = React.useState(false);
  const [productOutOpen, setProductOutOpen] = React.useState(false);
  const [stockError, setStockError] = React.useState(false);
  const [isSubmitDisabled, setSubmitDisabled] = React.useState(true);
  const [allProducts, setAllProducts] = React.useState([]);
  const [values, setValues] = React.useState({
    id: '',
    quantity: '',
    quantity_type: '',
    name: '',
    price: '',
    supplier_id: '',
  });
  const [invoiceValues, setInvoiceValues] = React.useState({
      //id: '',
      supplier_id: '',
       receiver_id: user.id,
       invoice_number: '',
       invoice_status: ''
  });
  const [puchaseValues, setPuchaseValues] = React.useState({
    
      "purchaseOrderId": 0,
      "productId": 0,
      "quantity": 0,
     // "quantityType": '',
      "name": '',
      "price": 0,
      "supplierId": 0,
      "supplierName": '',
      "entryDate": ''
});
const [selectedQtyType, setSetselectedQtyType] = React.useState("");
  const [dialogType, setDialogType] = React.useState("add");
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'invoiceDate',
      sort: 'desc',
    },
  ]);
  
  const useStyles = makeStyles((theme) => ({

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));

  

  const handleChangee = (event) => {
    setItem(event.target.value);
  };
  const handleClosee = () => {
    setOpenn(false);
  };
  const handleOpenn = () => {
    setOpenn(true);
  };

 //popu//
 const addMenuClickk = () => {
  //setEditCateg(get_icon_Key(menuData,data))   
  console.debug('from add popup open')
  //setBackdropOpen(true);
  setBackdropOpen(true);
  //setOpen(true);
  setOpenAddMenu(true);

}



const addProduct = () => {
  let rend = {
     // "id": values.id,
     "name":values.name,
      "quantity_type": values.quantity_type,
      "quantity": 0
  }
  //if (values.name, values.quantity_type) {
    postData('/restaurant/purchase', invoiceValues).then(
          (result) => {
              if(result)
              {
                  setSeverity('success');
                  setAlertMessage('Product is added, system created id is: '+result)
                  apiCall();
                  setOpenAddMenu(false);
                  setOpen(true);
              }
              else{
                  setSeverity('error');
                  setAlertMessage(result.message);
                  setOpenAddMenu(false);
                  setOpen(true);
              }
              console.log('add Product status')
              
          }            
      )
      console.debug("form: "+rend) 
}
const updateInvoice = () => {
  let rend = {
     "name":values.name,
      "quantity_type": values.quantity_type,
      "quantity": 0
  }
  putData('/restaurant/purchase/'+invoiceValues.id, invoiceValues).then(
          (result) => {
              if(result)
              {
                  setSeverity('success');
                  setAlertMessage('Product is added, system created id is: '+result.id)
                  apiCall();
                  setOpenAddMenu(false);
                  setOpen(true);
              }
              else{
                  setSeverity('error');
                  setAlertMessage(result.message);
                  setOpenAddMenu(false);
                  setOpen(true);
              }
              console.log('add Product status')
              
          }            
      )
      console.debug("form: "+rend) 
}

const addPurchaseOrderItem = () => {
  console.debug("puchaseValues: ",puchaseValues);
  postData('/restaurant/purchase-items', puchaseValues).then(
        (result) => {
            if(result)
            {
                setSeverity('success');
                setAlertMessage('Product is added, system created id is: '+result)
                //getProduct();
                setOpenAddMenu(false);
                setOpen(true);
                setOpenAddItemDialog(false);
            }
            else{
                setSeverity('error');
                setAlertMessage(result.message);
                setOpenAddMenu(false);
                setOpen(true);
            }
            console.log('add Product status')
            
        }            
    )
}

// end Popu//  
  const dispatch = useDispatch()
  const classes = useStyles();



  function apiCall() {
    getCustomers('/restaurant/purchases').then(
      (result) => {
        let response = result
        dispatch(setCustomer(response));
        setReceivedData(false);
      }
    )
    getUsers('/restaurant/product').then(
      (result) => {
        let resp = result
        setAllProducts(resp)
      }
    )
  }
  useEffect(() => {
    apiCall();
  }, [])
 
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setInvoiceValues({ ...invoiceValues, [prop]: event.target.value });
    setPuchaseValues({ ...puchaseValues, [prop]: event.target.value });
     allProducts.forEach(datai =>{
     if(datai.id===event.target.value)
     {
      puchaseValues.quantityType=datai.quantity_type;
      setSetselectedQtyType(datai.quantity_type);
     }
     
    });

    //console.debug(prop + " : " + event.target.value, event.target.quantity_type);
  };
  
  const handleClickOpen = (rowdata, clickType) => {
    setDialogType(clickType)
    if(clickType==='add')
    {
      /*    private Integer id;

    private int supplier_id;
    private int receiver_id;
    private int invoice_number;
    private String invoice_status;
     */
      setInvoiceValues({
        supplier_id: '',
        receiver_id: user.id,
        invoice_number: '',
        invoice_status: ''
      });
    }
    if(rowdata)
    {
      /*{
      private String supplierName;
      private String receiverName;
      private int invoiceNumber;
      private String invoiceStatus;
      private Date invoiceDate;
      }*/
      invoiceValues.invoice_number=rowdata.invoiceNumber;
      invoiceValues.invoice_status=rowdata.invoiceStatus;
      invoiceValues.supplier_id=rowdata.supplierId;
      invoiceValues.id=rowdata.id;
      setSelectedSuplierName(rowdata.supplierName);
      // setInvoiceValues(rowdata)
      //setExistingQty(rowdata.quantity)
    }

    if(clickType==='decrease')
    {
      setProductOutOpen(true)
    }else
    {
      setOpenAddMenu(true);
    }
      // setOpen(true);
        /**  //setEditCateg(get_icon_Key(menuData,data))   
        console.debug('from add popup open')
        //setBackdropOpen(true);
        setBackdropOpen(true);
        //setOpen(true);
        setOpenAddMenu(true); */
    };

  const handleClickOpenItem = (rowdata, clickType) => {
    setDialogType(clickType)
    puchaseValues.supplierName=rowdata.supplierName;
    puchaseValues.purchaseOrderId=rowdata.id;
    puchaseValues.invoiceNumber=rowdata.invoiceNumber;
    puchaseValues.supplierId=rowdata.supplierId;
    puchaseValues.quantityType=selectedQtyType;
    puchaseValues.purchase_order_id=rowdata.id
    
   /*"purchaseOrderId": 108,
"productId": 18,
"quantity": 100,
"quantityType": "piece",
"name": "Eggs",
"price": 8,
"supplierId": 52,
"supplierName": "Meat Supplier",
"entryDate": "2021-05-20T19:00:00.000+00:00"
----
 id: 58
invoiceDate: "2021-05-09T19:00:00.000+00:00"
invoiceNumber: 22
invoiceStatus: "paid"
receiverName: "storekeeper"
supplierId: 52
supplierName: "Meat Supplier"*/
    setOpenAddItemDialog(true);    
  };
  const handleClickSupplier = (value) => {
    console.debug("value: ",value);
   /* invoiceValues.supplier_id=id*/
    setSelectedSuplierName(value.name);
  }
  const handleClose = () => {
    setOpen(false);
    setProductOutOpen(false);
    setStockError(false);
  };

  useEffect(() => {
    getUsers('/restaurant/users').then(
      (result) => {
        let resp = result
        setAllWaiters(resp)
      }
    )
  }, [])
  useEffect(() => {
    getUsers('/restaurant/suppliers').then(
      (result) => {
        let resp = result
        setAllSuppliers(resp)
      }
    )
  }, [])

  const postnDeleteMenu = () => {
    var url = null;
   // var newQty = parseInt(existingQty);
    if (dialogType === "add") {
      addProduct();
    }  
    else{
      updateInvoice();
    }
  }


  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model);
    console.debug("actionType???");
  }, []);
  const handleCellEditCommit = React.useCallback(
    () => {
      console.debug("2-actionType???");
    },
    [],
  );
  console.debug("editRowsModel: " + editRowsModel);
  // const [editProduct, setEditProduct] = React.useState(false);
 
  let role = user.role;
  let columns;

  {
      columns = [
        { field: 'id', headerName: 'ID #', width: 100,hide: true  },
        { field: 'invoiceNumber', headerName: 'Invoice #', width: 120 },
        { field: 'invoiceStatus', headerName: 'Status', width: 150 },        
        { field: 'supplierName', headerName: 'Supplier', width: 200 },
        { field: 'supplierId', headerName: 'Supplier #', width: 150 ,hide: true },
        { field: 'receiverName', headerName: 'Receiver', width: 150 },
        { field: 'invoiceDate', headerName: 'Date', width: 150 },
        {
          field: 'invEdit', headerName: 'Edit', width: 120,
          renderCell:
            (params) => {
              return (
                <IconButton aria-label="increase" >
                  <EditIcon color="secondary" onClick={() => { handleClickOpen(params.row, "edit") }} />
                </IconButton>
              )
            },
          disableClickEventBubbling: true,
        },
        {
          field: 'invAdd', headerName: 'Add Items', width: 120,
          renderCell:
            (params) => {
              return (
                <IconButton aria-label="increase" color="primary" >
                  <PlaylistAddIcon  onClick={() => { handleClickOpenItem(params.row, "addItem") }} />
                </IconButton>
              )
            },
          disableClickEventBubbling: true,
        },
      ] 
  }
  //console.log("customer: "+customer);
  return (
    <div className='container' style={{ maxWidth: '100%' }}>

      <PureBreadcrumbs className='onPrintDine' />

      {/*---- Main table----*/}
      {customer ?
        <div style={{ width: '100%' }} className={classes.root, 'onPrintDine'} id='product-details-list-table'>
          <DataGrid 
          rows={customer} 
          columns={columns} 
          pageSize={100} 
          autoHeight='true'
            editRowsModel={editRowsModel}
            onEditRowsModelChange={handleEditRowsModelChange}
            onCellEditCommit={handleCellEditCommit}
            sortModel={sortModel}
            density="compact"
            loading={receivedData}
            components={{
              Toolbar: BillTableToolBar
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='onPrintDine'>

            <IconButton onClick={() => { handleClickOpen(null, "add") }}><MdAdd style={{ fontSize: "42px" }} /></IconButton>

          </div>

        </div>
        : <Spinner />}

{/* <Eidt/Add Product popu> */}
      <Dialog open={openAddMenu} onClose={()=>setOpenAddMenu(false)} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
        <DialogTitle id="product-details-form-dialog-title">
        {
            dialogType === "edit" ? "Edit Invoice" : 'Add Invoice'
          }
        </DialogTitle>
        <DialogContent>
 
        <TextField
          autoFocus
          margin="dense"
          id="tf-invoice_number"
          label="Invoice no."
          value={invoiceValues.invoice_number}
          type="text"
          fullWidth
          onChange={handleChange('invoice_number')}
        />
 {   /*       <Autocomplete
            fullWidth
            id="suplier-details-combo-box-demo-2"
            options={allSuppliers}
            getOptionLabel={(option) => option.name}                
            renderInput={(params) => <TextField {...params} label="Supplier"  />}
           // onChange={(event, value) => setSupplierId(value)}
            onChange={(event, value) => handleClickSupplier(value) }
           // inputValue={selectedSuplierName}
            loadingText
             defaultValue={selectedSuplierName}
            //value={selectedSuplierName}
            //onChange={handleChange('supplier_id')}
            /> */}

        <FormControl className={classes.formControl} >
            <InputLabel id="se-supplier_id-select-label" >Supplier</InputLabel>
            <Select
            autoWidth
            labelId="se-supplier_id-label"
            id="se-supplier_id"
            value={invoiceValues.supplier_id}
            onChange={handleChange('supplier_id')}
            label="Supplier"
            margin="dense"
        >
                   {

allSuppliers.map(
   (item,index)=>{
       return(
        <MenuItem value={item.id}>{item.name}</MenuItem>
   )
}
)}

        </Select>
          </FormControl>


            
             <FormControl className={classes.formControl} >
            <InputLabel id="se-invoice_status-select-label" >Invoice status</InputLabel>
            <Select
            autoWidth
            labelId="se-invoice_status-label"
            id="se-invoice_status"
            value={invoiceValues.invoice_status}
            onChange={handleChange('invoice_status')}
            label="Invoice Status"
            margin="dense"
        >
          <MenuItem value="unpaid">
            <em>None</em>
          </MenuItem>
          <MenuItem value="paid">Paid</MenuItem>
          <MenuItem value="unpaid">Unpaid</MenuItem>
        </Select>
          </FormControl>
           
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenAddMenu(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => { postnDeleteMenu() }}  color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Admin Add Dialog */}
 {/* <add Items> */}
 <Dialog open={openAddItemDialog} onClose={()=>setOpenAddItemDialog(false)} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
        <DialogTitle id="order-details-form-dialog-title">
         Add order item
        </DialogTitle>
        <DialogContent>
        <Typography variant="overline" display="block" gutterBottom>
        {puchaseValues.supplierName}
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
        {puchaseValues.purchaseOrderId}
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
        {puchaseValues.invoiceNumber}
        </Typography>       
           <FormControl className={classes.formControl} >
              <InputLabel id="se-product-id-select-label" >Product</InputLabel>
              <Select
                autoWidth
                labelId="se-product-id-label"
                id="se-product-id"
                value={values.product_id}
                onChange={handleChange('product_id')}
                label="Product"
                margin="dense">
                {
                  allProducts.map(
                    (item,index)=>{
                      //console.debug("Items: ",item.id,item.name,item.quantity_type)
                        return(
                          <MenuItem value={item.id} type={item.quantity_type}>{item.name}</MenuItem>
                        )
                  }
                  )
                }
              </Select>
          </FormControl>
          {values.quantity_type}     
          {
            <TextField
            fullWidth
              margin="dense"
              id="product-details"
              label="Quantity"
              value={values.quantity}
              type="number"
              onChange={handleChange('quantity')}
              InputProps={{
                endAdornment: <InputAdornment position="end">{selectedQtyType}</InputAdornment>,
              }}
            />
          }
            {
            <TextField
              fullWidth
              margin="dense"
              id="product-details"
              label="Unit Price"
              value={values.price}
              type="number"
              onChange={handleChange('price')}
            />
          }
          {/*
            <Autocomplete
            fullWidth
            //className={classes.Select}
            id="suplier-details-combo-box-demo-2"
            options={allSuppliers}
            getOptionLabel={(option) => option.name}
            style={{ width: 215 }} 
            value={values.supplier_id}                       
            renderInput={(params) => <TextField {...params} label="Supplier"  />}
            //onChange={(event, value) => setSelectedCustomer(value)}
            /> */
          }

        </DialogContent>
        <DialogActions>
        <Button onClick={()=>setOpenAddItemDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => { addPurchaseOrderItem() }} color="primary">
            Submit
          </Button>
        </DialogActions>

      </Dialog>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity={severity} onClose={handleClose}>
        {alertMessage}
      </Alert>
    </Snackbar>
    </div >
  )

}