import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { postData } from '../../../../services/getData';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';
import {useSelector,useDispatch} from 'react-redux';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '500px',
        '& > * + *': {
            marginTop: theme.spacing(2),
          },
    },
    theCard: {
        width: '1000px'
      },
  }));

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export const AddProduct=()=>{
    /*---------disable the console logs when production environment--------*/
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('success');
    const [alertMessage, setAlertMessage] = React.useState('loading....');
  const [values, setValues] = React.useState({
   // id: '',
    quantity: '',
    quantity_type: '',
    name: '',
    price: '',
    supplier_id: '',
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    console.debug(event.target.value)
  };

  
  const addProduct = () => {
    let rend = {
       // "id": values.id,
        "quantity": values.quantity,
        "quantity_type": values.quantity_type,
        "name":values.name,
        "price": values.price,
        "supplier_id": values.supplier_id
    }
    if (values.name, values.price) {
        postData('/restaurant/product', rend).then(
            (result) => {
                if(result.id)
                {
                    setSeverity('success');
                    setAlertMessage('Product is added, system created id is: '+result.id)
                }
                else{
                    setSeverity('error');
                    setAlertMessage(result.message)
                }
                console.log('add Product status')
                setOpen(true);
            }            
        )
       
        
        console.debug("form: "+rend)
    }    
   // handleClose()
}
const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

    return(
    <div >
        <Card className={classes.theCard} >
        <CardContent>  
        <Typography gutterBottom variant="h5" component="h2">
            Add Product
          </Typography>
            <div className={classes.root}>    
        <TextField
          required
          id="add-product-tf-name"
          label="Name"
          variant="outlined"
          fullWidth
          value={values.name}
          onChange={handleChange('name')}
        /> 
        <FormControl fullWidth  variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Unit Price</InputLabel>
          <OutlinedInput
            id="add-product-ip-price"
            value={values.price}
            onChange={handleChange('price')}
            startAdornment={<InputAdornment position="start">Rs</InputAdornment>}
            labelWidth={60}
          />
        </FormControl>
        <TextField
          id="add-product-tf-quantity"
          label="Quantity"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          value={values.quantity}
          onChange={handleChange('quantity')}
        />
        <FormControl variant="outlined" >
        <InputLabel id="add-product-inl-quantity-type">Quantity type</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="add-product-se-quantity_type"
          value={values.quantity_type}
          onChange={handleChange('quantity_type')}
          label="Unit type"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="liter">Liter</MenuItem>
          <MenuItem value="kilogram">Kilogram</MenuItem>
          <MenuItem value="packet">Packet</MenuItem>
          <MenuItem value="piece">Piece</MenuItem>
          <MenuItem value="bottle">Bottle</MenuItem>
          <MenuItem value="cane">Cane</MenuItem>
        </Select>
      </FormControl>
          
        <TextField
          required
          id="add-product-tf-supplier_id"
          label="Supplier ID"
          variant="outlined"
          fullWidth
          value={values.supplier_id}
          onChange={handleChange('supplier_id')}
        />  
        </div> 
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={()=>addProduct()} color="primary" startIcon={<SaveIcon />}>Add User</Button>
        </CardActions>
      </Card>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity={severity} onClose={handleClose}>
        {alertMessage}
      </Alert>
    </Snackbar>
      </div>
      
    )
}