import React, { useEffect, useState } from 'react'
// import { createBrowserHistory} from 'history'
// import {useLocation} from 'react-router-dom'
import { Component } from 'react'
// import {useSelector} from 'react-redux'
import { Row, Col } from 'react-bootstrap'
// import profilePicture from '../../../assets/images/profile/pfp.png';
import './style.css'
// import {waiterCard} from '../../../component/partials/waiterCard'
import  MenuData  from '../../../assets/menuImages/49.jpg'
import { getDining } from '../../../services/getDiningData'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import { useSelector, useLocation, useDispatch } from 'react-redux'
import { setTableMenu,setBasket } from '../../../redux/actions'
import Para from '../../../component/partials/Para'
import Heading from '../../../component/partials/Heading'
import Btn from '../../../component/partials/Button'
import { Spinner } from '../../../component/partials/Spinner'
import {getCustomerOrder} from '../../../services/getCustomerOrder'
import { TableBill } from '../../../component/partials/TableBillCommon'
import { postBill } from '../../../services/postBill'

export const TableOrder = () => {
      /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }
    const { table } = useSelector(state => state.currentTableReducer)
    const { menu } = useSelector(state => state.tableMenuReducer)
    const [loading, setLoading] = useState(false)
    const { dineTable } = useSelector(state => state.dineTableReducer)
    const {basket} =useSelector(state =>state.basketReducer)
    const { user } = useSelector(state => state.userReducer)

    // const [menu,setMenu]=useState()
    let _ = require('underscore')
    let totalPrice = 0;
    const dispatch = useDispatch()
    let currentDate=new Date().toString()
    function apiCall() {
        getCustomerOrder(`/restaurant/customer-orders/${table}?hour=16`).then(
            (result) => {
                let response = result
                const uniqueObjects = [...new Map(response.orderMenuItem.map(item => [item.menuItemId, item])).values()]
                dispatch(setBasket(uniqueObjects))

                dispatch(setTableMenu(response))
                console.log('its wrokihnaudauhdada')


            }
        )
    }
    const postNprint=(id)=>{
        const payload={
            
            "status": "progress",
      "total_amount": totalPrice,
      "payment_method": "cash",
      "paid": 0,
      "return_amount": 0
        }
        postBill(`/restaurant/bill/${menu.billId}`,payload).then(
            (result)=>{
              console.log('result',result)
      
            }
          )
        console.log('check the payload ',payload)
        console.log('bil id waiter',menu.billId)
        window.print()
    }
    useEffect(() => {

        setTimeout(() => {
            setLoading(true);
        }, 1800);

        apiCall()

        const interval = setInterval(() => {
            apiCall()
        }, 30000);

        return () => clearInterval(interval)
    }, [])
    console.log('+++++++++--------', menu, 'this is table ', table)
    console.log("Ash",dineTable)
    let TableNo;
if(menu && dineTable && loading){
    // for(var i=0;i<dineTable;i++){
        // menu.orderMenuItem.map((item)=>{return totalPrice+=item.price})
       
    // }

  

// let tableNo=dineTable.map((data)=>data.table.filter(data=> data.tableid.includes(81)))
// console.log('table Number',tableNo)
//console.log('table Number',dineTable[0].table[0].tableid)
//console.log('floor table id',menu.floorTableId)
//console.log("2-Ash",dineTable)
// console.log('floor table new',check)


// console.log('table Number new',tableNo)

// var b=a.orderMenuItem.filter((data) => data.orderItemStatus.includes("pending"))

}
let itemIdLength=[]
    return (
<div>
  <div className='btnOnPrint'>
       {loading && menu? <Grid container spacing={0} alignItems="stretch" className='btnOnPrint'>        
        <Grid item xs={8} >
        <div class="cards">

        <div >
            <Row>
            {
                basket.map((item,index)=>{
                    
let itemId;
let reet =item.id?item.id:item.menuItemId
console.log('reet id',reet)
itemId=menu.orderMenuItem.filter((item)=>item.menuItemId === reet)
console.log('item id reet',itemId)

     itemIdLength.push(itemId.length)
     totalPrice+=item.price*itemIdLength[index]
                    return(
                    <Col>
                       <Card style={{height:"250px"}}>
      <CardActionArea>
        <CardMedia
          image={MenuData}
          title="Contemplative Reptile"
        />
        <CardContent style={{}}>
          <Typography gutterBottom variant="h6" component="h2" style={{fontSize:"13px",fontWeight:"bold", }}>
          {item.name}
          </Typography>
          <Typography   color="textSecondary" component="p" style={{fontSize:"13px" }}>
        {item.price}
          </Typography>
          
          <Typography  color="textSecondary" component="p" style={{fontSize:"13px" }}>
              Quantity
        {itemIdLength[index]}
          </Typography>
         
        </CardContent>
      </CardActionArea>     
    </Card>
    </Col>

                    
                    )
                })
            }</Row>
</div>

    </div>
</Grid>
        <Grid xs={4} className='container' style={{backgroundColor:'#F5F5F5',padding:'10px',borderRadius:'4px'}}>
<Heading title='New Orders' fontSize='20px'/>
<Divider />


<Row>
    <Col><Para title='Order Id' fontSize="15px"/></Col>
    <Col><Para title={menu.id}   fontSize="15px"/></Col>

</Row>

{user.role=='takeaway'?'':<Row>
    <Col><Para title='Table Number' fontSize="15px"/></Col>
    <Col><Para title={menu.floorTableName} fontSize="15px"/></Col>

</Row>}

<Row>
    <Col><Para title='Customer Id' fontSize="15px"/></Col>
    <Col><Para title={menu.customerId} fontSize="15px"/></Col>

</Row>
<Divider />

<Row>
    <Col><Para title='Item' fontWeight='bold' fontSize="15px"/></Col>
    <Col><Para title='Price (Rs)' fontWeight='bold' fontSize="15px"/></Col>
    <Col><Para title='Item Total' fontWeight='bold' fontSize="15px"/></Col>
    <Col><Para title='Qt' fontWeight='bold' fontSize="15px"/></Col>

</Row>
<Divider />

    {
    

basket.map(
    (item,index)=>{
        // totalPrice+=item.price
        return(
            <div>
            <Row>
                <Col>
                <Para color='black'  title={item.name} fontSize="15px"  />
                </Col>
                <Col><Para color='black' title={item.price}  fontSize="15px"/></Col>
                <Col><Para color='black' title={item.price*itemIdLength[index]}  fontSize="15px"/></Col>

                <Col><Para color='black' title={itemIdLength[index]} fontSize="15px"/></Col>
                </Row>
                
            </div>
        )
    }
)

    }
    

<Divider />
<Row>
    <Col><Para title='Total Price' fontWeight='bold'  fontSize='15px'/></Col>
    <Col style={{fontWeight:'bold'}}>Rs : <Para title={totalPrice} fontSize="15px" fontWeight='bold'/></Col>
</Row>

<div className='btnOnPrint'>
<Btn title="Print" margin="20px 0 0 0" borderRadius='50px' height='50px' onClick={postNprint}/>
</div>
        </Grid>      


      </Grid>:<Spinner />}

</div>
       {/* Receipt View */}

<div className='hiddenView'>
{loading&& menu?<div >
    <img src="https://ab-res.web.app/static/media/header-01.81675d4a.png" style={{height:'220px', display:'block', marginLeft:'auto', marginRight:'auto'}}/>
{/*<h1 style={{textAlign:'center',fontWeight:'bold' }}>AB Restaurant</h1>*/ } 
    <div style={{textAlign:'center'}}>Address: Near Malir Bridge, Malir Karachi</div>
    <div style={{textAlign:'center'}} >Phone: 021-34500919</div>  
    <Divider />
    <div style={{textAlign:'center' }}>Customer Order</div>
    <div style={{textAlign:'center' }}>{currentDate.slice(0, 24)}</div> 
    <div style={{textAlign:'center' }}>Order Id: {menu.id}</div> 
    <div style={{textAlign:'center' }}>Customer Id: {menu.customerId}</div> 
    <div style={{fontSize:'30px',textAlign:'center' }}>{user.role=='takeaway'?'Order Type: Takeaway' : 'Table Number: '+menu.floorTableName}</div> 
    

<br/><br/>
<table className='w-100'>
          <thead className='kitchen-table-head'>
            <td>ITEM</td>
            <td>QTY</td>
            <td>PRICE</td>
            <td>AMOUNT</td>
          </thead>
          <tbody>
          {
basket.map(
    (item,index)=>{
        return(
        <tr key={index} className='rowPad'>
            <td >{item.name}</td>
            <td >{itemIdLength[index]}</td>
            <td >{item.price}</td>
            <td>{item.price*itemIdLength[index]}</td>
        </tr>
    )
    }
)

   }
          </tbody>
          </table>   
          <br />
<Divider style={{borderTop:'5px dashed black'}}/>
<Row>
   <Col><h1 style={{textAlign:'center',fontWeight:'bold'}}>TOTAL</h1></Col>
   <Col><h1 style={{textAlign:'center',fontWeight:'bold'}}>Rs : {totalPrice}</h1></Col>
   <Col></Col>
</Row>
<br/>

<div className='btnOnPrint'>
<Btn title="Print" margin="20px 0 0 0" borderRadius='50px' height='50px' onClick={()=>{postNprint(menu.billId)}}/>
</div></div>
:''}</div >
       




</div>





    )
        }






















