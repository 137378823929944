import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Avatar, IconButton } from '@material-ui/core';
import PureBreadcrumbs from '../../../component/Breadcrumb';
import { Spinner } from '../../../component/partials/Spinner';
import Slide from '@material-ui/core/Slide';
import { getUsers } from '../../../services/getUsers';
import { putData } from '../../../services/getData';
import { postData } from '../../../services/getData';
import EditIcon from '@material-ui/icons/Edit';
import { MdAdd } from 'react-icons/md'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {GridToolbar } from "@material-ui/data-grid";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function BillTableToolBar() {
    /*---------disable the console logs when production environment--------*/
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
  return (
    <div>
      
      <GridToolbar />
    {/*<GridToolbarFilterButton />
    <GridToolbarDensitySelector />
    <GridToolbarExport />*/}
  </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
function QuickSearchToolbar(props) {
  //const classes = useStyles();

  return (
   // <div className={classes.root}>
    <div >
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
       // className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
export const Suppliers = () => {
  const { customer } = useSelector(state => state.customerReducer)
  const { user } = useSelector(state => state.userReducer)
  const [open, setOpen] = React.useState(false);
  const [openAddMenu, setOpenAddMenu] = React.useState(false);
  const [existingQty, setExistingQty] = React.useState(0);
  const [allWaiters, setAllWaiters] = React.useState([])
  const [allSuppliers, setAllSuppliers] = React.useState([])
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('loading....');
  const [receivedData, setReceivedData] = React.useState(true);

  const dispatch = useDispatch();
  const [values, setValues] = React.useState({
    id: '',
    name: '',
    address:'',
    phone:0,
    email:'no-email',
  });
  const [searchText, setSearchText] = React.useState('');
  //const [rows, setRows] = React.useState();
  const [rows, setRows] = React.useState(allSuppliers);
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = allSuppliers.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
   // console.debug(prop + " : " + event.target.value);
  };
  const [dialogType, setDialogType] = React.useState("add");
  
  const handleClickOpen = (rowdata, clickType) => {
    
    setDialogType(clickType)
    if(rowdata)
    {
      setValues(rowdata)
      setExistingQty(rowdata.quantity)
      console.debug("quantity:" + rowdata.quantity)
    }
   if(clickType==='add')
    {
      setValues({
       // id: '',
        name: '',
        address:'',
        phone:0,
        email:'no-email',
      })
    }    
   // setOpen(true);
    setOpenAddMenu(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getSupplier();
  }, [])
  useEffect(() => {
    
  }, [])
  function  getSupplier () {
    getUsers('/restaurant/suppliers').then(
      (result) => {
        let resp = result
        setAllSuppliers(resp);
        setRows(resp);
        setReceivedData(false) ;
      }
    )
  }

  const postnDeleteMenu = () => {
    var url = null;
   // var newQty = parseInt(existingQty);
   console.debug('0-Added supplier...',dialogType);
    if (dialogType === "edit") {
      url = `/restaurant/supplier/${values.id}`
      putData(url, values).then(
        (result) => {  
          setSeverity('success');
          setAlertMessage('Supplier updated: '+values.id)
         getSupplier();
          setOpenAddMenu(false);
         setOpen(true);
        }
      )
    }
    else {
      url = `/restaurant/supplier`
      console.debug('1-Added supplier...');
      postData(url, values).then(
        (result) => {
            if(result.id)
            {
              setSeverity('success');
              setAlertMessage('Product is added, system created id is: '+result.id)
              getSupplier();
              console.debug('2-Added supplier...');
            }
            else{
                //setSeverity('error');
               //setAlertMessage(result.message)
               console.error('Error supplier...');
               setSeverity('error');
               setAlertMessage(result.message);
            }
            console.debug('3-Added supplier...');
            setOpenAddMenu(false);
             setOpen(true);
        }            
    )
    }
  }


  const [editRowsModel, setEditRowsModel] = React.useState({});
  const [adminDialogOpen, setAdminDialogOpen] = useState(false);

  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model);
    console.debug("actionType???",editRowsModel);
  }, []);
 /* const handleCellEditCommit = React.useCallback(
    () => {
      console.debug("2-actionType???",);
    },
    [],
  );*/

  const handleCellEditCommit = React.useCallback(
    async (params) => {
      try {
        console.debug('Row params:',params)
        var updateValues={
          id: params.id        
        }
        allSuppliers.forEach((supplier)=>{
          if(supplier.id===params.id){
            updateValues.address=supplier.address;
            updateValues.phone=supplier.phone;
            updateValues.email=supplier.email;
            updateValues.name=supplier.name;
            updateValues[params.field]=params.value;
            //break;
          }          
        });
        console.debug('setting values:',updateValues);
        var url = `/restaurant/supplier/${updateValues.id}`
        putData(url, updateValues).then(
          (result) => {  
            setSeverity('success');
            setAlertMessage('Supplier updated: '+updateValues.id)
            getSupplier();
           // setOpenAddMenu(false);
           //setOpen(true);
           //setSnackbar({ children: 'User successfully saved', severity: 'success' });
            setRows((prev) =>
            prev.map((row) => (row.id === params.id ? { ...row, ...result } : row)),
            );
          }
        )
      } catch (error) {
     
      }
    },
    [],
  );
  const addMenuClick = () => {
    //setEditCateg(get_icon_Key(menuData,data))   
    console.debug('from add popup open')
    //setBackdropOpen(true);
    setBackdropOpen(true);
    setOpen(true);
  }

  let role = user.role;
  let columns;
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);
  {
    /**"id": 51,
      "name": "Chicken Supplier",
      "address": "xyz",
      "phone": 8,
      "email": "xyx.com" */
    columns = [

      { field: 'id', headerName: 'Supplier #', width: 120 },
      { field: 'name', headerName: 'Name', width: 200, editable: true },
      { field: 'address', headerName: 'Address', width: 150, editable: true },
      {
        field: 'phone', headerName: 'Phone', width: 150, editable: true,
        valueFormatter: (params) => {
          return `0 ${params.value}`;
        }
      },
      { field: 'email', headerName: 'Email', width: 250, editable: true },
      {
        field: 'invEdit', headerName: 'Edit', width: 150,
        renderCell:
          (params) => {
            return (
              <IconButton aria-label="increase" >
                <EditIcon color="secondary" onClick={() => { handleClickOpen(params.row, "edit") }} />
              </IconButton>
            )
          },
        disableClickEventBubbling: true,
      },
    ]
  }
  //console.log("customer: "+customer);
  return (
    <div className='container' style={{ maxWidth: '100%' }}>

      <PureBreadcrumbs className='onPrintDine' />

      {/*---- Main table----*/}
      {allSuppliers ?
        <div style={{  width: '100%' }} className={'onPrintDine'} id='supplier-details-list-table'>
          <DataGrid 
          rows={rows} 
          columns={columns} 
          pageSize={100} 
          autoHeight={true}
            //editRowsModel={editRowsModel}
           // onEditRowsModelChange={handleEditRowsModelChange}
            //onCellEditCommit={handleCellEditCommit}
            onCellEditCommit={handleCellEditCommit}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'desc' }],
              },
            }}
            density="compact"
            loading={receivedData}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
              },
            }}
            /*components={{
              Toolbar: BillTableToolBar
            }}*/
          />

          {user.role == 'admin' || user.role == 'storekeeper' ? <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='onPrintDine'>

            <IconButton onClick={() => { handleClickOpen(null, "add") }}><MdAdd style={{ fontSize: "42px" }} /></IconButton>

          </div> : ''}
        </div>
        : <Spinner />}
   

      {/* ADD & Edit Supplier dialog */}
      <Dialog open={openAddMenu} onClose={() => setOpenAddMenu(false)} aria-labelledby="form-dialog-title" TransitionComponent={Transition} >
        <DialogTitle id="product-details-form-dialog-title">
          {
            dialogType === "edit" ?'Edit Suppliers': 'Add Suppliers'
          }
        </DialogTitle>
        <DialogContent >
          {
              <TextField
                autoFocus
                margin="dense"
                id="product-details-name"
                label="Product name"
                value={values.name}
                fullWidth
                onChange={handleChange('name')}
              />
          }
          {
            <TextField
              
              margin="dense"
              style={{margin_right: '20px'}}
              id="product-details"
              label="Address"
              value={values.address}
              type="text"
              fullWidth
              onChange={handleChange('address')}
            />
          }
          {
            <TextField
              
              margin='5'
              id="product-details"
              label="Phone Number"
              value={values.phone}
              type="text"
              fullWidth
              onChange={handleChange('phone')}             
            />
          }
          <br/>          
          {
            <TextField
              
              margin_left='10px'
              id="product-details"              
              label="Email"
              value={values.email}
              defaultValue="no-email"
              type="text"
              fullWidth
              onChange={handleChange('email')}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddMenu(false)} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={() =>  postnDeleteMenu() }>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* ADD Supplier dialog popu  End*/}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity={severity} onClose={handleClose}>
        {alertMessage}
      </Alert>
    </Snackbar>
    </div>
  )

}