import React,{useEffect,useState} from 'react'
import {TableButton} from './Tablebutton'
import { Button,Row,Col } from 'reactstrap';
import './style.css';
import Btn from '../partials/Button'
import Para from '../partials/Para'
import Divider from '@material-ui/core/Divider';
import { setMenuData,addMenuItem,removeMenuItem,setBasket,subtractMenuItem,addCart,removeWholeBasket,decWholeBasket,incWholeBasket,removeCart,popCart,emptyCart} from '../../redux/actions';
import {useDispatch,  useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import {postTableOrder} from '../../services/postTableOrder'
import {getDining} from '../../services/getDiningData'
import {IconButton,Icon} from '@material-ui/core'
import{RiAddBoxFill} from 'react-icons/ri'
import {AiFillMinusSquare} from 'react-icons/ai'
import StarRateIcon from '@material-ui/icons/StarRate';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  tableHeader: {
    font:'17px monospace !important;',
    fontWeight:"bold !important;",
  },
  tableRow: {
    font:'17px monospace !important;',
  },
  tableRowTotal: {
    font:'18px monospace !important;',
    fontWeight:"bold !important;"
  },
});

export function TablebillCom(action) {
  const classes = useStyles();
  // const {orderData} = useSelector(state => state.menuDataReducer)
  // const a=[{id:1},{id:2},{id:3},{id:4},{id:5},{id:6}]
  const { basket } = useSelector(state => state.basketReducer)
  const {cart} =useSelector(state=>state.cartReducer)
  const {orderId} =useSelector(state=>state.orderIdReducer)
  const {oldItem}=useSelector(state=>state.oldItemReducer)
  const {totalTableInfo} = useSelector(state=>state.totalTableInfoReducer)
  const {wholeBasket} =useSelector(state =>state.wholeBasketReducer)
  const {duplicatedWholeBasket} =useSelector(state=>state.duplicatedWholeBasketReducer)
  const { user } = useSelector(state => state.userReducer)
  const dispatch=useDispatch()

// var l=length
let postErrorOccur=false;
 const [okButton,setOkButton]=useState(true)
 const[snackOpen,setSnackOpen]=useState(false)
const [submitButtonColor,setSubmitButtonColor]=useState('gray')
let val=action.action;
let totalPrice=0
console.log('here,s cal',val)
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const snackHandleClose=(event, reason)=>{
  if (reason === 'clickaway') {
    return;
  }

  setSnackOpen(false);
}
useEffect(()=>{
if(val == true){
  setOkButton(false)
setSubmitButtonColor('#ff0000')

}
else{
  // setSubmitButtonColor('gray')
setOkButton(true)
setSubmitButtonColor('gray')

}
},[val])

let itemIdLength=[]
let duplicateLength=[]
const addMenu = (data,index) => {
  
  console.log('this is current item id basket', data)
  let pay = {
    name: data.name,
    price: data.price,
    id: data.id?data.id:data.menuItemId,
    // quantity:data.quantity?data.quantity:1
  }
  let amt={
    name: data.name,
    price: data.price,
    menuItemId: data.id?data.id:data.menuItemId,
  }
let inc=itemIdLength[index]
console.log('is inc',itemIdLength[index])
  inc=inc+1;
  itemIdLength[index]=inc
  console.log('is increment',itemIdLength)
dispatch(addCart(data.id?data.id:data.menuItemId))
  dispatch(addMenuItem(pay))
  dispatch(incWholeBasket(amt))
  setOkButton(false)
  setSubmitButtonColor('#ff0000')
  console.log('dekho cart 2',cart)
}
console.log('dekho cart',cart)

const subtractItem=(data,index)=>{
  if(data.quantity >1){
  dispatch(popCart(data.id?data.id:data.menuItemId))

  }
  

console.log('cur',data)
let dec=itemIdLength[index]
if( itemIdLength[index] > duplicateLength[index]){
// dispatch(subtractMenuItem(data))
// dispatch(decWholeBasket(data.id?data.id:data.menuItemId))
  dec=-1}
dispatch(popCart(data.id?data.id:data.menuItemId))
dispatch(decWholeBasket(data.id?data.id:data.menuItemId))


}
if(postErrorOccur == true){
  alert('Error Occur in Post API')
}
// let len =length
const postBasket=()=>{
  setOkButton(true)
  setSubmitButtonColor('gray')
  basket.map(data=>{
    console.log('hey loookey',data.id)
    
    
  }    
  )
  // let value=null
  
// new schema logic

let check=[]
basket.map((item)=>{
  let bow={
  
    menuItemId:item.id,
    quantity:item.quantity
  }
  check.push(bow)
})
console.log('new logic ',check)
// end logic


  let dt=basket.map(dt=>dt.id?dt.id:null )
  
 let onlyId = dt.filter(function(item) {
    return item !== null
})

console.log('whats is items',onlyId)
let payload={
  "orderId":orderId,
  "orderItems":cart,
  "orderItemStatus":"pending"
}
console.log('call payload',payload)
  postTableOrder('/restaurant/customer-order-items',payload,'POST').then(
    (result)=>{
      let response=result

    }
   
  )
  dispatch(emptyCart()) 

  setSnackOpen(true)
  // console.log('hey checken',basket.map(dt=>dt.id))
// console.log('order id is ',orderId)

  // console.log()
}

  // const {basket} =useSelector(state=>state.basketReducer)
  console.log('Heerre items in basket',basket)
console.log('here,s action',action.action)

  function removeItem(index,data){
// console.log('remove cart',id)
dispatch(removeMenuItem(index))
// dispatch(popCart(data.id?data.id:data.menuItemId))
dispatch(removeCart(data.id?data.id:data.menuItemId))
dispatch(removeWholeBasket(data.id?data.id:data.menuItemId))


}
console.log('whole basket',wholeBasket)
console.log('current value of basket is ',basket)

  return (
    // <>
    <div className='container1 ' style={{ backgroundColor:"#f5f5f5" , padding:"10px",color:"#707070" , borderRadius:'4px'}} >
        <div className='TableBillCommon-waiter-order' >
        

          <Row>
    <Col><Para margin='0 0 3px 0' font="normal normal normal 18px/19px mont-med" title='Order Id'/></Col>
    <Col><Para margin='0 0 3px 0' textAlign='right' font="normal normal normal 18px/19px mont-med" title={totalTableInfo.id} /></Col>

</Row>

<Row>
    <Col><Para margin='0 0 3px 0' title='Customer Id' font="normal normal normal 18px/19px mont-med"/></Col>
    <Col><Para margin='0 0 3px 0' textAlign='right' font="normal normal normal 18px/19px mont-med"title={totalTableInfo.customerId} /></Col>

</Row>

{user.role!=='takeaway'?<Row>
    <Col><Para margin='0 0 3px 0' title='Table Number' font="normal normal normal 18px/19px mont-med"/></Col>
    <Col><Para margin='0 0 3px 0' textAlign='right' font="normal normal normal 18px/19px mont-med"title={totalTableInfo.floorTableName} /></Col>

</Row>:''}

          <Divider />

          <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size="small">
    <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell className={classes.tableHeader}>Name</TableCell>
            <TableCell className={classes.tableHeader} align="right">Price</TableCell>
            <TableCell className={classes.tableHeader} align="right">Quantity</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
  {
  
    basket.map((data,index)=>{
      let disabled
let itemId,duplicateItemId;
let reet =data.id?data.id:data.menuItemId
console.log('reet id',reet)
itemId=wholeBasket.filter((item)=>item.menuItemId === reet)
duplicateItemId=duplicatedWholeBasket.orderMenuItem.filter((item)=>item.menuItemId === reet)
console.log('item id reet',itemId)

     itemIdLength.push(itemId.length)
duplicateLength.push(duplicateItemId.length)
      if(index<oldItem){
disabled=false

      }
      else{
        disabled=true
   
      }
     
 totalPrice+=data.price*itemIdLength[index] 
const incLength=(index)=>{
  let val=itemIdLength[index]
  val=val+1
  console.log('consilde example',val)
  return itemIdLength[index]=val
}     
      return(
          <TableRow key={data.name}>
              <TableCell className={classes.tableRow}>
                {disabled?<AddShoppingCartIcon fontSize="small" color="primary" />:<Icon disabled={true}/>}
                </TableCell>
              <TableCell className={classes.tableRow}>{data.name}</TableCell>
              <TableCell className={classes.tableRow}  align="right">{data.price}</TableCell>
              <TableCell className={classes.tableRow}  align="right">
                <IconButton size="small">
                  <RemoveCircleIcon fontSize="small" color="primary" onClick={()=>{subtractItem(data,index)}} />
                </IconButton>
                {itemIdLength[index]?itemIdLength[index]:1}
                <IconButton size="small" >
                  <AddCircleIcon fontSize="small" color="primary" onClick={()=>{addMenu(data,index);incLength(index);}}/>
                </IconButton>                
              </TableCell>
              <TableCell className={classes.tableRow}  align="right">
                {disabled?<IconButton size="small" color="secondary"  onClick={()=>{removeItem(index,data)}}><DeleteIcon fontSize="small"  /></IconButton>:''}
                </TableCell>
            </TableRow>
      )
      }
      )
  
}
          
          <TableRow>
            <TableCell className={classes.tableRowTotal} align="right" colSpan={2}>TOTAL</TableCell>
            <TableCell className={classes.tableRowTotal} align="right">{totalPrice}</TableCell>
          </TableRow>
</TableBody>
</Table>
    </TableContainer>

          <hr />


          <br />
          <Btn title='Submit' height="50px" borderRadius="70px" justifyContent="center" bgColor={submitButtonColor} disabled={okButton} onClick={()=>{postBasket()}} />
  
  
        
     

        </div>
        <Snackbar open={snackOpen} autoHideDuration={6000} onClose={snackHandleClose}>
        <Alert onClose={snackHandleClose} severity="success">
          SuccessFully submit the order!
        </Alert>
      </Snackbar>

      </div>

    // </>
  )
}
