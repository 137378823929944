import {BaseURL} from './baseURL'

export function getBills(address){


  // let BaseUrl=BaseURL
    // let BaseURL='http://192.168.1.76:8082';


    return new Promise((resolve,reject)=>{

      var myHeaders = new Headers();
myHeaders.append("content-type","application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
           

            // body: file,
            // redirect: 'follow'
          };

        fetch(BaseURL+address, requestOptions)
  .then(response => response.json())
  
  .then((result) => {
    resolve(result)
    console.log(result)
            let data=result
            return data;

        }
  )
  .catch((error)=>{
      reject(error)
      console.log('error',error)
  });
    })
}