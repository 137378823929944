//export const BaseURL = 'https://ab-restaurant.herokuapp.com'

// 
//export const BaseURL = 'http://192.168.18.8:8082'
//export const BaseURL = 'http://localhost:8082'

//new heroku
//export const BaseURL = 'https://abrestaurant.herokuapp.com'

//IONOS
//export const BaseURL = 'http://www.baloch.info:8082'
//export const BaseURL = 'http://www.anwar-baloch.online:8082'
export const BaseURL =''
