
import React,{useEffect,useState, useRef} from 'react';
import axios from "axios"
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Size,
  Tooltip,
  Export,
} from 'devextreme-react/chart';

import PivotGrid, {
  FieldChooser,
  Scrolling,
  LoadPanel
} from 'devextreme-react/pivot-grid';
import config from 'devextreme/core/config';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { sales } from '../../../localDB/transactionData';
import { setCustomer } from '../../../redux/actions';
import { getCustomers } from '../../../services/getCustomerList';
import {makeStyles } from '@material-ui/core/styles';
const currencyFormatter = new Intl.NumberFormat(
  'en-PK', {
    style: 'currency',
    currency: 'PKR',
    minimumFractionDigits: 0,
  },
);
function customizeTooltip(args) {
  const valueText = currencyFormatter.format(args.originalValue);
  return {
    html: `${args.seriesName} | Total<div class="currency">${valueText}</div>`,
  };
}

export const Reports = () => {
//export default function Reports() {

  
  const { customer } = useSelector(state => state.customerReducer)

  const [receivedData, setReceivedData] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [country, setCountry] = React.useState("Netherlands");
  const [currency, setCurrency] = React.useState("EUR");
  useEffect(() => {
      if(!dataLoaded)
      {
        getTransactions();
      }
    }, [dataLoaded]);
    const dispatch = useDispatch();



    function getTransactions() {
      getCustomers('/restaurant/inventory-transactions').then(
        (result) => {
          let response = result
          dispatch(setCustomer(response));
          setDataLoaded(true);
        }
      )
    }
  config({ defaultCurrency: currency });
  const dataSource = new PivotGridDataSource({
    fields: [{
      caption: 'product',
      dataField: 'product',
      //width: 150,
      area: 'row',
      selector: function (data) {
        return data.product+" ("+data.quantityType+")";
        },
    },{
      caption: 'department',
     // width: 120,
      dataField: 'department',
      area: 'row',
      sortBySummaryField: 'Total',
      customizeText: function (cellInfo) {
        return cellInfo.value==="undefined"||cellInfo.value===null?"-no department-":cellInfo.value;
    },
    }, {
      caption: 'Employee',
      dataField: 'employee',
     // width: 150,
      area: 'row',
      customizeText: function (cellInfo) {
        return cellInfo.value==="undefined"||cellInfo.value===null?"-no employee-":cellInfo.value;
    },
    },  {
      dataField: 'transactionKhiFormatTime',
      dataType: 'date',
      area: 'column',
    }, {
      groupName: 'transactionKhiFormatTime',
      groupInterval: 'year',
      visible: false
    },    
    {
      groupName: 'transactionKhiFormatTime',
      groupInterval: 'quarter',
      visible: false
  }
    , {
      groupName: 'transactionKhiFormatTime',
      groupInterval: 'month',
      expanded: false,
    }, {
      groupName: 'transactionKhiFormatTime',
      groupInterval: 'day',
      expanded: true,
    }
    /*,
    {
      caption: 'Quantity',
      selector: function (data) {
        return data.quantity+"-"+data.quantityType;
        },
      area: 'data',
    }*/,{
      caption: 'Quantity',
      dataField: 'quantity',
      dataType: 'number',
      summaryType: 'sum',
      area: 'data',
    },
    {
      caption: '(recent) Total purchase price',
      dataField: 'totalProductPriceAtTransactionTime',
      dataType: 'number',
      summaryType: 'sum',
      //format: 'currency',
      area: 'data',
    }],
    store: customer,
  });
  const [value, setValue] = useState();

    const refresh = ()=>{
        // re-renders the component
        setValue({});
    }
  return (
    <div>
  {dataLoaded?
        <ReportsClass dataSource={dataSource} />
      :
      <Paper elevation={3} style={{display:'flex', justifyContent: 'center' ,alignContent:'center'}}>
      <CircularProgress  />
    </Paper>
    }
      
  </div>
    
  ); 
}


class ReportsClass extends React.Component  {
  constructor(props) {
    super(props);
    this.dataSource=props.dataSource;
  this.povitRef = React.createRef();
  this.chartRef = React.createRef();
    
  }
 
componentDidMount() {
  this.povitRef.bindChart(this.chartRef, {
    dataFieldsDisplayMode: 'splitPanes',
    alternateDataFields: false,
  });
}
  render() {
    return (
      <React.Fragment>
        <PivotGrid
          id="pivotgrid"
          dataSource={this.dataSource}
          allowSortingBySummary={true}
          allowFiltering={true}
          showBorders={true}
          showColumnTotals={false}
          showColumnGrandTotals={false}
          showRowTotals={false}
          showRowGrandTotals={false}
          ref={(ref) => { ref?this.povitRef = ref.instance : this.povitRef=this.povitRef.current ; }}
        >
          <FieldChooser enabled={true} height={400} />
          <Export enabled={true} />
        </PivotGrid>
        <br/>
        <Chart ref={(ref) => { ref? this.chartRef = ref.instance : this.chartRef=this.chartRef.current;}} >
          <Size height={200} />
          <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
          <CommonSeriesSettings type="bar" />
          <AdaptiveLayout width={450} />
        </Chart>
        </React.Fragment>
    );
  }
}