import React, { useEffect, useState } from 'react';
import PureBreadcrumbs from '../../../component/Breadcrumb'
import { Label, Input } from 'reactstrap'
// import './reception.css';
import { table } from './data'
import { dineData } from '../../../localDB/dinningData';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Para from '../../../component/partials/Para'
import Btn from '../../../component/partials/Button'
import { BorderColor } from '@material-ui/icons';
import { Link } from 'react-router-dom'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { getDining } from '../../../services/getDiningData'
import { postDining } from '../../../services/postDiningStatus'
import { useDispatch, useSelector } from 'react-redux';
import { setDineTable, setTableStatus } from '../../../redux/actions';
import { MyCard } from '../../../component/partials/Card'
import { Row } from 'react-bootstrap'
import Grid from '@material-ui/core/Grid';
import { Spinner } from '../../../component/partials/Spinner'
import ApartmentIcon from '@material-ui/icons/Apartment';



export function CashierDining() {
      /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }

    const { user } = useSelector(state => state.userReducer)

    console.log('user in dinning', user)
    const { dineTable } = useSelector(state => state.dineTableReducer)
    const dispatch = useDispatch()

    function apiCall() {
        getDining('/restaurant/dinning').then(
            (result) => {
                let response = result;
                console.log('checking API data', response)
                dispatch(setDineTable(response))

            })
    }

    useEffect(() => {
        apiCall()
        const interval = setInterval(() => {
            apiCall()
        }, 30000);

        return () => clearInterval(interval)

    }, [])



    console.log('dinetable edites', dineTable)
    return (
        <div>

            <PureBreadcrumbs />
            {/* <h1 style={{ textAlign: 'center' }}>Dining </h1> */}
            <br />

            {/* <AccountTreeIcon color="primary"/> */}
            <div>


{dineTable?
                <div className='row' style={{ boxShadow: '0px 8px 23px ' ,marginLeft:'auto' }}>
                    {dineTable.map((floorData, index) => {
                        console.log('checking floornama u', floorData.floorName)
                        var occ = floorData.table.filter((data) => data.status.includes("occupied"))
                        var free = floorData.table.filter((data) => data.status.includes("free"))

                        return (<div>
                            <div className='row w-100'>
                                <div className="col-lg-4 ">
                                    <p style={{textAlign:'center'}}><u className="dinning-p-line"><ApartmentIcon style={{color:'black'}}/> {floorData.floorName.substring(2)}</u></p>
                                </div>

                                {/* {occ.length} */}
                                <div className="col-lg-4 ">
                                    <p style={{textAlign:'center'}}><u className='dinning-p-line'>occupied : {occ.length}</u></p>
                                </div>

                                {/* {free.length */}
                                <div className="col-lg-4 ">
                                    <p style={{textAlign:'center'}}><u className='dinning-p-line'>vacant tables : {free.length}</u></p>
                                </div>
                            </div>
                            <br />
                            <div >
                                <Grid container spacing={0} alignItems="stretch" style={{justifyContent:'center'}}>
                                    {
                                        floorData.table.map((data, tableIndex) => {
                                            //                                   
                                            let freeStatus = false
                                            if (data.status == 'free') {
                                                freeStatus = true
                                            }
                                            return (
                                                <Grid item xs>
                                                    <MyCard number={data.tableNumber} status={data.status} />
                                                </Grid>
                                            )
                                        })

                                    }
                                    <hr />
                                    <hr />
                                </Grid>
                            </div>
                        </div>
                        )
                    })
                    }
                </div>
:<Spinner /> } </div>
        </div>
    )
};
