import React, { useEffect ,useState } from 'react';
import PureBreadcrumbs from '../../../component/Breadcrumb'
import {MyCard} from '../../../component/partials/Card'
import {Route,useLocation,useHistory} from 'react-router-dom'
import './style.css';
import { table } from '../reception/data'
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Para from '../../../component/partials/Para'
import Btn from '../../../component/partials/Button'
import { BorderColor } from '@material-ui/icons';
import { Link } from 'react-router-dom'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { getTableOrder } from '../../../services/getTableOrder'
import { setTableOrder,currentTable } from '../../../redux/actions'
import { getDining } from '../../../services/getDiningData'
import {TableOrder} from '../../../pages/Layout/waiter/tableOrder'
import {Spinner} from '../../../component/partials/Spinner'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {getCustomerOrder} from '../../../services/getCustomerOrder'



export const Orders = () => {
      /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }
    const { user } = useSelector(state => state.userReducer)
    const dispatch = useDispatch()
    const history=useHistory()
    const location=useLocation()
    const { tableOrder } = useSelector(state => state.tableOrderReducer)

    function apiCall(){
        var path=`/restaurant/customer-orders?user-id=${user.id}&hour=16`
        if(user.role==='adminwaiter')
        {
            path=`/restaurant/customer-orders?&hour=16` 
        }
        getCustomerOrder(path).then(
            (result) => {
                let response = result;
                console.log('checking ORDER DATA', response)
                dispatch(setTableOrder(response))
            })
    }
    function apiCall1(){
        getCustomerOrder(`/restaurant/customer-orders?hour=16`).then(
            (result) => {
                let response = result;
                console.log('checking ORDER DATA', response)
                dispatch(setTableOrder(response))

            

            })
    }
    useEffect(() => {
if(user.role=='admin'){
    apiCall1()
    const interval = setInterval(() => {
     apiCall1()
 }, 30000);

 return () => clearInterval(interval)
}
else{
    apiCall()
    const interval = setInterval(() => {
     apiCall()
 }, 30000);

 return () => clearInterval(interval)
}
       
      
    }, [])

    


const openDetail=(id)=>{
    location.pathname='/waiter/waiterTableOrder'
history.push('/waiter/waiterTableOrder')
dispatch(currentTable(id))

}
const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
const[isToggleOn,setIsToggleOn]=useState(false)
const handleClick=()=>{
    setIsToggleOn(!isToggleOn)
}
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  let filterTable;
  if(tableOrder ){
      if(!isToggleOn){
      filterTable=tableOrder.filter((data)=>data.status.includes('progress') )
      }
      else{
        //   || data.status.includes('new'))
          filterTable=tableOrder.filter((data)=>data.status.includes('progress') || data.status.includes('completed'))
      }
  }
console.log('filter table',filterTable)
console.log('nom table order',tableOrder)
let a=[1,2,3,4,5]
    return (
        <div>
            <div>
            <PureBreadcrumbs style={{position:'relative'}}/>
           </div>
            <br />

            
           { tableOrder?<div className='container-fluid waiter-dining-main'>
<FormGroup className='MatToggle'>
           <FormControlLabel
        control={<Switch 
        color='primary'
        label="Completed"
        onChange={handleClick}/>}
        label="Completed"
      />
      </FormGroup>


                <br className='m-5' />



               
                <div className='row ' >

                    {filterTable.map((data) => {

                        console.log('table order ...........', tableOrder)
                        console.log('table order ...........', data)
                        return (

 <div className='col waiter-dinning-cont' onClick={()=>openDetail(data.id)}>
    <MyCard id={data.customerId} number={data.floorTableName} status={data.status} orderId={data.id} title=""  />
    {/* <div style={{position:'absolute',bottom:'15px',left:'55px'}}>
    <p>O-Id: {data.id}</p>

    </div> */}

    <div style={{position:'absolute',bottom:'5px',left:'55px'}}>
    {/* font="normal normal normal 15px/19px Montserrat" */}
    <Para title="See Orders" color="#FF0000"   />
    </div>
    
    </div> 

                         
                        )
                    })
                    }
                </div>
            </div>
            :<Spinner />}
        </div>
    );
}