import React, { useEffect, useState } from 'react'
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom'
import './style.css'
import { Row, Col } from 'reactstrap';
//import TextField from '@material-ui/core/TextField';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { getCustomers } from '../../../services/getCustomerList'
import { CreateDelCus } from '../../../services/createDelCus'
import { getCustomerOrder } from '../../../services/getCustomerOrder'
import { setTableOrder, setOrderId, setBasket, setTableMenu } from '../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { DataGrid } from '@material-ui/data-grid';
import Btn from '../../../component/partials/Button'
//import { Spinner } from '../../../component/partials/Spinner'





const StyledChip = withStyles({
    root: {
        width: "70%",
        position: "relative"
    },
    deleteIcon: {
        position: "absolute",
        right: 0
    }
})(Chip);

const useStyles = makeStyles({
    formControl: {
        minWidth: '30%',
        backgroundColor: 'white',
        float: 'right'

    }
    ,
    selectBox: {
        width: '30%',
        float: 'right'
    },
    Select: {
        backgroundColor: 'white',
        // border:'1px solid black',
        borderRadius: '20px',
        float: 'right'

    }
})
export const DeliveryNewOrder = () => {
      /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.debug = function () {};
    console.info = function () {};
  }
    const { tableOrder } = useSelector(state => state.tableOrderReducer)
    const { menu } = useSelector(state => state.tableMenuReducer)
    const [customer, setCustomer] = useState(false)
    const dispatch = useDispatch()
    let res;
  let isInfo = false
  let switchOrder=[]
    useEffect(() => {
        getCustomerOrder(`/restaurant/customer-orders?hour=16`).then(
            (result) => {
                let response = result;
                console.log('checking ORDER DATA', response)
                res = response.filter(item => item.dining_type == 'takeaway')

                if (res.length >= 1) {

                    dispatch(setTableOrder(res))
                }



            })


        getCustomers('/restaurant/customers').then(
            (result) => {
                let response = result
                response.map(data => {
                    let d = data.date;
                    // let n=new Date()
                    // let m=n.split(' ')[2]
                    // console.log('check date',m)

                    // d=d.split(' ')[1]
                    console.log('parse date', d)
                })

                //  setArrayOne(result)
                setCustomer(response)
            }
        )
    }
        , [])

    const classes = useStyles()
  const [isToggleOn, setIsToggleOn] = useState(false)

    const handleSwitchClick = () => {
        setIsToggleOn(!isToggleOn)
      }
          const history = useHistory()
    console.log('here menu', menu)
    const handleClick = (item) => {
        const payload = {
            "name": null,
            "email": null,
            "phone": null,
            "address": null,
            "diningType": 'takeaway'
        }
        CreateDelCus('/restaurant/customer', payload).then(
            (result) => {
                let response = result
                dispatch(setOrderId(response.orderId))
                setTimeout(() => { history.push('/takeaway/menuOrder') }, 1200)

            }
        )
    }

    // const openMenu=(item)=>{
    //     console.log('here item ',item)
    //     dispatch(setBasket(item.orderMenuItem))
    //     dispatch(setTableMenu(item))

    // setTimeout(()=>{history.push('/takeaway/tableOrder')},1200)
    // }
    function tableDetail(id) {
        let order = tableOrder[id].orderMenuItem
        console.log('here your table order', tableOrder)
        console.log('here your order', order)
        console.log('here your res', res)
        const uniqueObjects = [...new Map(tableOrder[id].orderMenuItem.map(item => [item.menuItemId, item])).values()]

        dispatch(setBasket(uniqueObjects))
        dispatch(setOrderId(tableOrder[id].id))



        dispatch(setTableMenu(tableOrder[id]))
        setTimeout(() => { history.push('/takeaway/tableOrder') }, 200)
    }
    // function pushOrder(){
    //     console.log('exist menu ',menu)
    //     if(menu){
    // history.push('/takeaway/tableOrder')
    //     }
    // }

    if(tableOrder){
        tableOrder.forEach((item,index)=>{
switchOrder.push(item)
        })
    }
    let orderLength=switchOrder.length
    if(orderLength > 0 ){
        isInfo=true
    }

    if(isInfo){
        if(!isToggleOn){
            switchOrder=switchOrder.filter((data)=>data.status == 'progress' || data.status == 'new')
        }
        else{
            switchOrder=switchOrder
        }
    }
    const columns = [
        // {field:'id',headerName:'SNo',width:150},
        { field: 'billId', headerName: 'ID', width: 150 },
        { field: 'status', headerName: 'Status', width: 150 },

        { field: 'date', headerName: 'Time', width: 200, },
        { field: 'customerId', headerName: 'Customer Id', width: 150, },
        { field: 'id', headerName: 'Order Id', width: 150, },

        {
            field: 'detail', headerName: 'View Detail', width: 150,
            renderCell:
                (params) => (


                    <Btn title='View Order' height='30px' bgColor='' onClick={() => { tableDetail(params.rowIndex) }} />
                ),
            disableClickEventBubbling: true,
        }
        ,


    ]

    return (
        <div className='delivery-newOrder-main'>
            {/* {customer?<div className={classes.selectBox}>
<Autocomplete
className={classes.Select}
      id="combo-box-demo"
      options={customer}
      getOptionLabel={(option) => option.customerId.toString()+ "   " + option.customerName}
      style={{ width: 300 }}
      
      renderInput={(params) => <TextField {...params} label="Search" variant="outlined" />}
    />
</div>:''
}               */}
            <br />


            {<div className='delivery-newOrder-boxContainer'>
                <Row>
                {isInfo ? <div><FormGroup className='MatToggle'>
        <FormControlLabel
          control={<Switch
            color='primary'
            label="Completed"
            onChange={handleSwitchClick} />}
          label="Completed"
        />
      </FormGroup></div> : ''}
      
                    {/* Converting See Order Card into Table */}
                    {tableOrder ? <div style={{ width: '100%',marginTop:'30px' }} id='delivery-new-order-table'>{<DataGrid  autoHeight='true' rows={tableOrder} columns={columns} pageSize={15} />}</div> :
                        <p className='delivery-newOrder-line'>
                            There is not Order Yet
                        </p>}
                    {/* End Converting */}






                </Row>


            </div>
            }
            <div>

                <div className='delivery-newOrder-icon'><IconButton className='delivery-newOrder-iconBtn'>
                    <AddIcon onClick={handleClick} />
                </IconButton></div>
            </div>
        </div>
    )
}