export const dineTableReducer = (state= {dineTable:null},action) => {
    switch(action.type) {
        case "SET_DINETABLE":
            return {...state,dineTable:action.payload}

        case "SET_TABLE_STATUS":
            let {floorName, tableIndex, status} = action.payload
            let updatedDineTableData = state.dineTable.map(dt=>{
                if(dt.floorName == floorName){
                    dt.table[tableIndex].status = status
                }
                return dt
            })
            console.log({floorName, tableIndex, status})
            console.log("=====buddy",{updatedDineTableData})
            return {...state,dineTable:updatedDineTableData}

        default:
            return {...state}
    }
}