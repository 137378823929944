import React,{useEffect,useState} from 'react';

//import Para from '../../../../component/partials/Para'
//import Heading from '../../../../component/partials/Heading';
// import Input from '../../../../component/partials/Input'
import TextField from '@material-ui/core/TextField';
//import { withStyles } from "@material-ui/core/styles";
import {getCustomers} from '../../../../services/getCustomerList'
import {deliveryExistOrder} from '../../../../services/deliveryExistOrder'
import '../style.css'
//import Btn from '../../../../component/partials/Button';
import {CreateDelCus} from '../../../../services/createDelCus'
import {useHistory} from 'react-router-dom'
import {setOrderId} from '../../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
//import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
//import SearchIcon from '@material-ui/icons/Search';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Spinner } from '../../../../component/partials/Spinner';
import { getUsers } from '../../../../services/getUsers';
//import { BorderLeft } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';

const useStyles=makeStyles({
       root:{
       // border: '1px #e0dddd solid',
        borderRightColor:'#e0dddd',
        borderRightStyle: 'solid',
        borderRightWidth:'1px'
       },
        
        selectBox:{
            width:'30%',
            marginTop: '7%',
/*    float:'right',
    position:'absolute',
    right:'40px',
    top:'80px'*/
        },
        Select:{
            backgroundColor:'white',
            // border:'1px solid black',
            borderRadius:'20px',
            margin: '5%',
           // float:'left'
    
        },
        button:{
            marginLeft: '5%',    
        }
      })

export function PlaceOrder(){
          /*---------disable the console logs when production environment--------*/
  if (process.env.NODE_ENV === "production") {
        console.log = function () {};
        console.debug = function () {};
        console.info = function () {};
      }
        const classes=useStyles()
      //  let name,email,add;
      //  let phone=0
       // let [tel,setTel]=useState('')
        const history=useHistory()
        const [selectedCustomer,setSelectedCustomer]=useState()
        const [customer,setCustomer]=useState(false)
        const [isDisabled,setIsDisabled]=useState(false)
        const { orderId } = useSelector(state => state.orderIdReducer)
        const [allWaiters, setAllWaiters] = React.useState([])
        const [deliveryUser,setDeliveryUser]=useState(false)
        const [values, setValues] = React.useState({
                name: '',
                email: '',
                phone: '',
                address: '',
                diningType: 'delivery',
              });
              const handleChange = (prop) => (event) => {
                setValues({ ...values, [prop]: event.target.value });
                console.debug(event.target.value)
              };
        function setOrderIdFunc(){
                if(orderId){
                        history.push('/takeaway/menuOrder')
                }
        }
        useEffect(()=>{
                getUsers('/restaurant/users').then(
                    (result) => {
                        let resp = result
                        setAllWaiters(resp)                
                    }
                )
              },[])

        useEffect(()=>{
                getCustomers('/restaurant/customers?delivery=true').then(
                        (result)=>{
                        let response=result
                        response.map(data=>{let d=data.date;
                // let n=new Date()
                // let m=n.split(' ')[2]
                // console.log('check date',m)            
                // d=d.split(' ')[1]
                        console.log('parse date',d)})
                        //  setArrayOne(result)
                        setCustomer(response)
                        }
                ) 
        },[])

        const afterExistCust=(customer)=>{
                console.log('here your customer Information',customer)
                if(customer){
                        
                        deliveryExistOrder(`/restaurant/order-bill?customerId=${customer.customerId}&diningType=delivery&userId=${deliveryUser.id}`).then(
                                (result)=>{
                                        let response=result
                                        console.log('customer order id with customer id ',result)
                                        dispatch(setOrderId(response.orderId))
                                        setTimeout(()=>{ history.push('/takeaway/menuOrder')},120)
                                }
                        )
                        // setTimeout( setOrderIdFunc,120)
                }
        }
        const handleClick=()=>{
                console.log(values.name,values.email,values.address,values.phone,deliveryUser.id)
               //let mail=email.indexOf('@')
                //if(name && email && add && phone && mail>0){
                        if(values.name && values.email && values.address && values.phone ){
                        
                        console.log('here how customer payload',values)
                        CreateDelCus('/restaurant/customer?deliveredby='+deliveryUser.id ,values).then(
                                (result)=>{
                                        let response=result
                                        dispatch(setOrderId(response.orderId))
                                        setIsDisabled(true)
                        setTimeout(()=>{ history.push('/takeaway/menuOrder')},120)
                                }
                        )
                        // setOrderIdFunc()
                }
        }

        const dispatch=useDispatch()
      //  const {delOrder} =useSelector(state=>state.takeawayOrderReducer)

        return(
                <div  >
                <Grid container spacing={3} >  
                              
                  <Grid item xs={6} className={classes.root}>
                  <Typography variant="h6" gutterBottom style={{ marginTop:'7%'}}>
        Search with existing customer
      </Typography>  
                  {
                        customer?
                        <div style={{ marginTop:'7%'}}>
                        <Autocomplete
                        className={classes.Select}
                        id="combo-box-demo"
                        options={customer}
                        getOptionLabel={(option) => option.customerId.toString()+ ' | ' + option.customerName +' | '+option.customerEmail+' | '+option.customerPhone}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Search customer" variant="outlined" />}
                        onChange={(event, value) => setSelectedCustomer(value)}  
                        rows={2}                     
                        />
                        <Autocomplete
                        className={classes.Select}
                        id="combo-box-demo"
                        options={allWaiters}
                        getOptionLabel={(option) => option.id.toString()+ ' | ' + option.name +' | '+option.role}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Search Delivery boy" variant="outlined" />}
                        onChange={(event, value) => setDeliveryUser(value)}    
                        rows={2}                   
                        />
                        <IconButton onClick={()=>{afterExistCust(selectedCustomer)}} className={classes.button}>
                                <AddShoppingCartIcon  color='primary' />
                        </IconButton>
                        </div>
                        :<Spinner />
                  }
                  </Grid>
                <Grid item xs={6}>
                <div className='delivery-order-main' style={{ marginTop:"7%", width:"585px" , height:'550px',boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}}>
{/* header nav */}
<div className="placeOrder-header-nav"
style={{ height:'10px'}}>
        <div style={{ marginTop:'-2%'}}>
        Add Customer Detail
        </div></div>                  
<div className='placeOrder-body' style={{ padding:'10px'}}>

<div className='delivery-order-input'>
<TextField
        id="filled-secondary"
        label="Name"
        variant="outlined"
        color="white"
        fullWidth
       margin='dense'
       multiline={true}
       rows={2}
       onChange={handleChange('name')}
        required
      />
</div>

<TextField

        id="filled-secondary"
        label="Email"
        variant="outlined"
        color="white"
        size='large'
        margin='dense'
        fullWidth
        multiline={true}
       rows={2}
        onChange={handleChange('email')}
        required
/>

<TextField
        id="filled-secondary"
        label="Contact No"
        variant="outlined"
        color="white"
        size='large'
        fullWidth
        margin='dense'
        multiline={true}
        rows={2}
        type="number"
        onChange={handleChange('phone')}
        required
      />


<TextField
        id="filled-secondary"
        label="Address"
        variant="outlined"
        color="white"
        fullWidth
        size='large'
        margin='dense'
        multiline={true}
        rows={2}
        onChange={handleChange('address')}
        required
/>
<hr/>
        <div >
        Delivery boy
        </div>
        {
                 customer?                                
                <Autocomplete
                id="combo-box-demo"
                options={allWaiters}
                getOptionLabel={(option) => option.id.toString()+ ' | ' + option.name +' | '+option.role}
                fullWidth
                rows={2}
                renderInput={(params) => <TextField {...params} label="Search Delivery boy" variant="outlined" />}
                onChange={(event, value) => setDeliveryUser(value)}                       
                />
                :<Spinner />
        }
<button className='delivery-order-btn' style={{border:'none' , marginTop:'3%' , height:'38px',width:'112px', borderRadius:'2px'}} disabled={isDisabled} onClick={handleClick}>Submit</button>

</div>


        </div>
                  </Grid>
                 
                </Grid>
              </div>
                            
     
    )
}

