import { combineReducers, createStore } from "redux";
import {userReducer,usersReducer} from './reducers/userReducer'
import {dineTableReducer} from './reducers/dineTableReducer'
import{reservedTable} from './reducers/reservedReducer'
import {tableOrderReducer} from './reducers/tableOrderReducer'
import {menuDataReducer} from './reducers/menuDataReducer'
import {basketReducer,oldItemReducer,cartReducer,wholeBasketReducer,duplicatedWholeBasketReducer,itemLengthReducer} from './reducers/basketReducer'
import {categoryItemReducer,cardItemReducer,tableItemReducer,categoryNameReducer} from './reducers/categoryItem'
import {currentTableReducer,totalTableInfoReducer} from './reducers/tableOrderReducer'
import {tableMenuReducer} from './reducers/tableMenu'
import{billReducer,billInfoReducer} from './reducers/billReducer'
import{customerReducer,singleCustomerReducer} from './reducers/customerReducer'
import {menuIdReducer} from './reducers/tableMenu'
import {kitchenOrderReducer,postOrderReducer,isPrintReducer} from './reducers/kitchenOrderReducer'
import {orderIdReducer} from './reducers/waiterReducer'
import { takeawayOrderReducer } from "./reducers/takeawayReducer";
import {waitersInfoReducer} from './reducers/currentWaiterReducer';
import {inventoryReducer} from './reducers/inventoryReducer'

export const store = createStore(combineReducers({
    userReducer,
    usersReducer,
    dineTableReducer,
    billInfoReducer,
    takeawayOrderReducer,
    itemLengthReducer,
    duplicatedWholeBasketReducer,
    wholeBasketReducer,
    oldItemReducer,
    totalTableInfoReducer,
    isPrintReducer,
    reservedTable,
    tableOrderReducer,
    tableItemReducer,
    menuDataReducer,
    basketReducer,
    cartReducer,
    categoryItemReducer,
    categoryNameReducer,
    currentTableReducer,
    tableMenuReducer,
    billReducer,
    customerReducer,
    postOrderReducer,
    menuIdReducer,
    customerReducer,
    kitchenOrderReducer,
    orderIdReducer,
    cardItemReducer,
    singleCustomerReducer,
    waitersInfoReducer,
    inventoryReducer,
}))