import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Avatar, IconButton } from '@material-ui/core'
import Btn from '../../../component/partials/Button';
import { setCustomer } from '../../../redux/actions';
import { getCustomers } from '../../../services/getCustomerList';
import PureBreadcrumbs from '../../../component/Breadcrumb';
import { Spinner } from '../../../component/partials/Spinner';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getUsers } from '../../../services/getUsers';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import InputAdornment from '@material-ui/core/InputAdornment';
import { putData,postData } from '../../../services/getData';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import EditIcon from '@material-ui/icons/Edit';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { MdAdd } from 'react-icons/md';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {GridToolbar } from "@material-ui/data-grid";

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& .tr-table-order-new': {
      backgroundColor: 'rgba(255, 179, 0, 0.98)',
    },
    '& .tr-table-order-completed': {
      backgroundColor: '#fe0101',
    },
    '& .tr-table-order-in-progress': {
      backgroundColor: '#86d689',
    },
  },
  box: {

    minWidth: '500px',
    '@media print': {
      minWidth: '600px'
    }

  },
  screen: {
    padding: '0px',
    textAlign: 'center',
    '@media print': {
      textAlign: 'left'
    }
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function BillTableToolBar() {
    /*---------disable the console logs when production environment--------*/
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
  return (
    <div>
      
      <GridToolbar />
    {/*<GridToolbarFilterButton />
    <GridToolbarDensitySelector />
    <GridToolbarExport />*/}
  </div>
  );
}

export const OrdersDetail = () => {
  const { customer } = useSelector(state => state.customerReducer)
  const { user } = useSelector(state => state.userReducer)
  const [open, setOpen] = React.useState(false);
  const [openAddMenu, setOpenAddMenu] = React.useState(false);
  const [existingQty, setExistingQty] = React.useState(0);
  const [allWaiters, setAllWaiters] = React.useState([]);

  const [allSuppliers, setAllSuppliers] = React.useState([]);
  const [allProducts, setAllProducts] = React.useState([]);
  const [allPurchaseItems, setAllPurchaseItems] = React.useState([]);

  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [selectedCustomer,setSelectedCustomer]=useState();
  const [receivedData, setReceivedData] = React.useState(true);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('loading....');
  // const [selectedCustomer,setAllSuppliers]=useState()


  {/* <Eidt button function & Popu> */ }
  const addMenuClickkk = () => {
    //setEditCateg(get_icon_Key(menuData,data))   
    console.debug('from add popup open')
    //setBackdropOpen(true);
    setBackdropOpen(true);
    // setOpen(true);
    setOpenAddMenu(true);
  }
  // <--popu-->
  const useStyles = makeStyles((theme) => ({

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));
  const [item, setItem] = React.useState('');
  const [openn, setOpenn] = React.useState(false);

  const handleChangee = (event) => {
    setItem(event.target.value);
  };
  const handleClosee = () => {
    setOpenn(false);
  };
  const handleOpenn = () => {
    setOpenn(true);
  };

  const dispatch = useDispatch()
  const classes = useStyles();

  {/* <Eidt button function End> */ }
  function getProduct() {
    getCustomers('/restaurant/purchase-items').then(
      (result) => {
        let response = result
       // dispatch(setCustomer(response));
        setAllPurchaseItems(response)
        setReceivedData(false) ;
      }
    )
  }
  useEffect(() => {
    getProduct();  
  }, [])
  const handleClose = () => {
    setOpen(false);
  };
  const [values, setValues] = React.useState({
    id: '',
    purchase_order_id: '',
    quantity: '',
    quantity_type: '',
    price: '',
    product_id:'',
  });

  /*const [purchaseOrderItems, setPurchaseOrderItems] = React.useState({
    id: '',
    purchase_order_id: '',
    quantity: '',
    quantity_type: '',
    price: '',
    product_id:'',
  });*/

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    console.debug(prop + " : " + event.target.value)
  };
  const [dialogType, setDialogType] = React.useState("add");

  const handleClickOpen = (rowdata, clickType) => {
    
    setDialogType(clickType)
    if(rowdata)
    {
      /*setValues(rowdata)
      setExistingQty(rowdata.quantity)
      console.debug("rowdata:" + rowdata.id, );*/
  
      values.id=rowdata.id
      values.purchase_order_id=rowdata.purchaseOrderId
      values.quantity=rowdata.quantity
      values.quantity_type=rowdata.quantityType
      values.price=rowdata.price
      values.product_id=rowdata.productId
    }
   if(clickType==='add')
    {
      setValues(
        {
         // id: '',
          purchase_order_id: '',
          quantity: '',
          quantity_type: '',
          price: '',
          product_id:'',
        }
      )
    }    
   //setOpen(true);
    setOpenAddMenu(true);
  };
  useEffect(() => {
    getUsers('/restaurant/users').then(
      (result) => {
        let resp = result
        setAllWaiters(resp)
      }
    )
  }, [])
  useEffect(() => {
    getUsers('/restaurant/suppliers').then(
      (result) => {
        let resp = result
        setAllSuppliers(resp)
      }
    )
    getUsers('/restaurant/product').then(
      (result) => {
        let resp = result
        setAllProducts(resp)
      }
    )
  }, [])


  const postnDeleteMenu = () => {
    var url = null;
   // var newQty = parseInt(existingQty);
    if (dialogType === "add") {
      addProduct();
    }  
    else{
      updateInvoice();
    }
  }

  const updateInvoice = () => {
    putData('/restaurant/purchase-item/'+values.id, values).then(
            (result) => {
                if(result)
                {
                    setSeverity('success');
                    setAlertMessage('Product is added, system created id is: '+result.id)
                    getProduct();
                    setOpenAddMenu(false);
                    setOpen(true);
                }
                else{
                    setSeverity('error');
                    setAlertMessage(result.message);
                    setOpenAddMenu(false);
                    setOpen(true);
                }
                console.log('add Product status')
                
            }            
        )
  }

  const addProduct = () => {
      postData('/restaurant/purchase-items', values).then(
            (result) => {
                if(result)
                {
                    setSeverity('success');
                    setAlertMessage('Product is added, system created id is: '+result)
                    getProduct();
                    setOpenAddMenu(false);
                    setOpen(true);
                }
                else{
                    setSeverity('error');
                    setAlertMessage(result.message);
                    setOpenAddMenu(false);
                    setOpen(true);
                }
                console.log('add Product status')
                
            }            
        )
  }

 /* const postnDeleteMenu = () => {
    var url = null;
    if (dialogType === "edit") {
      url = `/restaurant/editProduct/${values.id}`
      putData(url, values).then(
        (result) => {  
          console.log(`here deleted one menu item id is`)
        }
      )
    }
    else {
      url = `/restaurant/product`
      postData(url, values).then(
        (result) => {
            if(result.id)
            {
               console.debug('supplier is added, system created id is: '+result.id);
               setSeverity('success');
                  setAlertMessage('Product is added, system created id is: '+result.id)
            }
            else{
               console.error('Error supplier...');
               setSeverity('error');
                  setAlertMessage(result.message);
            }
        }            
    )
    }
    getProduct();
    setOpenAddMenu(false);
  }*/


  const [editRowsModel, setEditRowsModel] = React.useState({});

  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model);
    console.debug("actionType???");
  }, []);
  const handleCellEditCommit = React.useCallback(
    () => {
      console.debug("2-actionType???");
    },
    [],
  );
  console.debug("editRowsModel: " + editRowsModel);


  let role = user.role;
  let columns;
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'purchaseOrderId',
      sort: 'asc',
    },
  ]);
  /*
{
"id": 2,
"purchaseOrderId": 108,
"productId": 18,
"quantity": 100,
"quantityType": "piece",
"name": "Eggs",
"price": 8,
"supplierId": 52,
"supplierName": "Meat Supplier",
"entryDate": "2021-05-20T22:00:00.000+00:00"
}
   */ 
  {
      columns = [
        { field: 'id', headerName: 'Entry #', width: 150 },
        { field: 'purchaseOrderId', headerName: 'Order #', width: 150 },
        { field: 'productId', headerName: 'Product #', width: 150 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'quantity', headerName: 'Quantity', width: 150 },
        { field: 'quantityType', headerName: 'Unit', width: 150 },
        {
          field: 'price', headerName: 'Unit price', width: 150,
          valueFormatter: (params) => {
            return `${params.value} Rs`;
          }
        },
        { field: 'supplierName', headerName: 'Supplier', width: 150 },
        { field: 'supplierId', headerName: 'Supplier #', width: 150 },
        { field: 'entryDate', headerName: 'Date', width: 150 },
        {
          field: 'invEdit', headerName: 'Edit', width: 150,
          renderCell:
            (params) => {
              return (
                <IconButton aria-label="increase" >
                  <EditIcon color="secondary" onClick={() => { handleClickOpen(params.row, "edit") }} />
                </IconButton>
              )
            },
          disableClickEventBubbling: true,
        },
      ]
  }
  //console.log("customer: "+customer);
  return (
    <div className='container' style={{ maxWidth: '100%' }}>

      <PureBreadcrumbs className='onPrintDine' />

      {/*---- Main table----*/}
      {allPurchaseItems ?
        <div style={{  width: '100%' }} className={classes.root, 'onPrintDine'} id='orders-details-list-table'>
          <DataGrid 
          rows={allPurchaseItems} 
          columns={columns} pageSize={100} 
          autoHeight='true'
            editRowsModel={editRowsModel}
            onEditRowsModelChange={handleEditRowsModelChange}
            onCellEditCommit={handleCellEditCommit}
            sortModel={sortModel}
            density="compact"
            loading={receivedData}
            components={{
              Toolbar: BillTableToolBar
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='onPrintDine'>

            <IconButton onClick={() => { handleClickOpen(null, "add") }}><MdAdd style={{ fontSize: "42px" }} /></IconButton>

          </div>

        </div>
        : <Spinner />}
   
      {/* <Edit Button Dialog> */}
      <Dialog open={openAddMenu} onClose={()=>setOpenAddMenu(false)} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
        <DialogTitle id="order-details-form-dialog-title">
          {
            dialogType === "edit" ?'Edit Order': 'Add Order'
          }
        </DialogTitle>
        <DialogContent>
             { /*<TextField
                autoFocus
                fullWidth
                margin="dense"
                id="product-details"
                label="Name"
                value={values.name}
                type="text"
                width='200'
                margin-top= '8px'
                onChange={handleChange('name')}
              />*/}
              {
            /*<Autocomplete
            fullWidth
            //className={classes.Select}
            id="product-details-combo-box-demo-2"
            options={allProducts}
            getOptionLabel={(option) => option.name}
            style={{ width: 215 }} 
            value={values.id}                       
            renderInput={(params) => <TextField {...params} label="Product"  />}
            //onChange={(event, value) => setSelectedCustomer(value)}
            /> */
          }
           <FormControl className={classes.formControl} >
              <InputLabel id="se-product-id-select-label" >Product</InputLabel>
              <Select
                autoWidth
                labelId="se-product-id-label"
                id="se-product-id"
                value={values.product_id}
                onChange={handleChange('product_id')}
                label="Product"
                margin="dense">
                {
                  allProducts.map(
                    (item,index)=>{
                      console.debug("Items: ",item.id,item.name)
                        return(
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        )
                  }
                  )
                }
              </Select>
          </FormControl>
          {values.quantity_type}     
          {
            <TextField
            fullWidth
              margin="dense"
              id="product-details"
              label="Quantity"
               value={values.quantity}
              type="number"
              onChange={handleChange('quantity')}
            />
          }
            {
            <TextField
              fullWidth
              margin="dense"
              id="product-details"
              label="Unit Price"
              value={values.price}
              type="number"
              onChange={handleChange('price')}
            />
          }

         
          {/*
            <Autocomplete
            fullWidth
            //className={classes.Select}
            id="suplier-details-combo-box-demo-2"
            options={allSuppliers}
            getOptionLabel={(option) => option.name}
            style={{ width: 215 }} 
            value={values.supplier_id}                       
            renderInput={(params) => <TextField {...params} label="Supplier"  />}
            //onChange={(event, value) => setSelectedCustomer(value)}
            /> */
          }

        </DialogContent>
        <DialogActions>
        <Button onClick={()=>setOpenAddMenu(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => { postnDeleteMenu() }} color="primary">
            Submit
          </Button>
        </DialogActions>

      </Dialog>
      {/* <Edit Button Dialog End> */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity={severity} onClose={handleClose}>
        {alertMessage}
      </Alert>
    </Snackbar>
    </div>
  )
}