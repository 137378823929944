import React from 'react';
import { GeneralSetup } from "../../pages/GeneralSetup";
import { AdminSetupPage } from "../../pages/AdminSetupPage";
import { DepartmentSetupPage } from "../../pages/DepartmentSetupPage";
import { SubDepartmentSetupPage } from "../../pages/SubDepartmentSetupPage";
import { DeviceSetupPage } from "../../pages/DeviceSetupPage";
import { Settings, DesktopMac, Store, AccountBox } from "@material-ui/icons";
    // import myIcon from '../../assets/icons/'
import AddCustomer from '../../pages/Layout/reception/addCustomer'
import {CustomerList} from '../../pages/Layout/reception/CustomerList'
import {RecepDinning} from '../../pages/Layout/reception/dinningArea'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import {Menu,DinningArea,Bills,Logout,Customer} from '../../component/partials/CustomDasboarIcon'


export const RecepMenu = [
    {
        id:1,
        menuName: 'Customer',
        menuDescription: 'General setups menus',
        path: '/customerList',
        component: CustomerList,
        exact: true,
        icon :<Customer />,
        submenu: [
            // {
            //     id:3,
            //     menuName: 'Customer List',
            //     menuDescription: 'To Setup admins',
            //     path: '/addCustomer/customerList',
            //     component: CustomerList,
            //     icon: <AccountBox />,
            // },
            // {
            //     id: 4,
            //     menuName: 'Add customer',
            //     menuDescription: 'To Setup departments',
            //     path: '/addCustomer/addCustomer',
            //     component: AddCustomer,
            //     icon: <AccountBox />
            // }
        ]
    },
    {
        id:2,
        menuName: 'Dining Area',
        menuDescription: 'Device mac id setup menus',
        path: '/reception',
        component: RecepDinning,
        exact: true,
        icon: <DinningArea />,
        submenu: []
    },
    // {
    //     id: 5,
    //     menuName: 'Attendance',
    //     menuDescription: 'Inventory setup menus',
    //     path: '/attendence',
    //     component: DeviceSetupPage,
    //     exact: true,
    //     icon: <Store />,
    //     submenu: []
        
    // },
    {
        id: 6,
        menuName: 'Logout',
        menuDescription: 'Inventory setup menus',
        path: '/',
        component: DeviceSetupPage,
        exact: true,
        icon: <Logout />,
        submenu: []
        
    }
    ];
