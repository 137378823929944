import React from 'react';
import {Home} from '../../pages/Home';
import {KitchenTableOrder} from '../../pages/Layout/kitchen/TableOrder';
import { FcShipped,FcFinePrint,FcDocument,FcPaid,FcExport } from "react-icons/fc";
import { IconContext } from "react-icons";
import {Logout,Inventry} from '../../component/partials/CustomDasboarIcon';

export const storekeeperMenu=[
    {
        id:1,
        menuName: 'Inventory',
        menuDescription: 'General setups menus',
        path: '/storekeeper/inventry',
        component: KitchenTableOrder,
        exact: true,
        icon :<Inventry />,
        submenu:[
            /*{
                    id:7,
                    menuName: 'Add Product',
                    menuDescription: 'To Setup admins',
                    path: '/storekeeper/inventry/addProduct',
                    component: KitchenTableOrder,
                   
                },
                {
                    id:8,
                    menuName: 'Product Detail',
                    menuDescription: 'To Setup admins',
                    path: '/storekeeper/inventry/productDetail',
                    component: KitchenTableOrder,
                  
                },*/
            ]
    },
    {
        id:2,
        menuName: 'Transactions',
        menuDescription: 'Transactions menu',
        path: '/storekeeper/transactions',
        component: KitchenTableOrder,
        exact: true,
        icon :<IconContext.Provider  value={{ style: { marginLeft:"8px" } }}>
        <div>
        <FcExport size="36px"/>
        </div>
      </IconContext.Provider>,
        submenu:[]
    },
    {
        id:3,
        menuName: 'Invoices',
        menuDescription: 'Invoice menu',
        path: '/storekeeper/invoices',
        component: KitchenTableOrder,
        exact: true,
        icon :<IconContext.Provider  value={{ style: { marginLeft:"8px" } }}>
        <div>
        <FcDocument size="36px"/>
        </div>
      </IconContext.Provider>,
        submenu:[]
    },
    {
        id:4,
        menuName: 'Order Items',
        menuDescription: 'General setups menus',
        path: '/storekeeper/orders',
        component: KitchenTableOrder,
        exact: true,
        icon :<IconContext.Provider  value={{ style: { marginLeft:"8px" } }}>
        <div>
        <FcPaid size="36px" />
        </div>
      </IconContext.Provider>,
        submenu:[]
    },
    {
        id:5,
        menuName: 'Suppliers',
        menuDescription: 'Supplier menu',
        path: '/storekeeper/suppliers',
        component: KitchenTableOrder,
        exact: true,
        icon :<IconContext.Provider  value={{ style: { marginLeft:"8px" } }}>
        <div>
        <FcShipped size="36px" />
        </div>
      </IconContext.Provider>,
        submenu:[]
    },
    {
        id:6,
        menuName: 'Reports',
        menuDescription: 'Storekeeper reporting',
        path: '/storekeeper/reports',
        component: KitchenTableOrder,
        exact: true,
        icon :<IconContext.Provider  value={{ style: { marginLeft:"8px" } }}>
        <div>
        <FcFinePrint size="36px"  />
        </div>
      </IconContext.Provider>,
        submenu:[]
    },
    {
        id:7,
        menuName: 'Logout',
        menuDescription: 'General  menus',
        path: '/',
        component: KitchenTableOrder,
        exact: true,
        icon :<Logout />,
        submenu:[]
    }
]
