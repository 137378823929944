import React from 'react'


function Fourseats({fill,theWidth=130, theHight=100 }){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width={theWidth} height={theHight} viewBox="0 0 220 173">
        <defs>
          <linearGradient id="linear-gradient" x1="0.494" y1="-0.012" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fb8603"/>
            <stop offset="1" stopColor="#df851d"/>
            <stop offset="1" stopColor="#804706"/>
          </linearGradient>
          <filter id="Rectangle_379" x="37.5" y="46.036" width="146" height="83.534" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="5.5" result="blur"/>
            <feFlood floodOpacity="0.161"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <linearGradient id="linear-gradient-5" x1="0.5" x2="0.5" y2="1.79" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f29022"/>
            <stop offset="1" stopColor="#ec922b"/>
          </linearGradient>
          <clipPath id="clip-svg_4">
            <rect width="220" height="173"/>
          </clipPath>
        </defs>
        <g id="svg_4" data-name="svg 4" clipPath="url(#clip-svg_4)">
          <rect width="220" height="173" fill="#fff"/>
          <g id="Group_479" data-name="Group 479" transform="translate(-3842 -14339.68)">
            <g id="Group_478" data-name="Group 478" transform="translate(3896 14379.68)">
              <path id="Path_237" data-name="Path 237" d="M31.84,6.594v.455A15.92,15.92,0,1,1,0,7.049V6.594C0-2.2,31.84-2.2,31.84,6.594Z" transform="translate(107.212 23.522) rotate(-179)" opacity="0.9" fill={fill}/>
              <path id="Path_235" data-name="Path 235" d="M31.84,6.594v.455A15.92,15.92,0,1,1,0,7.049V6.594C0-2.2,31.84-2.2,31.84,6.594Z" transform="matrix(1, 0.017, -0.017, 1, 75.516, 68.684)" opacity="0.9" fill={fill}/>
              <path id="Path_236" data-name="Path 236" d="M31.84,6.594v.455A15.92,15.92,0,1,1,0,7.049V6.594C0-2.2,31.84-2.2,31.84,6.594Z" transform="matrix(1, 0.017, -0.017, 1, 12.097, 69.139)" opacity="0.9" fill={fill}/>
              <path id="Path_238" data-name="Path 238" d="M31.84,6.594v.455A15.92,15.92,0,1,1,0,7.049V6.594C0-2.2,31.84-2.2,31.84,6.594Z" transform="translate(43.531 23.522) rotate(-179)" opacity="0.9" fill={fill}/>
              <g transform="matrix(1, 0, 0, 1, -54, -40)" filter="url(#Rectangle_379)">
                <rect id="Rectangle_379-2" data-name="Rectangle 379" width="113" height="50.534" rx="4" transform="translate(54 60.54)" fill="#fff"/>
              </g>
            </g>
            <rect id="Rectangle_619" data-name="Rectangle 619" width="8.422" height="50.534" rx="4" transform="translate(3896 14400.217)" fill={fill}/>
          </g>
        </g>
      </svg>
      


    )
}


export default Fourseats