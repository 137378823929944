import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { postData } from '../../../services/getData';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Avatar, IconButton } from '@material-ui/core';
import { getCustomers } from '../../../services/getCustomerList';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { getUsers } from '../../../services/getUsers';
import InputAdornment from '@material-ui/core/InputAdornment';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import AssignmentReturnRoundedIcon from '@material-ui/icons/AssignmentReturnRounded';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { pink } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
const useStyles = makeStyles({
  root: {
    width: '100%',
    '& .tr-table-order-new': {
      backgroundColor: 'rgba(255, 179, 0, 0.98)',
    },
    '& .tr-table-order-completed': {
      backgroundColor: '#fe0101',
    },
    '& .tr-table-order-in-progress': {
      backgroundColor: '#86d689',
    },
  },
  box: {
    minWidth: '500px',
    '@media print': {
      minWidth: '600px'
    }

  },
  screen: {
    padding: '0px',
    textAlign: 'center',
    '@media print': {
      textAlign: 'left'
    }
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
function QuickSearchToolbar(props) {
  //const classes = useStyles();

  return (
   // <div className={classes.root}>
    <div >
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
       // className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export const Inventory = () => {
  const { customer } = useSelector(state => state.customerReducer)
  const { user } = useSelector(state => state.userReducer)
  const [open, setOpen] = React.useState(false);
  const [openAddMenu, setOpenAddMenu] = React.useState(false);
  const [existingQty, setExistingQty] = React.useState(0);
  const [allWaiters, setAllWaiters] = React.useState([])
  const [allSuppliers, setAllSuppliers] = React.useState([])
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('loading....');
  const [receivedData, setReceivedData] = React.useState(true);

  const useStyles = makeStyles((theme) => ({

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));
  const [item, setItem] = React.useState('');
  const [openn, setOpenn] = React.useState(false);
  const [productOutOpen, setProductOutOpen] = React.useState(false);
  const [stockError, setStockError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");  
  const [isSubmitDisabled, setSubmitDisabled] = React.useState(true);
  const [selectedEmployee,setSelectedEmployee]=useState();
  const [selectedDepartment,setSelectedDepartment]=useState();
  
  const [allInventory, setAllInventory] = React.useState([])
  const [searchText, setSearchText] = React.useState('');
  //const [rows, setRows] = React.useState();
  const [rows, setRows] = React.useState(allInventory);
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = allInventory.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  const handleChangee = (event) => {
    setItem(event.target.value);
  };
  const handleClosee = () => {
    setOpenn(false);
  };
  const handleOpenn = () => {
    setOpenn(true);
  };

 //popu//
 const addMenuClickk = () => {
  //setEditCateg(get_icon_Key(menuData,data))   
  console.debug('from add popup open')
  //setBackdropOpen(true);
  setBackdropOpen(true);
  //setOpen(true);
  setOpenAddMenu(true);

}



const addProduct = () => {
  let rend = {
     // "id": values.id,
     "name":values.name,
      "quantity_type": values.quantity_type,
      "quantity": 0
  }
  if (values.name, values.quantity_type) {
    postData('/restaurant/add-product', rend).then(
          (result) => {
              if(result.id)
              {
                  setSeverity('success');
                  setAlertMessage('Product is added, system created id is: '+result.id)
                  apiCall();
                  setOpenAddMenu(false);
                  setOpen(true);
              }
              else{
                  setSeverity('error');
                  setAlertMessage(result.message);
                  setOpenAddMenu(false);
                  setOpen(true);
              }
              console.log('add Product status')
              
          }            
      )
      console.debug("form: "+rend)
  }    
 // handleClose()
}

// end Popu//  
  const dispatch = useDispatch()
  const classes = useStyles();


   const formatter = new Intl.NumberFormat('en-US', {
     minimumFractionDigits: 0,
     maximumFractionDigits: 2,
   });

  function apiCall() {
    getCustomers('/restaurant/product').then(
      (result) => {
        let response = result
        //dispatch(setCustomer(response));
        setAllInventory(response);
        setRows(response);
        setReceivedData(false);
      }
    )
  }
  useEffect(() => {
    apiCall();
  }, [])
  const [values, setValues] = React.useState({
    id: '',
    quantity: '',
    quantity_type: '',
    name: '',
    price: '',
    supplier_id: '',
    transaction_item: '',
    transaction_type: '',
    employee: '',
    department: '',
  });
  const handleChange = (prop) => (event) => {
    
    if(prop === 'department' )
    {
      setValues({ ...values, [prop]: event.target.value.department });
    }
    else if(prop === 'quantity')
    {
      const theValue=formatter.format(event.target.value);
      if(theValue <= existingQty || dialogType==='increase')
        { 
          setSubmitDisabled(false);
          setStockError(false);
          setErrorMsg("")
        }
        else{
          setStockError(true);
          setSubmitDisabled(true);
          setErrorMsg("Quantity cannot be less than in stock.")
        }
        //check if the value entered has a decimal value
        if(!(theValue.indexOf(".") == -1)){       
          if(event.target.value.split(".")[1].length>2)
          {
            setStockError(true);
            setSubmitDisabled(true);
            setErrorMsg("Quantity cannot have more than two decimal value")
          }
        }
      setValues({ ...values, [prop]: theValue });
    }
    else{
      setValues({ ...values, [prop]: event.target.value });
    }   
   // console.debug(prop + " : " + event.target.value);
  };
  const [dialogType, setDialogType] = React.useState("add");

  const handleClickOpen = (rowdata, clickType) => {
    setDialogType(clickType)
    if(clickType==='add')
    {
     setValues( {
        id: '',
        quantity: '',
        quantity_type: '',
        name: '',
        price: '',
        supplier_id: '',
      }); 
    }
    if(rowdata)
    {
      setValues(rowdata)
      setExistingQty(rowdata.quantity)
      console.debug("quantity:" + rowdata.quantity)
    }

    if(clickType==='add')
    {      
      setOpenAddMenu(true);
    }else
    {
      setProductOutOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setProductOutOpen(false);
    setStockError(false);
    setErrorMsg("")
  };

  useEffect(() => {
    getUsers('/restaurant/users').then(
      (result) => {
        let resp = result
        setAllWaiters(resp)
      }
    )
  }, [])
  useEffect(() => {
    getUsers('/restaurant/suppliers').then(
      (result) => {
        let resp = result
        setAllSuppliers(resp)
      }
    )
  }, [])

  const postnDeleteMenu = () => {
    console.debug("the Depart",selectedDepartment)
    var url = null;
    var newQty = parseInt(existingQty);
    if (dialogType === "add") {
      addProduct();
    }
    else {
      if (dialogType === "increase") {
        newQty = parseInt(existingQty) + parseInt(values.quantity);
      }
      else{
        newQty = parseInt(existingQty) - parseInt(values.quantity);
      }      
      
      if (newQty<0)
      {
        setSeverity('warning');
        setAlertMessage('Quantity cannot be: '+newQty)
        setOpen(true);
      }
      else{
        //values.quantity=newQty
        var inventoryData={
            transaction_item: values.id,
            quantity: values.quantity,
            transaction_type: 'added',
           // employee: selectedEmployee.id,
            employee: values.employee,
            department: values.department,
         }
         if(dialogType === "decrease")
         {          
          var theMinusQty=values.quantity;
          theMinusQty=-theMinusQty;
          inventoryData.quantity= theMinusQty;

          inventoryData.transaction_type= 'removed';
         }
      //  console.debug("the Employee",values.employee)
        url = `/restaurant/inventory-transaction`;
        postData(url, inventoryData).then(
          (result) => {
            console.log(`here deleted one menu item id is ${newQty}`);
            setSeverity('success');
            setAlertMessage('Product updated: '+values.id)
            setProductOutOpen(false);
            setOpenAddMenu(false);
            setOpen(true);
            apiCall();
          });        
      }     
        console.log(`here deleted one menu item id is ${newQty}`);
    }
  }

  const [editRowsModel, setEditRowsModel] = React.useState({});
  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model);
    console.debug("actionType???");
  }, []);
  const handleCellEditCommit = React.useCallback(
    () => {
      console.debug("2-actionType???");
    },
    [],
  );
  console.debug("editRowsModel: " + editRowsModel); 
  let role = user.role;
  let columns;
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);
  {
      columns = [

        { field: 'id', headerName: 'Product #', width: 120 },
        { 
          field: 'name', headerName: 'Name', width: 200, editable: true, 
          renderCell:
          (params) => {
            return (
              <Typography variant="subtitle2" gutterBottom component="div">
              {params.value}
              </Typography>
            )
          },
      },
        { field: 'quantity', headerName: 'In stock', width: 150,
        renderCell:
          (params) => {
            if(params.value<5)
            { 
              return (
                <div>
                  {Number.isInteger(params.value)?params.value:formatter.format(params.value)} 
                  <Tooltip TransitionComponent={Zoom} title="Low inventory!!">
                    <WarningAmberRoundedIcon sx={{ color: pink[500],fontSize: 16 }}  />
                  </Tooltip>
              </div>
            )
          }
            else{
              return (
              <Typography variant="subtitle2" gutterBottom component="div">
                {Number.isInteger(params.value)?params.value:formatter.format(params.value)} 
              </Typography>)
            }
          }, 
      },
        { field: 'quantity_type', headerName: 'Unit', width: 150 },
        {
          field: 'invOut', headerName: 'Inventory out', width: 200,
          renderCell:
            (params) => {
              return (
                <IconButton aria-label="decrease" >
                  <AssignmentTurnedInRoundedIcon color="primary" onClick={() => { handleClickOpen(params.row, "decrease") }} />
                </IconButton>
              )
            },
          disableClickEventBubbling: true,
        },
        {
          field: 'invIn', headerName: 'Inventory return', width: 200,
          renderCell:
            (params) => {
              return (
                <IconButton aria-label="increase" >
                  <AssignmentReturnRoundedIcon color="secondary" onClick={() => { handleClickOpen(params.row, "increase") }} />
                </IconButton>
              )
            },
          disableClickEventBubbling: true,
        },
      /*  {
          field: 'invEdit', headerName: 'Edit', width: 150,
          renderCell:
            (params) => {
              return (
                <IconButton aria-label="increase" >
                  <EditIcon color="secondary" onClick={() => { handleClickOpen(params.row, "edit") }} />
                </IconButton>
              )
            },
          disableClickEventBubbling: true,
        },*/
      ]
  }
  //console.log("customer: "+customer);
  return (
    <div className='container' style={{ maxWidth: '100%' }}>
        <Typography variant="h6" gutterBottom component="div">
              Inventory
        </Typography>
        <Button color="primary" startIcon={<AddIcon />} onClick={addMenuClickk}>
        Add Item
      </Button>
      {/*---- Main table----*/}
      
        <div style={{ width: '100%' }} className={classes.root, 'onPrintDine'} id='product-details-list-table'>
          <DataGrid 
          rows={rows} 
          columns={columns} 
          pageSize={100} 
          autoHeight={true}
          //onCellEditCommit={handleCellEditCommit}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
          density="compact"
          loading={receivedData}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
            },
          }}
          />
        </div>

{/* <Eidt/Add Product popu> */}
      <Dialog open={openAddMenu} onClose={()=>setOpenAddMenu(false)} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
        <DialogTitle id="product-details-form-dialog-title">
        {
            dialogType === "edit" ? "Edit Product" : 'Add Inventory Item'
          }
        </DialogTitle>
        <DialogContent>
          
              <TextField
                autoFocus
                margin="dense"
                id="product-details"
                label="Name"
                value={values.name}
                type="text"
                fullWidth
                onChange={handleChange('name')}
              />
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label">Unit </InputLabel>
            <Select
          labelId="demo-simple-select-outlined-label"
          id="add-product-se-quantity_type"
          value={values.quantity_type}
          onChange={handleChange('quantity_type')}
          label="Unit"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="liter">Liter</MenuItem>
          <MenuItem value="kilogram">Kilogram</MenuItem>
          <MenuItem value="packet">Packet</MenuItem>
          <MenuItem value="piece">Piece</MenuItem>
          <MenuItem value="bottle">Bottle</MenuItem>
          <MenuItem value="cane">Cane</MenuItem>
        </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenAddMenu(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => { postnDeleteMenu() }}  color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Admin Add Dialog */}


            {/*---- dialog PtoductOut----*/}
{      <Dialog open={productOutOpen} onClose={handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
        <DialogTitle id="product-details-form-dialog-title">
               
          {
            dialogType === "decrease" ? "Stock out" : 'Stock in'
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6" gutterBottom component="div">
            {values.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
            Currently in stock: {existingQty}
            </Typography>
          </DialogContentText>                   
              <TextField
                autoFocus
                margin="dense"
                id="product-details-quantity"
                label="Quantity"
                InputProps={{
                  endAdornment: <InputAdornment position="end">{values.quantity_type}</InputAdornment>,
                }}
                type="number"
                fullWidth
                onChange={handleChange('quantity')}
                error={stockError}              
                helperText={errorMsg} 
              />
              {/*<Autocomplete
                //className={classes.Select}
                id="product-details-combo-box-demo"
                options={allWaiters}
                getOptionLabel={(option) => option.name}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Employee" />}
                onChange={(event, value) => setSelectedEmployee(value)}
                loading={true}
                loadingText='Load kanagaen..'
              />*/}
              <TextField id="outlined-basic" label="Employee"  fullWidth onChange={handleChange('employee')}/> 
              <Autocomplete
                id="departments-combo-box-demo"
                options={departments}
               // getOptionLabel={(option) => option.name}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Department" />}
               // onChange={handleChange('department')}
                onChange={(event, value) => setValues({ ...values, department: value.department })}
                loading={true}
                loadingText='Load kanagaen..'
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => { postnDeleteMenu() }} color="primary" disabled={isSubmitDisabled}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      }
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity={severity} onClose={handleClose}>
        {alertMessage}
      </Alert>
    </Snackbar>
    </div >
  )

}
const departments = [
  { label: 'Spice Kitchen A', department: 'Spice Kitchen A' },
  { label: 'Spice Kitchen B', department: 'Spice Kitchen B' },
  { label: 'Zubair', department: 'Zubair' },
  { label: 'BBQ Asif', department: 'BBQ Asif' },
  { label: 'BBQ Plater', department: 'BBQ Plater' },
  { label: "Ice Cream Shop", department: 'Ice Cream Shop' },
  { label: 'Fast Food', department: 'Fast Food' },
  { label: 'Salads', department: 'Salads' },
  { label: "Ground Floor Waiters", department: 'Ground Floor Waiters' },
  { label: 'Dine Out Waiters', department: 'Dine Out Waiters' },
  { label: '1st Floor Waiters', department: '1st Floor Waiters' },
  { label: "2nd Floor Waiters", department: '2nd Floor Waiters' },
  { label: 'Management', department: 'Management' },
  { label: 'Event Management', department: 'Event Management' },
  { label: "House Keeping", department: 'House Keeping' },
  { label: "Parcel", department: 'Parcel' },
  { label: "Tandoor", department: 'Tandoor' },  
  { label: "Old-Stock", department: 'Old-Stock' },
  { label: "Imdad", department: 'Imdad' },
  { label: "Continental", department: 'Continental' },        
];












