    export const customerReducer=(state={customer:null},action)=>{
        switch(action.type){
            case 'SET_CUSTOMER':
                return {...state,customer:action.payload}

            default:
                return {...state}
        }
    }

    export const singleCustomerReducer=(state={customerPop:[]},action)=>{
        switch(action.type){
            case 'SET_SINGLE_CUSTOMER':
                return {...state,customerPop:action.payload}

            default:
                return {...state}
        }
    }