import {BaseURL} from './baseURL'

export function getData(address){
    return new Promise((resolve,reject)=>{
      var myHeaders = new Headers();
      myHeaders.append("content-type","application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

        fetch(BaseURL+address, requestOptions)
  .then(response => response.json())

  .then((result) => {
    resolve(result)
    console.log(result)
            let data=result
            return data;

        }
  )
  .catch((error)=>{
      reject(error)
      console.log('getData;error',error)
  });
    })
}
export function postData(address,payload){

  return new Promise((resolve,reject)=>{
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify(payload);
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  };
  
  fetch(BaseURL+address, requestOptions)
    .then(response => response.json())
    .then((result) => {
      resolve(result)
      console.log(result)})
    .catch((error) => {
    reject(error)
      console.log('error', error)
    }
      );
  
   
  })
  }
  export function putData(address,payload){

    return new Promise((resolve,reject)=>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify(payload);
    
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
    };
    
    fetch(BaseURL+address, requestOptions)
      .then(response => response)
      .then((result) => {
        resolve(result)
        console.log(result)})
      .catch((error) => {
      reject(error)
        console.log('error', error)
      }
        );
    
     
    })
    }