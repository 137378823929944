import React, { useState } from 'react';
import clsx from 'clsx';
// import {Toolbar,Typography} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
//import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { staticMenu } from '../static/Menus';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AppBar, Toolbar, IconButton, Typography, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import ListItemLinkContainer from '../container/ListItemLinkContainer';
import { useStyles } from '../static/MiniDrawerStyles';
// import {MenuReal} from '../static/Menu-main'
import { RecepMenu } from '../static/Menu/recep-menu';
import { waiterMenu } from '../static/Menu/waiter-menu';
import { KitchenMenu } from '../static/Menu/kitchen-menu';
import { CashierMenu } from '../static/Menu/cashier-menu'

import {AdminMenu} from '../static/Menu/admin-menu'
import {AccountantMenu} from '../static/Menu/accountantMenu'
import{TakeawayMenu} from '../static/Menu/takeaway-menu.js'
import{storekeeperMenu} from '../static/Menu/storeKeeper-menu'
import { getFoodCategoryMenuItems } from '../static/Menu/menuCard-item'
import ProfileDetails from './profileDetails';
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
//import { MenuItems } from '../static/Menu/menu-item'
import { SignIn } from '../pages/Layout/sign-In/Sign-In'
import { Switch, Route,useHistory } from 'react-router-dom'
import { setUser } from '../redux/actions'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Logo from '../assets/images/header-01.png'
import './style.css'



function MiniDrawer(props) {
    // let [existMenu,setExistMenu]=useState(RecepMenu);

const history=useHistory()
    let existMenu;
    // let existMenu = waiterMenu;
    const location = useLocation();
    console.log('current params are', location.pathname)
  

    const classes = useStyles();
    const theme = useTheme();
    const { openSideBar, handlerSideBar } = props;
    // let menu={};
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.userReducer)

  const handleBack=()=>{
      if(history.length>0){
          history.goBack()
      }
      else{
          console.log('length is null')
      }
  }
    
    const checkActive = () => {
        alert('its working')
    }
    // console.log('the value of reception menu is',user.role)
    if (user) {
        if ('reception' == user.role) {
            existMenu = RecepMenu;
        }
        else if ('waiter' == user.role || 'adminwaiter' == user.role) {
            existMenu = waiterMenu
            if (location.pathname == "/waiter/menu") {
               // existMenu = MenuItems;
                existMenu = getFoodCategoryMenuItems("/waiter/menu")
            }
            else if (location.pathname == '/waiter/menuItems') {
                //existMenu = MenuCardItems;
                existMenu = getFoodCategoryMenuItems("/waiter/menuItems")
            }
          /*  else {
                existMenu = waiterMenu
            }*/
        }        
        else if('storekeeper' == user.role){
            existMenu=storekeeperMenu
        }
        else if('takeaway' == user.role){
            existMenu=TakeawayMenu
            if(location.pathname == '/takeaway/menuOrder'){
               // existMenu = TakeAwayMenuItems
                existMenu = getFoodCategoryMenuItems("/takeaway/menuOrder")
            }
            else if(location.pathname == '/takeaway/menu'){
               // existMenu=CommonMenuItems
                existMenu = getFoodCategoryMenuItems("/takeaway/menu")
            }
           /* else{
                existMenu=TakeawayMenu
            }*/
        }
        else if ('cashier' == user.role) {
            if (location.pathname == '/cashier/menu') {
               // existMenu = cashierMenuCardItems
                existMenu = getFoodCategoryMenuItems("/cashier/menu")
            }
            else {
                existMenu = CashierMenu
            }
        }
        else if ('admin' == user.role) {
            if (location.pathname == '/admin/menu') {
               // existMenu = AdminMenuItems
                existMenu = getFoodCategoryMenuItems('/admin/menu')
            }else if (location.pathname == "/waiter/menu") {//TODO: to change to /admin/customer-order
               // existMenu = MenuItems;
               existMenu = getFoodCategoryMenuItems("/waiter/menu")

            }
            else {
                existMenu = AdminMenu
            }
        }
        else if ('kitchen' == user.role || 'cheif' == user.role) {

            if (location.pathname == '/kitchen/menu') {
                //existMenu = KitchenMenuItems
                existMenu = getFoodCategoryMenuItems("/kitchen/menu")
            }
            else {
                existMenu = KitchenMenu
            }
        }
        else if ('accountant' == user.role) {   
           
            existMenu = AccountantMenu     
        }
    }
    else {
        dispatch(setUser(JSON.parse(localStorage.getItem('user'))))
        if (location.pathname = 'reception') {
            existMenu = RecepMenu
        }
        else if ('waiter' == user.role || 'adminwaiter' == user.role) {
            existMenu = waiterMenu
            if (location.pathname == "/waiter/menu") {
                //existMenu = MenuItems;
                existMenu = getFoodCategoryMenuItems("/waiter/menu")

            }
            else if (location.pathname == '/waiter/menuItems') {
               // existMenu = MenuCardItems;
                existMenu = getFoodCategoryMenuItems("/waiter/menuItems")
            }
           /* else {
                existMenu = waiterMenu
            }*/
        }
        else if('storekeeper' == user.role){
            existMenu=storekeeperMenu
        }
        else if ('cashier' == user.role) {
            if (location.pathname == '/cashier/menu') {
                //existMenu = cashierMenuCardItems
                existMenu = getFoodCategoryMenuItems("/cashier/menu")
            }
            else {
                existMenu = CashierMenu
            }
        }        
        else if('takeaway' == user.role){
            if(location.pathname == '/takeaway/menuOrder' ){
               // existMenu = TakeAwayMenuItems
                existMenu = getFoodCategoryMenuItems("/takeaway/menuOrder")
            }
            else if(location.pathname == '/takeaway/menu'){
               // existMenu=CommonMenuItems
                existMenu = getFoodCategoryMenuItems("/takeaway/menu")
            }
            else{
                existMenu=TakeawayMenu
            }
        }

        else if ('kitchen' == user.role || 'cheif' == user.role) {

            if (location.pathname == '/kitchen/menu') {
               // existMenu = KitchenMenuItems
                existMenu = getFoodCategoryMenuItems("/kitchen/menu")
            }
            else {
                existMenu = KitchenMenu
            }
        }
        else if ('admin' == user.role) {
            if (location.pathname == '/admin/menu') {
               // existMenu = AdminMenuItems

               existMenu = getFoodCategoryMenuItems('/admin/menu')
            }
            else {
                existMenu = AdminMenu
            }
        }
        else if ('accountant' == user.role) {   
           
                existMenu = AccountantMenu     
        }
    }


    // const role=['admin','store-keeper','cheif']
    return (
        <div className="hiddenDrawer">
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: openSideBar,
                })}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={handlerSideBar}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: openSideBar,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton onClick={handleBack} className='arrowBack'>
                    <ArrowBackIcon className='mr-3' />
                    </IconButton>
                    {/* component={Link} to='/' */}
                    <Typography style={{ color: 'white' }}  variant="h6" noWrap>
                        AB Restaurant
                    </Typography>
                   
                    <img src={Logo} style={{height:'70px',marginLeft:'auto'}}/>
                </Toolbar>
            </AppBar>
            <Drawer

                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: openSideBar,
                    [classes.drawerClose]: !openSideBar,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: openSideBar,
                        [classes.drawerClose]: !openSideBar,
                    }),
                }}
                open={openSideBar}
            >
                <div style={{ minHeight: '64px', display: 'flex', justifyContent: 'space-between' }} >
                      <img src={Logo} alt="" style={{height:'70px'}} />
                    <IconButton onClick={handlerSideBar} >
                        {theme.direction === 'rtl' ? <MenuOpenIcon style={{ color: 'black' }} /> : <MenuOpenIcon style={{ color: 'black' }} />}
                    </IconButton>
                </div>
                <Divider />
                <nav >
                    <List>
                        {existMenu.map((menu, index) => (
                            <React.Fragment key={index}  >
                                <ListItemLinkContainer menuObj={menu} />
                            </React.Fragment>
                        ))}
                    </List>
                </nav>
            </Drawer>
        </div>
    );
}

export default MiniDrawer;